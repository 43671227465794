import { v4 as uuidv4 } from 'uuid';
import env from '../env';
import ms from 'ms';

const SESSION_ID_KEY = 'sessionId';
const DISTINCT_ID_KEY = 'distinctId';
const SESSION_ID_EXPIRATION = ms('30m');
const SESSION_ID_RENEWAL = ms('15m');

let sessionIdCache: string;
let sessionIdTimestampCache: number;
let distinctIdCache: string;

/**
 * Sessions expire after 30 mins of inactivity
 * If hooks runs when session age isn't expired but has an age greater than 15 minutes it will renew the session expiration
 * distinctId is random UUID stored in localStorage until cleared
 */
export const useSessionId = (): {
  sessionId: string;
  distinctId: string;
} | void => {
  if (env.runtime.isServer) return;

  try {
    let distinctId = distinctIdCache || localStorage.getItem(DISTINCT_ID_KEY);

    if (!distinctId) {
      const newDistinctId = uuidv4();
      distinctId = newDistinctId;
      distinctIdCache = newDistinctId;
      localStorage.setItem(DISTINCT_ID_KEY, distinctId);
    }

    const sessionId = sessionIdCache || localStorage.getItem(SESSION_ID_KEY);
    const sessionIdTimestamp =
      sessionIdTimestampCache ||
      localStorage.getItem(`${SESSION_ID_KEY}_timestamp`);

    if (sessionId && sessionIdTimestamp) {
      const sessionIdAge = Date.now() - Number(sessionIdTimestamp);

      if (sessionIdAge < SESSION_ID_EXPIRATION) {
        if (sessionIdAge > SESSION_ID_RENEWAL) {
          const newSessionIdTimestamp = Date.now();
          sessionIdTimestampCache = newSessionIdTimestamp;
          localStorage.setItem(
            `${SESSION_ID_KEY}_timestamp`,
            String(newSessionIdTimestamp),
          );
        }

        return { sessionId, distinctId };
      }
    }

    const newSessionId = uuidv4();
    sessionIdCache = newSessionId;
    localStorage.setItem(SESSION_ID_KEY, newSessionId);

    const newSessionIdTimestamp = Date.now();
    sessionIdTimestampCache = newSessionIdTimestamp;
    localStorage.setItem(
      `${SESSION_ID_KEY}_timestamp`,
      String(newSessionIdTimestamp),
    );

    return { sessionId: newSessionId, distinctId };

  } catch (e) {
    const sessionId = uuidv4();
    const distinctId = uuidv4();

    return { sessionId, distinctId };
  }
};
