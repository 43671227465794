import { useQuery } from 'react-query';
import { getMe } from '../util/api';

export const useUserData = (user) => {
  const {
    data: userData,
    isLoading: loading,
    isError: error,
  } = useQuery('userData', () => getMe({ plan: true }), {
    enabled: !!user,
    retry: false,
  });

  return { userData, loading, error };
};
