import { useStore } from './store.hooks';
import { useMemo } from 'react';
import { defineAbility } from '@wander-app/shared';
import { ForbiddenError } from '@casl/ability';

export const useAbility = () => {
  const store = useStore();
  const can = (ability, rules) => {
    try {
      rules.forEach((rule) => {
        ForbiddenError.from(ability).throwUnlessCan(rule.action, rule.subject);
      });
      return true;
    } catch (err) {
      return null;
    }
  };

  return useMemo(() => {
    return {
      can: (rules, data_guard_info = {}) =>
        can(defineAbility(store.user, data_guard_info), rules),
    };
  }, [store.user.roles]);
};
