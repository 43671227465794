import { makeAutoObservable } from 'mobx';
import { StoreMobx } from '../store.mobx';
import { EmbedCategoryMobx } from '../embedCategory.mobx';
import { EmbedFeatureMobx } from '../embedFeature.mobx';

export class PublicLayoutMobx {
  view = '';
  selectedFeature?: EmbedFeatureMobx = null;
  selectedCategory?: EmbedCategoryMobx = null;
  drawerIsOpen = true;
  featureDrawerIsOpen = false;

  changeFeatureItemAndView = (
    view: string,
    feature: EmbedFeatureMobx,
    fly = false,
  ) => {
    this.view = view;
    this.selectedFeature = feature;
    if (fly) {
      feature.flyTo();
    }
  };

  setSelectedFeature = (feature: EmbedFeatureMobx) => {
    this.selectedFeature = feature;
  };

  changeCategoryItemAndView = (view: string, category: EmbedCategoryMobx) => {
    this.view = view;
    this.selectedCategory = category;
  };

  reset = () => {
    this.view = '';
  };

  changeView = (view: string) => {
    this.updateFeatureDrawerIsOpen(false);
    this.reset();
    this.view = view;
  };

  toggleDrawerOpen = () => {
    this.drawerIsOpen = !this.drawerIsOpen;
  };

  updateFeatureDrawerIsOpen = (state: boolean) => {
    this.featureDrawerIsOpen = state;
  };

  parent: StoreMobx;
  constructor(parent: StoreMobx) {
    this.parent = parent;
    makeAutoObservable(this);
  }
}
