export const NODE_ENV = process.env.NODE_ENV;
export const NEXT_PUBLIC_DEFAULT_MAP_ID =
  process.env.NEXT_PUBLIC_DEFAULT_MAP_ID;
export const NEXT_PUBLIC_FIREBASE_ADMIN_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_ADMIN_PROJECT_ID;
export const FIREBASE_ADMIN_CLIENT_EMAIL =
  process.env.FIREBASE_ADMIN_CLIENT_EMAIL;
export const FIREBASE_ADMIN_PRIVATE_KEY =
  process.env.FIREBASE_ADMIN_PRIVATE_KEY;
export const FIREBASE_ADMIN_STORAGE_BUCKET =
  process.env.FIREBASE_ADMIN_STORAGE_BUCKET;
export const NEXT_PUBLIC_FIREBASE_ADMIN_DATABASE_URL =
  process.env.NEXT_PUBLIC_FIREBASE_ADMIN_DATABASE_URL;
export const NEXT_PUBLIC_FIREBASE_API_KEY =
  process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const COOKIE_SECRET_CURRENT = process.env.COOKIE_SECRET_CURRENT;
export const COOKIE_SECRET_PREVIOUS = process.env.COOKIE_SECRET_PREVIOUS;
export const API_URL = process.env.NEXT_PUBLIC_API_URL || '';
export const NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
export const NEXT_PUBLIC_MAPBOX_USER_ID =
  process.env.NEXT_PUBLIC_MAPBOX_USER_ID;
export const NEXT_PUBLIC_MAPBOX_STYLEID =
  process.env.NEXT_PUBLIC_MAPBOX_STYLEID;
export const NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const NEXT_PUBLIC_FIREBASE_APP_ID =
  process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const SENTRY_DSN =
  process.env.SENTRY_DSN_WEB || process.env.NEXT_PUBLIC_SENTRY_DSN_WEB;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const NEXT_PUBLIC_FOURSQUARE_API_KEY =
  process.env.NEXT_PUBLIC_FOURSQUARE_API_KEY;
export const MAX_REQ_SIZE_MB = process.env.MAX_REQ_SIZE_MB;
export const MIXPANEL_PROJECT_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN;
export const MIXPANEL_SERVICE_ACCOUNT_USER =
  process.env.MIXPANEL_SERVICE_ACCOUNT_USER;
export const MIXPANEL_SERVICE_ACCOUNT_PASS =
  process.env.MIXPANEL_SERVICE_ACCOUNT_PASS;
export const MIXPANEL_PROJECT_ID = process.env.MIXPANEL_PROJECT_ID ?? '2708509';
export const BIG_QUERY_REGION = process.env.BIG_QUERY_REGION;
export const BIG_QUERY_TABLE = process.env.BIG_QUERY_TABLE;
export const BIG_QUERY_PROJECT_ID = process.env.BIG_QUERY_PROJECT_ID;
export const DISABLE_SENTRY = process.env.DISABLE_SENTRY;
export const NEXT_PUBLIC_NEW_EMBED_URL =
  process.env.NEXT_PUBLIC_NEW_EMBED_URL || 'http://localhost:3000';
export const NEXT_PUBLIC_CLIENT_URL = process.env.NEXT_PUBLIC_CLIENT_URL;