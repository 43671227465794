import { makeAutoObservable, observable } from 'mobx';
import _ from 'lodash/fp';
import { getAllTagItems } from '../util/api';
import { StoreMobx } from './store.mobx';
import { mapToArray } from '../util/store.util';
import { FilterTagItem } from '../components/Tags/TagFilters';
import { EmbedCategoryMobx } from './embedCategory.mobx';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { Subject } from 'rxjs';

export const $filterSearchbarBlurSubject = new Subject();

export type TagItem = {
  name: string;
  type: string;
  metadata: Record<string, unknown>;
  id: string;
};

type AdminTagItem = {
  item?: TagItem;
  mode: 'add' | 'edit' | 'view' | 'delete';
  type: 'keyword' | 'type' | 'field' | 'fieldoption';
} & { fields?: Array<Record<string, unknown>> };

export type TagItemFieldOption = {
  id: string;
  name: string;
  type: 'fieldoption';
  metadata: Record<string, unknown>;
};

export type TagItemField = {
  id: string;
  name: string;
  type: 'field';
  metadata: Record<string, unknown>;
  field_options: Array<TagItemFieldOption>;
};

export type TagItemType = {
  name: string;
  id: string;
  type: 'type';
  metadata: Record<string, unknown>;
  fields: Array<TagItemField>;
};

export class TagsMobx {
  parent: StoreMobx;
  tagModal: 'admin' | 'normal' | '';
  selectedAdminTagItem: AdminTagItem = null;
  deleteAdminTagItemModal: 'delete' | null = null;
  tagItems: Array<Record<string, unknown>> = [];
  activeKeyword: Record<string, unknown> | null = null;

  selectedTagItemIds: Array<string> = [];
  initialselectedTagItemIds: Array<string> = [];
  selectedTagType: TagItemType = null;
  selectedTagField: TagItemField = null;
  bulkTagging = false;
  bulkIdsUnionSet: Set<string> = new Set();
  bulkIdsIntersectionSet: Set<string> = new Set();
  bulkIdsDeleteSet: Set<string> = new Set();

  filterItemIDs = observable.set<string>();
  filterSearchQuery = '';
  filterHoursRange: [number, number] = [0, 24];
  filterHoursSelectedDays: Record<string, boolean> = {};
  filterHoursOpenFilter: null | 'now' | '24hours' = null;
  filteredFeaturesSet: Set<string> = new Set();
  urlFiltersReady = false;

  selecetedList = '';

  setBulkIdsUnionSet(ids: Array<string>) {
    this.bulkIdsUnionSet = new Set(ids);
  }
  setBulkIdsIntersectionSet(ids: Array<string>) {
    this.bulkIdsIntersectionSet = new Set(ids);
  }
  removeBulkUnionId(id) {
    this.bulkIdsUnionSet.delete(id);
  }
  removeBulkIntersectionId(id) {
    this.bulkIdsIntersectionSet.delete(id);
  }
  addBulkDeleteId(id) {
    this.bulkIdsDeleteSet.add(id);
  }
  removeBulkDeleteId(id) {
    this.bulkIdsDeleteSet.delete(id);
  }
  clearBulkIds() {
    this.bulkIdsUnionSet.clear();
    this.bulkIdsIntersectionSet.clear();
    this.bulkIdsDeleteSet.clear();
  }

  setSelectedList(listName: string) {
    this.selecetedList = listName;
  }

  setUrlFiltersReady(ready: boolean) {
    this.urlFiltersReady = ready;
  }

  selectedFilterItems = { keyword: null, type: null, category: null };

  groupedFeatureTagItems: TagItem[];
  categoryTagItems: TagItem[];

  get filterCurrentTagItems(): TagItem[] {
    if (this.selectedFilterItems.category) {
      return (
        this.selectedFilterItems.type?.fields ||
        this.selectedFilterItems.keyword?.types ||
        this.selectedFilterItems.category?.keywords
      );
    }
    return (
      this.selectedFilterItems.type?.fields ||
      this.selectedFilterItems.keyword?.types ||
      this.groupedFeatureTagItems
    );
  }

  setSelectedFilterItemById({
    tagItemId,
    categoryId,
  }: {
    tagItemId: string;
    categoryId: string;
  }) {
    const keyword = _.find(
      (tagItem: TagItem) => tagItem.id === tagItemId,
      this.groupedFeatureTagItems,
    );

    const type =
      !keyword &&
      _.flow(
        _.reduce((acc, { types }) => {
          if (types?.length) {
            acc.push(types);
          }
          return acc;
        }, []),
        _.flatten,
        _.find((tagItem: TagItem) => tagItem.id === tagItemId),
      )(this.groupedFeatureTagItems);

    const category =
      categoryId &&
      _.find(
        (tagItem: TagItem) => tagItem.id === categoryId,
        this.groupedFeatureTagItems,
      );

    this.selectedFilterItems = {
      keyword: keyword || null,
      category: category || null,
      type: type || null,
    };
  }

  get filterSearchQueryItem(): TagItem {
    return (
      this.selectedFilterItems.type || this.selectedFilterItems.keyword || null
    );
  }

  get filterCurrentTagItemsType(): 'keyword' | 'type' | 'field' {
    const tagItem = _.first(this.filterCurrentTagItems);
    if (_.has('types', tagItem)) return 'keyword';
    if (_.has('fields', tagItem)) return 'type';
    if (_.has('field_options', tagItem)) return 'field';
  }

  get filterItemIDsArray() {
    return Array.from(this.filterItemIDs);
  }

  get featureTagItems() {
    if (this.parent.isBuilderInstance) return;

    return this.createFeatureTagItems(
      mapToArray(this.parent.embed.features) as EmbedFeatureMobx[],
    );
  }

  createFeatureTagItems(features: EmbedFeatureMobx[]) {
    const tagItems = _.map('properties.tag_items', features);
    const flattenedTagItems = _.flatten(tagItems);

    const tagItemCounts = _.reduce((counts, tagItem: TagItem) => {
      if (!(tagItem.id in counts)) {
        counts[tagItem.id] = 0;
      }
      counts[tagItem.id]++;

      return counts;
    }, {})(flattenedTagItems);

    const groupedByTagItemType = _.flow(
      _.uniqBy('id'),
      _.reduce(
        (acc, tagItem: TagItem) => {
          acc[tagItem.type][tagItem.id] = tagItem;
          return acc;
        },
        {
          keyword: {},
          type: {},
          field: {},
          fieldoption: {},
        },
      ),
    )(flattenedTagItems);

    return { items: groupedByTagItemType, counts: tagItemCounts };
  }

  createGroupedFeatureTagItems(data: TagItem[]) {
    const { items: featureItems, counts } = this.featureTagItems;

    const createTagItemFilter = (key: string, items) =>
      _.filter((item: any) => item?.id in items[key]);

    const sortByCount = _.sortBy((item: any) => -counts[item?.id]);

    const groupedTagItemsHelper = (items: any) => {
      const mapFieldFieldOptions = _.map((field: any) => {
        const fieldCopy = _.cloneDeep(field);
        fieldCopy.field_options = _.flow(
          createTagItemFilter('fieldoption', items),
          sortByCount,
        )(field.field_options);
        return fieldCopy;
      });

      const mapTypeFields = _.map((type: any) => {
        const typeCopy = _.cloneDeep(type);
        typeCopy.fields = _.flow(
          createTagItemFilter('field', items),
          mapFieldFieldOptions,
          sortByCount,
        )(type.fields);
        return typeCopy;
      });

      const mapKeywordTypes = _.map((keyword: any) => {
        const keywordCopy = _.cloneDeep(keyword);
        keywordCopy.types = _.flow(
          createTagItemFilter('type', items),
          mapTypeFields,
          sortByCount,
        )(keyword.types);
        return keywordCopy;
      });

      const groupedFeatureTagItems = _.flow(
        createTagItemFilter('keyword', items),
        mapKeywordTypes,
        sortByCount,
      )(data);

      return groupedFeatureTagItems;
    };

    const groupedFeatureTagItems = groupedTagItemsHelper(featureItems);

    const tagCategories = _.flow(
      _.map((c: EmbedCategoryMobx) => {
        const categoryFeatureTagItems = this.createFeatureTagItems(
          c.allFeatures,
        );

        const itemSets = {
          keyword: new Set(_.map('id', categoryFeatureTagItems.items.keyword)),
          type: new Set(_.map('id', categoryFeatureTagItems.items.type)),
          field: new Set(_.map('id', categoryFeatureTagItems.items.field)),
          fieldoption: new Set(
            _.map('id', categoryFeatureTagItems.items.fieldoption),
          ),
        };

        return {
          name: c.name,
          type: 'category',
          metadata: {},
          id: c.id,
          keywords: groupedTagItemsHelper(categoryFeatureTagItems.items),
          itemSets,
        };
      }),
    )(this.parent.embed.tagCategories);

    this.categoryTagItems = tagCategories;

    this.groupedFeatureTagItems = _.concat(
      this.categoryTagItems,
      groupedFeatureTagItems,
    );
  }

  updateFeatureVisibilities() {
    const shouldFilterHours =
      this.parent.tags.filterHoursOpenFilter !== null ||
      _.size(this.parent.tags.filterHoursSelectedDays) !== 0;
    const tagFiltersApplied =
      this.filterSearchQueryItem || this.filterItemIDs.size !== 0;

    const allListedByDefaultIds = _.map(
      'id',
      this.parent.embed.listedByDefaultFeatures,
    );

    if (
      !tagFiltersApplied &&
      !shouldFilterHours &&
      !this.selectedFilterItems.category
    ) {
      const allFeatureIds = _.map('id', this.parent.embed.allFeaturesArray);
      this.filteredFeaturesSet = new Set([
        ...allFeatureIds,
        ...allListedByDefaultIds,
      ]);
      this.parent.embed.updateFeatureVisibilities(this.filteredFeaturesSet);
      return;
    }

    const filteredFeatureIds = _.map(
      'id',
      this.parent.embed.filteredFeaturesArrayV2,
    );

    this.filteredFeaturesSet = new Set([...filteredFeatureIds]);

    this.parent.embed.updateFeatureVisibilities(this.filteredFeaturesSet);
  }

  get selectedFilterTagIDs(): Array<string> {
    return _.concat(
      this.filterSearchQueryItem?.id || [],
      Array.from(this.filterItemIDs),
    );
  }

  get allSelectedFilterTagCount(): number {
    return _.flow(
      _.values,
      _.reject(_.isNil),
      _.size,
      _.add(this.filterItemIDs.size),
    )(this.selectedFilterItems);
  }

  setFilterHoursRange(range: [number, number]) {
    this.filterHoursRange = range;
  }

  setFilterHoursSelectedDays(
    cb: (days: Record<string, boolean>) => Record<string, boolean>,
  ) {
    this.filterHoursSelectedDays = cb(this.filterHoursSelectedDays);
  }

  get filterHoursSelectedDaysSize() {
    return _.size(_.pickBy(_.identity, this.filterHoursSelectedDays));
  }

  setFilterHoursOpenFilter(filter: null | 'now' | '24hours') {
    if (this.filterHoursOpenFilter === filter) {
      this.filterHoursOpenFilter = null;
    } else {
      this.filterHoursOpenFilter = filter;
    }
    this.filterHoursSelectedDays = {};
  }

  resetFilterHours = () => {
    this.filterHoursOpenFilter = null;
    this.filterHoursRange = [0, 24];
    this.filterHoursSelectedDays = {};
  };

  resetCurrentTagItems() {
    this.selectedFilterItems = {
      category: null,
      keyword: null,
      type: null,
    };
  }

  get shouldReplaceSearchQuery() {
    return !(
      this.selectedFilterItems.category &&
      this.parent.embed.filteredFeaturesArrayV2?.length &&
      this.parent.embed.isSelectedFeature &&
      this.parent.layout.public.featureDrawerIsOpen
    );
  }

  handleTagClick = (tagItem: FilterTagItem) => {
    this.filterSearchQuery = tagItem.name;

    const isCategory = tagItem.type === 'category';
    const isDifferentCategory =
      isCategory && this.selectedFilterItems.category?.id !== tagItem.id;
    const isSameCategory = isCategory && !isDifferentCategory;

    const isKeyword = tagItem.type === 'keyword';
    const isSelectedKeyword =
      isKeyword && this.selectedFilterItems.keyword?.id === tagItem.id;

    const isType = tagItem.type === 'type';
    const isSelectedType = this.selectedFilterItems.type?.id === tagItem.id;

    const categoryHas = (type: 'keyword' | 'type') => {
      if (isCategory && this.selectedFilterItems[type]) {
        return tagItem.itemSets[type].has(this.selectedFilterItems[type].id);
      }
      if (tagItem.type === type && this.selectedFilterItems.category) {
        return this.selectedFilterItems.category.itemSets[type].has(tagItem.id);
      }
      return true;
    };

    const categoryHasKeyword = categoryHas('keyword');
    const categoryHasType = categoryHas('type');

    const resetCategory =
      isSameCategory || !categoryHasKeyword || !categoryHasType;

    const getNextCategory = () => {
      if (isDifferentCategory) {
        return tagItem;
      }
      if (resetCategory) {
        return null;
      }
      return this.selectedFilterItems.category;
    };

    const getNextKeyword = () => {
      if (isSelectedKeyword) {
        return null;
      }
      if (isKeyword && !isSelectedKeyword) {
        return tagItem;
      }
      if (isType || categoryHasKeyword) {
        return this.selectedFilterItems.keyword;
      }
      return null;
    };

    const getNextType = () => {
      if (isSelectedType) {
        return null;
      }
      if (isType && !isSelectedType) {
        return tagItem;
      }
      if (isKeyword || categoryHasType) {
        return this.selectedFilterItems.type;
      }
      return null;
    };

    this.selectedFilterItems = {
      category: getNextCategory(),
      keyword: getNextKeyword(),
      type: getNextType(),
    };
  };

  resetFilters = (changeView = true) => {
    this.setFilterSearchQuery('');
    this.resetCurrentTagItems();
    this.resetFilterHours();
    this.resetCurrentTagItems();
    this.filterItemIDs.clear();
    this.parent.embed.clearSelectedFeature();
    if (changeView) {
      this.parent.layout.public.changeView('');
    }
  };

  addFilterItem(id: string) {
    if (
      this.selectedFilterItems.category &&
      !this.selectedFilterItems.category.itemSets.fieldoption.has(id)
    ) {
      this.selectedFilterItems.category = null;
    }
    this.filterItemIDs.add(id);
  }

  setFilterItemIds(ids: Array<string>) {
    this.filterItemIDs.clear();
    ids.forEach((id) => this.filterItemIDs.add(id));
  }

  removeFilterItem(id: string) {
    this.filterItemIDs.delete(id);
  }

  setFilterSearchQuery(query: string) {
    this.filterSearchQuery = query;
  }

  setSelectedTagItemIds = (itemIds) => {
    const keywordIdsWithTypes = _.flow(
      _.filter((keyword) => _.size(_.get('types', keyword)) > 0),
      _.map('id'),
    )(this.tagItems);
    this.selectedTagItemIds = _.flow(
      _.filter((id) => !keywordIdsWithTypes.includes(id)),
    )([...itemIds]);
  };

  setInitialTagItemIds = (itemIds) => {
    this.initialselectedTagItemIds = [...itemIds];
  };

  setSelectedTagType = (type) => {
    this.selectedTagType = type;
  };

  setSelectedTagField = (field) => {
    if (
      this.selectedTagField &&
      (field ? field.id !== this.selectedTagField.id : true)
    ) {
      const fieldOptionsIdsSet = new Set(
        _.map(({ id }) => id, this.selectedTagField.field_options),
      );

      if (fieldOptionsIdsSet.size !== 0) {
        const numSelectedFieldOptions = _.size(
          _.filter((id) => fieldOptionsIdsSet.has(id), this.selectedTagItemIds),
        );
        if (numSelectedFieldOptions === 0) {
          this.setSelectedTagItemIds(
            _.filter(
              (id) => id !== this.selectedTagField.id,
              this.selectedTagItemIds,
            ),
          );
        }
      }
    }
    this.selectedTagField = field;
  };

  setTagModal = (type: 'normal' | 'admin' | '') => {
    this.tagModal = type;
  };

  setDeleteAdminTagItemModal(type: 'delete' | null) {
    this.deleteAdminTagItemModal = type;
  }

  setSelectedAdminTagItem({ item, mode, type }: AdminTagItem) {
    this.selectedAdminTagItem = { item, mode, type };
  }

  closeAdminTagModal() {
    this.selectedAdminTagItem = null;
  }

  setTagItems(data) {
    this.tagItems = _.sortBy(({ name }) => name.toLowerCase(), data);
  }

  setActiveKeyword(keyword: Record<string, unknown> | null = null) {
    this.resetTypeAndField();
    this.activeKeyword = keyword;
  }

  toggletBulkTagging(state: boolean) {
    this.bulkTagging = state;
  }

  async refetchTagItems() {
    const data = await getAllTagItems();
    this.setTagItems(data);
  }

  resetTypeAndField() {
    this.selectedTagType = null;
    this.setSelectedTagField(null);
  }

  setBuilderTagItems({
    keyword = null,
    type = null,
    field = null,
    field_option = null,
  }) {
    this.activeKeyword = keyword;
    this.selectedTagType = type;
    this.selectedTagField = field;
    const ids = _.flow(
      _.omitBy(_.isNil),
      _.map('id'),
    )([type, field, field_option]);
    this.selectedTagItemIds = _.flow(
      _.filter((id) => !_.includes(id, ids)),
      _.concat(ids),
      (ids) => {
        if (!_.get('metadata.multiselect', field) && field && field_option) {
          const idsToRemoveSet = new Set(
            _.flow(
              _.map('id'),
              _.filter((id) => id !== field_option.id),
            )(field.field_options),
          );
          return _.filter((id) => !idsToRemoveSet.has(id), ids);
        }
        return ids;
      },
    )(this.selectedTagItemIds);

    this.selectedTagItemIds = _.concat(
      _.filter((id) => !_.includes(id, ids), this.selectedTagItemIds),
      ids,
    );
  }

  removeKeywordAndTypes() {
    if (!this.activeKeyword) return;
    const idsToRemove = new Set<string>();
    const initialSelectedTagIdsSet = new Set(this.initialselectedTagItemIds);
    idsToRemove.add(this.activeKeyword.id as string);
    _.each(
      (type: { id: string }) => {
        _.each(
          (field: { id: string }) => {
            _.each(
              (fieldOption: { id: string }) => {
                initialSelectedTagIdsSet.has(fieldOption.id) &&
                  idsToRemove.add(fieldOption.id);
              },
              _.get('field_options', field),
            );
            initialSelectedTagIdsSet.has(field.id) && idsToRemove.add(field.id);
          },
          _.get('fields', type),
        );
        initialSelectedTagIdsSet.has(type.id) && idsToRemove.add(type.id);
      },
      _.get('activeKeyword.types', this),
    );
    for (const id of idsToRemove) {
      this.bulkIdsDeleteSet.add(id);
      this.removeBulkIntersectionId(id);
      this.removeBulkUnionId(id);
    }
    this.selectedTagItemIds = _.filter(
      (id) => !idsToRemove.has(id),
      this.selectedTagItemIds,
    );
    this.resetTypeAndField();
    this.activeKeyword = null;
  }

  get tagItemsToAddDelete() {
    const idsToAddSet: Set<string> = new Set(
      this.selectedTagItemIds.filter(
        (tag) =>
          !this.initialselectedTagItemIds.includes(tag) ||
          !this.bulkIdsIntersectionSet.has(tag),
      ),
    );

    const tagItemsToAdd = [];
    const tagItemsToRemove = [];
    _.each((keyword: { id: string }) => {
      _.each(
        (type: { id: string }) => {
          _.each(
            (field: { id: string }) => {
              _.each(
                (fieldOption: { id: string }) => {
                  if (idsToAddSet.has(fieldOption.id)) {
                    tagItemsToAdd.push(fieldOption);
                  }
                  if (this.bulkIdsDeleteSet.has(fieldOption.id)) {
                    tagItemsToRemove.push(fieldOption);
                  }
                },
                _.get('field_options', field),
              );
              if (idsToAddSet.has(field.id)) {
                tagItemsToAdd.push(field);
              }
              if (this.bulkIdsDeleteSet.has(field.id)) {
                tagItemsToRemove.push(field);
              }
            },
            _.get('fields', type),
          );
          if (idsToAddSet.has(type.id)) {
            tagItemsToAdd.push(type);
          }
          if (this.bulkIdsDeleteSet.has(type.id)) {
            tagItemsToRemove.push(type);
          }
        },
        _.get('types', keyword),
      );
      if (idsToAddSet.has(keyword.id)) {
        tagItemsToAdd.push(keyword);
      }
      if (this.bulkIdsDeleteSet.has(keyword.id)) {
        tagItemsToRemove.push(keyword);
      }
    }, this.tagItems);
    return {
      toAdd: _.sortBy((tag) => tag.name, tagItemsToAdd),
      toDelete: _.sortBy((tag) => tag.name, tagItemsToRemove),
    };
  }

  removeTypeAndFields() {
    if (!this.selectedTagType) return;
    const initialSelectedTagIdsSet = new Set(this.initialselectedTagItemIds);
    const idsToRemove = _.flow(
      _.reduce(
        (acc, { id, field_options }) => {
          if (this.bulkTagging) {
            acc = [
              ...acc,
              id,
              ..._.map(_.get('id'), field_options).filter((id) =>
                initialSelectedTagIdsSet.has(id),
              ),
            ];
          } else {
            acc = [...acc, id, ..._.map(_.get('id'), field_options)];
          }
          return acc;
        },
        [this.selectedTagType.id],
      ),
      _.compact,
      (ids) => new Set(ids),
    )(this.selectedTagType.fields);

    const numSelectedTypesBefore = _.flow(
      _.get('types'),
      _.map('id'),
      _.filter((id) => _.includes(id, this.selectedTagItemIds)),
      _.filter(_.identity),
      _.size,
    )(this.activeKeyword);

    if (numSelectedTypesBefore === 1) {
      idsToRemove.add(String(this.activeKeyword.id));
    }

    if (this.bulkTagging) {
      for (const id of idsToRemove) {
        this.bulkIdsDeleteSet.add(id);
        if (this.bulkIdsIntersectionSet.has(id)) {
          this.removeBulkIntersectionId(id);
        }
      }
    }

    this.selectedTagItemIds = _.filter(
      (id) => !idsToRemove.has(id),
      this.selectedTagItemIds,
    );

    this.resetTypeAndField();
  }

  removeField() {
    if (!this.selectedTagField) return;
    const idsToRemove = _.flow(
      _.map(({ id }) => id),
      _.concat(this.selectedTagField.id),
      _.compact,
      (ids) => new Set(ids),
    )(this.selectedTagField.field_options);

    this.selectedTagItemIds = _.filter(
      (id) => !idsToRemove.has(id),
      this.selectedTagItemIds,
    );
    this.selectedTagField = null;
  }

  constructor(parent: StoreMobx) {
    this.parent = parent;
    makeAutoObservable(this);
  }
}
