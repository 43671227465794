import _ from 'lodash/fp';
import { isMobile } from 'react-device-detect';
import { makeAutoObservable, flow } from 'mobx';
import { ICategory, IMapData } from './ImapData';
import { queryClient } from '../util/api/clients/queryClient';
import {
  fetchDefaultStyles,
  fetchMapById,
  fetchMapMarkersById,
  fetchMapPathsById,
  fetchMapPolygonsById,
  fetchCategoryIcons,
} from '../util/api';
import env from '../env';
import { QueryParamsMobx } from './queryParams.mobx';
import { MapHoursMobx } from './mapHours.mobx';
import { EmbedMobx } from './embed.mobx';
import { DrawMobx } from './draw.mobx';
import { PublicLayoutMobx, BuilderLayoutMobx } from './layout';
import { UserMobx } from './user.mobx';
import { EmbedCategoryMobx } from './embedCategory.mobx';
import { createLogger } from '../util/logger';
import version from '../_bump';
import { TagsMobx } from './tags.mobx';
import { UseEmbedEffectProps } from '../components/Embed';

export class StoreMobx {
  isBuilderInstance = false;
  logger = createLogger(`storeMobx`);
  mapHours = new MapHoursMobx(this);
  embed = new EmbedMobx(this);
  draw = new DrawMobx(this);
  user = new UserMobx(this);
  tags = new TagsMobx(this);
  queryParams = new QueryParamsMobx(this);
  layout = {
    page: {
      isMobile,
      isNotDesktop: isMobile,
    },
    public: new PublicLayoutMobx(this),
    builder: new BuilderLayoutMobx(this),
  };
  colorMode: 'light' | 'dark' = 'light';
  realtimeRandomId: string = null;

  setRealtimeRandomId = (id: string) => {
    this.realtimeRandomId = id;
  };

  setColorMode = (mode: 'light' | 'dark') => {
    this.colorMode = mode;
  };

  // do not delete, useful for debugging
  toggleColorMode = () => {
    this.colorMode = this.colorMode === 'light' ? 'dark' : 'light';
  };

  hydrate = (params) => {
    // applyParams(this, params);
  };

  extendLogger(name) {
    return this.logger.extend(name, env.wanderDebugger.delimiter);
  }

  loadMapById = (id: string) => {
    if (!id) return;
    fetchMapById(id)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then((data: IMapData) => {
        this.embed.setInitialData(
          {
            ...data,
            viewport: {
              longitude: data.map.point.coordinates[0],
              latitude: data.map.point.coordinates[1],
            },
          },
          id !== _.get('map.id', data),
        );
        this.mapHours.setMapHours(_.get('map.contact.opening_hours', data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.embed.setInitialData({ map: null });
        } else {
          throw error;
        }
      });
  };

  incrementalMapLoad = flow(function* incrementalMapLoad(
    effects: UseEmbedEffectProps,
  ) {
    const self = this as StoreMobx;
    try {
      if (!effects.data) {
        effects.data = yield fetchMapById(effects.mapId, effects.params);
      }
      self.embed.setInitialData(
        {
          ...effects.data,
          viewport: {
            longitude: effects.data.map.point.coordinates[0],
            latitude: effects.data.map.point.coordinates[1],
          },
        },
        effects.mapId !== _.get('map.id', effects.data),
      );
      self.mapHours.setMapHours(
        _.get('data.map.contact.opening_hours', effects),
      );
      const markers = yield fetchMapMarkersById(effects.mapId, effects.params);
      self.embed.mergeFeatures(markers);
      const paths = yield fetchMapPathsById(effects.mapId, effects.params);
      self.embed.mergeFeatures(paths);
      const polygons = yield fetchMapPolygonsById(
        effects.mapId,
        effects.params,
      );
      self.embed.mergeFeatures(polygons);

      if (effects.urlFeatureId && !self.embed.urlFeatureLoaded) {
        const feature = self.embed.getFeatureByID(effects.urlFeatureId);

        if (feature) {
          self.embed.setSelectedFeatureById(effects.urlFeatureId, false, true);
          self.layout.public.setSelectedFeature(feature);
          if (effects.device.isMobile) {
            self.embed.setActiveFeatureMobile(feature);
          }
        }
      } else if (effects.featureId) {
        self.embed.setSelectedFeatureById(effects.featureId);
      }

      self.handleMapLoadComplete();
      self.embed.setUrlFeatureLoaded(true);
    } catch (err) {
      self.logger('incrementalMapLoad failure', err);
    }
  });

  // Todo check this implementation
  createCategory = (data: ICategory) => {
    this.embed.categories.set(data.id, new EmbedCategoryMobx(this.embed, data));
  };

  deleteCategory = (data: ICategory) => {
    this.embed.categories.delete(data.id);
  };

  loadNewMap = async () => {
    const defaultStyles = await queryClient.fetchQuery('styles/map', () =>
      fetchDefaultStyles(),
    );

    this.embed.setInitialData({
      map: {
        id: 'local_map',
        categories: [],
        styles: defaultStyles[0],
        name: 'New Map',
        area_coordinates: null,
      },
      geoJSON: {
        features: [],
      },
      viewport: {
        longitude: -111,
        latitude: 46,
        zoom: 5,
      },
    });
  };

  fetchCategoryIcons = async () => {
    const iconsData = await fetchCategoryIcons();
    this.embed.setCategoryIcons(iconsData);
  };

  moveCategories = async (
    { from, to, mapId }: { from: number; to: number; mapId: string },
    apiRequest,
  ) => {
    try {
      const categoriesOld = this.embed.allNonLocalCategoriesArray.slice();
      categoriesOld.splice(to, 0, categoriesOld.splice(from, 1)[0]);
      // reorder all of them to make sure the sort order is consistent
      categoriesOld.forEach((cat, idx) => {
        this.embed.categories.get(cat.id).sort_order = idx;
      });

      await apiRequest({
        mapId,
        data: {
          order: categoriesOld.map((c) => ({
            category_id: c.id,
            sort_order: c.sort_order,
          })),
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  moveFeatures = async (
    { from, to, categoryId }: { from: number; to: number; categoryId: string },
    apiRequest,
  ) => {
    try {
      const category = this.embed.categories.get(categoryId);
      const features = category.filteredFeatures.slice();
      features.splice(to, 0, features.splice(from, 1)[0]);
      features.forEach((feature, idx) => {
        this.embed.features.get(feature.id).properties.sort_order = idx;
      });

      await apiRequest({
        data: {
          pois: features.map((f) => ({
            id: f.id,
            type: f.entityType.substring(0, _.size(f.entityType) - 1),
          })),
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // DEBUG

  removeSelectedFeatures = () => {
    // need to update this..
    const ids = _.map((x) => x.id, this.embed.selectedFeatures);
    this.embed.features.forEach((f) => {
      if (_.includes(f.id, ids)) {
        this.embed.features.delete(f.id);
      }
    });
  };

  updateSelectedFeaturesCategoryId = (id) => {
    this.embed.selectedFeatures.forEach((feat) => {
      feat.properties.category_id = id;
    });
  };

  updateFeatureProperties = (values) => {
    if (!this.embed.features.has(values.id)) return;
    const feature = this.embed.features.get(values.id);
    feature.properties = { ...feature.properties, ...values };
  };

  updateMapInfo = (values) => {
    const info = this.embed.map;
    this.embed.map = { ...info, ...values };
  };

  deleteFeature = (data) => {
    this.embed.features.delete(data.id);
  };

  setBuilderInstance = (isBuilder: boolean) => {
    this.isBuilderInstance = isBuilder;
  };

  handleMapLoadComplete = () => {
    // handle category query params
    if (
      _.negate(_.isNil)(this.queryParams.params.category) &&
      this.embed.categories.has(this.queryParams.params.category)
    ) {
      const categoryFromQueryParam = this.embed.categories.get(
        this.queryParams.params.category,
      );
      categoryFromQueryParam.fitBounds();
    }
  };

  /*
    In Chromium-based web browsers (e.g. Brave, Chrome, and Electron),
    the JavaScript console will—by default—only show messages logged
    by debug if the "Verbose" log level is enabled.

    See -> https://github.com/debug-js/debug#browser-support
  */
  enableLogger() {
    window.localStorage.setItem('debug', `${env.wanderDebugger.name}*`);
    window.location.reload();
  }

  get version() {
    return version;
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  // Make sure each non observable is located below to the maeAutoObservable function
  constructor() {
    makeAutoObservable(this, { logger: false, extendLogger: false });
    if (env.runtime.isBrowser) {
      window.wanderApp = this;
    }
  }
}

export const IStoreMobx = typeof StoreMobx;
