import client from '../client';
import { extractData } from '../../extract-data';
import {
  IPatchSyncMapListsDto,
  IPatchSyncMapPinsDto,
  IPatchUserListPOIs,
  IPatchUserMapListDto,
  IPatchUserPinsDto,
  IPostUserMapListDto,
} from '@wander-app/shared';

export const getMe = (options: { permissions?: boolean; plan?: boolean }) =>
  client.get('/users/me', { params: options }).then(extractData);

export const getMapPins = (mapId: string) =>
  client.get(`/users/maps/${mapId}/pins`).then(extractData);

export const getMapLists = (mapId: string) =>
  client.get(`/users/maps/${mapId}/lists`).then(extractData);

export const getMapList = ({
  mapId,
  listName,
}: {
  mapId: string;
  listName: string;
}) => client.get(`/users/maps/${mapId}/lists/${listName}`).then(extractData);

export const pinOrUnnpinPoi = ({
  mapId,
  data,
}: {
  mapId: string;
  data: IPatchUserPinsDto;
}) => client.patch(`/users/maps/${mapId}/pins`, data).then(extractData);

export const createMapList = ({
  mapId,
  data,
}: {
  mapId: string;
  data: IPostUserMapListDto;
}) => client.post(`/users/maps/${mapId}/lists`, data).then(extractData);

export const updateMapList = ({
  mapId,
  listName,
  data,
}: {
  mapId: string;
  listName: string;
  data: IPatchUserMapListDto;
}) =>
  client
    .patch(`/users/maps/${mapId}/lists/${listName}`, data)
    .then(extractData);

export const updateMapListPois = ({
  mapId,
  listName,
  data,
}: {
  mapId: string;
  listName: string;
  data: IPatchUserListPOIs;
}) =>
  client
    .patch(`/users/maps/${mapId}/lists/${listName}/pois`, data)
    .then(extractData);

export const deleteMapList = ({
  mapId,
  listName,
}: {
  mapId: string;
  listName: string;
}) => client.delete(`/users/maps/${mapId}/lists/${listName}`).then(extractData);

export const syncMapPins = (data: IPatchSyncMapPinsDto) =>
  client.patch(`/users/sync/pins`, data).then(extractData);

export const syncMapLists = (data: IPatchSyncMapListsDto) =>
  client.patch(`/users/sync/lists`, data).then(extractData);
