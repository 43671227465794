import { LOCAL_CATEGORY_ID } from './mobx.utils';

export const localCategory = {
  default_selected: true,
  path_label_text_keep_upright: true,
  circle_icon_size: null,
  circle_icon_stroke_width: null,
  marker_label_text_halo_blur: 0.2,
  marker_label_text_halo_width: 2.0,
  marker_label_text_letter_spacing: 0.0,
  marker_label_text_padding: 0.05,
  marker_label_text_size: 14.0,
  path_label_symbol_spacing: 100.0,
  path_label_text_halo_blur: 1.0,
  // path_label_text_halo_width is deprected, should use path_label_halo_width instead
  path_label_text_halo_width: 1.0,
  path_label_text_letter_spacing: 0.05,
  path_label_text_padding: 0.05,
  path_label_text_size: 16.0,
  path_line_blur: 0.0,
  path_line_gap_width: 0.0,
  path_line_miter_limit: 2.0,
  path_line_offset: 0.0,
  path_line_opacity: 0.9,
  path_line_round_limit: 1.05,
  path_line_width: 2.0,
  cluster_radius: 50,
  marker_label_text_max_width: 80,
  path_label_halo_width: 1.0,
  path_label_maxzoom: null,
  path_label_minzoom: null,
  path_label_text_max_width: 100,
  path_line_halo_width: 1,
  path_line_sort_key: null,
  marker_label_text_font: ['Open Sans Bold'],
  marker_label_text_offset: [0, -6],
  marker_label_text_translate: [0, 0],
  path_label_text_font: ['Open Sans Bold'],
  path_label_text_offset: [0, 0],
  path_label_text_translate: [0, 0],
  path_line_dasharray: [0, 0],
  path_line_translate: [0, 0],
  color: '#000000',
  icon: 'Running',
  marker_label_symbol_placement: 'point',
  marker_label_symbol_z_order: 'auto',
  marker_label_text_anchor: 'bottom',
  marker_label_text_color: null,
  marker_label_text_halo_color: '#FFFFFF',
  marker_label_text_justify: 'center',
  marker_label_text_pitch_alignment: 'auto',
  marker_label_text_transform: 'none',
  name: 'local category',
  path_label_color: '#FFFFFF',
  path_label_halo_color: '#FFFFFF',
  path_label_symbol_placement: 'line',
  path_label_symbol_z_order: 'auto',
  path_label_text_anchor: 'center',
  path_label_text_halo_color: null,
  path_label_text_justify: 'center',
  path_label_text_pitch_alignment: 'viewport',
  path_label_text_transform: 'none',
  path_line_cap: 'round',
  path_line_color: null,
  path_line_gradient: null,
  path_line_halo_color: null,
  path_line_join: 'round',
  path_line_pattern: null,
  path_line_translate_anchor: 'auto',
  created_at: '2021-11-16T12:28:19.615Z',
  id: LOCAL_CATEGORY_ID,
  map_id: '3af417a8-ef0f-4883-806b-669f1fe969bd',
  sort_order: 0,
  marker_label_icon_allow_overlap: false,
  marker_label_icon_ignore_placement: false,
  marker_label_text_allow_overlap: false,
  marker_label_text_ignore_placement: false,
  marker_icon_icon_allow_overlap: true,
  marker_icon_icon_ignore_placement: true,
  marker_icon_text_allow_overlap: true,
  marker_icon_text_ignore_placement: true,
  path_markers_visible: true,
  path_label_icon_allow_overlap: false,
  path_label_icon_ignore_placement: false,
  path_label_text_allow_overlap: false,
  path_label_text_ignore_placement: false,
  marker_icon_halo_blur: 0.5,
  marker_icon_halo_width: 1.0,
  marker_label_radial_offset: 2.0,
  marker_transition_zoom: 15.0,
  icon_size: 'MEDIUM',
  cluster_max_zoom: 18,
  cluster_min_points: 2,
  marker_text_variable_anchor: ['bottom'],
  marker_icon_color: null,
  marker_icon_halo_color: null,
  static: false,
  marker_icon_type: 'PRIMARY',
  marker_icon_only: false,
  path_label_text_symbol_spacing: 250,
  poly_fill_antialias: true,
  poly_fill_color: 'rgba(5,84,184,0.74)',
  poly_fill_opacity: 0.4,
  poly_fill_translate: [0, 0],
  poly_fill_translate_anchor: 'map',
  poly_fill_outline_color: '#3a3a3a',
};
