import { useCallback } from 'react';
import mixpanel from '../util/mixpanel';
import { useStore } from './store.hooks';
import { useLogger } from './useLogger';
import _ from 'lodash/fp';
import { useSessionId } from './useSessionId';
import { useMediaQueries } from './useMediaQueries';

export function useTracking(): {
  track: (event: string, data?: Record<string, unknown>) => void;
} {
  const logger = useLogger('mixpanel');
  const store = useStore();
  const sessionData = useSessionId();
  const { isMobile } = useMediaQueries();

  const track = useCallback(
    (event: string, data: Record<string, unknown> = {}) => {
      // @TODO permissions-check
      const params = {
        ...data,
        ...store.embed.trackingData,
        wander_user:
          store.user?.claims?.email?.includes('@wandermaps.com') ?? false,
        session_id: sessionData && sessionData.sessionId,
        mobile_web: isMobile,
        distinct_id:
          store.user?.claims?.user_id ||
          (sessionData && sessionData.distinctId),
      };
      logger('mixpanel', event, params);
      mixpanel.track(_.lowerCase(event), params);
    },
    [logger, store.embed.trackingData, store.user?.claims],
  );

  return { track };
}
