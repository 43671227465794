import _ from 'lodash/fp';
import { nanoid } from 'nanoid';
import { StoreMobx } from './store.mobx';
import { applyParams } from './mobx.utils';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { EmbedMobx } from './embed.mobx';
import { makeAutoObservable, observable } from 'mobx';
import { mapToArray } from '../util/store.util';

export class SegmentBundleMobx {
  parent: EmbedMobx;
  selected: boolean;
  features = observable.map<string, EmbedFeatureMobx>();
  merge_property_one: string;
  merge_property_two: string;
  isOpen: boolean;
  dynamicKey = '';

  constructor(parent: EmbedMobx, data: any) {
    this.parent = parent;
    this.selected = true;
    this.isOpen = false;
    applyParams(this, data);
    makeAutoObservable(this);
    this.generateDynamicKey();
  }

  get root(): StoreMobx {
    return this.parent.parent;
  }

  get selectedFeatures(): any {
    return _.filter(
      (feature: EmbedFeatureMobx) => feature.selected,
      mapToArray(this.features),
    );
  }

  toggleSelected(selected) {
    this.selected = selected;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  generateDynamicKey() {
    this.dynamicKey = nanoid();
  }

  addFeatures(features) {
    _.each((feature) => {
      this.features.set(feature.id, feature);
    }, features);
  }
}
