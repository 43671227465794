import _ from 'lodash/fp';
import { runInAction } from 'mobx';
import { nanoid } from 'nanoid';

export const applyParams = (ctx, data = {}) => {
  runInAction(() => {
    Object.assign(ctx, data);
  });
};

export const FUZZY_SEARCH_THRESHOLD = 0.7;

const LOCAL_IDENTIFIER = 'local_';

export const LOCAL_CATEGORY_ID = 'local_category';

export const getLocalID = () => `${LOCAL_IDENTIFIER}${nanoid()}`;

export const isLocalEntity = _.startsWith(LOCAL_IDENTIFIER);
