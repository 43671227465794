import {
  object,
  boolean,
  number,
  mixed,
  array,
  string,
  AnyObjectSchema,
  lazy,
} from 'yup';
import { MarkerIconType, phoneRegExp, IHours, websiteRegExp } from '../../helpers';

export const addMarkerSchema: AnyObjectSchema = object({
  icon_only: boolean(),
  elevation: number(),
  marker_icon_type: mixed<MarkerIconType>().oneOf(
    Object.values(MarkerIconType),
  ),
  hours: object({
    open_now: boolean(),
    periods: array().of(
      object({
        open: object({
          day: number().required(),
          time: string().required(),
          hours: number(),
          minutes: number(),
          nextDate: number(),
        }),
        close: object({
          day: number().required(),
          time: string().required(),
          hours: number(),
          minutes: number(),
          nextDate: number(),
        }),
      }),
    ),
  }),
  photos: array().of(string().url()),
  point: array().of(number()).min(2).max(3),
  address: string().nullable(),
  google_place_id: string(),
  name: string().required(),
  phone: string()
    .matches(phoneRegExp, 'please enter a valid phone number')
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(),
  website: string()
    .matches(websiteRegExp, 'please enter a valid website')
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(),
  description: string().nullable(),
  building_id: string().uuid(),
  category_id: string()
    .required('category is a required field')
    .uuid('category is a required field'),
  floor_id: string(),
  metadata: object(),
});
