export enum MapVersionVisibilityType {
  'marker' = 'marker',
  'path' = 'path',
  'polygon' = 'polygon',
  'category' = 'category',
  'all' = 'all',
}

export interface IPatchMapVersionVisibilitiesDto {
  id?: string;
  type: MapVersionVisibilityType;
  show: boolean;
}
