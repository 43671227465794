import { object, boolean, number, array, string, AnyObjectSchema } from 'yup';

export const addPathSchema: AnyObjectSchema = object({
  start_elevation: number(),
  end_elevation: number(),
  length: number(),
  hours: object({
    open_now: boolean(),
    periods: array().of(
      object({
        open: object({
          day: number().required(),
          time: string().required(),
          hours: number(),
          minutes: number(),
          nextDate: number(),
        }),
        close: object({
          day: number().required(),
          time: string().required(),
          hours: number(),
          minutes: number(),
          nextDate: number(),
        }),
      }),
    ),
  }),
  photos: array().of(string().url()),
  coordinates: object(), // more details
  name: string().required(),
  description: string().nullable(),
  category_id: string()
    .required('category is a required field')
    .uuid('category is a required field'),
  metadata: object(),
  default_pitch: number().required(),
  default_bearing: number().required(),
});
