import { makeAutoObservable, toJS } from 'mobx';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { nanoid } from 'nanoid';
import _ from 'lodash/fp';
import { IDays as DBDays } from './ImapData';

const getDefaultHours = () => [
  {
    id: nanoid(),
    from: null,
    to: null,
  },
];

type IHourRange = {
  id: string;
  from: string;
  to: string;
};

type IDay = {
  id: string;
  from: string;
  to: string;
  isClosed: boolean;
  hours: IHourRange[];
};

type IDays = IDay[];

const changeDateFormatToGoogle = (inputDate) => {
  const date = new Date(inputDate);
  if (date.getTime()) {
    const day = date.getDate().toString();
    // Months use 0 index.
    const month = (date.getMonth() + 1).toString();
    return (
      (month[1] ? month : '0' + month[0]) +
      '/' +
      (day[1] ? day : '0' + day[0]) +
      '/' +
      date.getFullYear()
    );
  }
};

const changeDateFormatFromGoogle = (inputDate) => {
  const date = inputDate.split('/').reverse();
  const tmp = date[2];
  date[2] = date[1];
  date[1] = tmp;
  return date.join('-');
};

const getDefaultGoogleHours = () => [
  {
    isClosed: false,
    hours: getDefaultHours(),
    id: nanoid(),
    from: null,
    to: null,
  },
];

export class featureCustomHoursMobx {
  parent: EmbedFeatureMobx;
  customHours = getDefaultGoogleHours();

  constructor(parent: EmbedFeatureMobx) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  convertHoursFromGoogle(customHours: DBDays) {
    if (_.size(_.get('periods', customHours)) === 0)
      return this.setMapHours(getDefaultGoogleHours());
    const mappedDays = [];
    _.each((period) => {
      const hours = {
        id: nanoid(),
        from: period.open.time
          .substring(0, 2)
          .concat(':')
          .concat(period.open.time.substring(2)),
        to: period.close?.time
          .substring(0, 2)
          .concat(':')
          .concat(period.close?.time.substring(2)),
      };
      let hoursData = [];
      if (mappedDays[period.close?.custom_index]?.hours) {
        hoursData = [
          ...(mappedDays[period.close?.custom_index]?.hours ?? {}),
          hours,
        ];
      } else hoursData = [hours];
      mappedDays[period.close?.custom_index] = {
        id: nanoid(),
        isClosed: false,
        from: changeDateFormatFromGoogle(
          customHours.custom_text[period.close?.custom_index][0],
        ),
        to: changeDateFormatFromGoogle(
          customHours.custom_text[period.close?.custom_index][2],
        ),
        hours: [...hoursData],
      };
    }, customHours.periods);
    this.setMapHours(mappedDays as IDays);
  }

  convertHoursToGoogle(customDays: IDays) {
    const periods = _.flow(
      _.filter(([, { isClosed, hours }]) => !isClosed),

      _.reduce(function generatePeriods(
        periods,
        [day, { hours }]: [
          number,
          {
            isClosed: boolean;
            hours: IHourRange[];
          },
        ],
      ) {
        const dayNumber = _.toNumber(day);
        const newPeriods = _.flow(
          _.filter(({ from, to }) => from),
          _.map(({ from, to }) => ({
            open: { custom_index: dayNumber, time: _.replace(':', '', from) },
            close: { custom_index: dayNumber, time: _.replace(':', '', to) },
          })),
          _.sortBy((period) => [
            period.open.custom_index,
            _.parseInt(10, period.open.time),
          ]),
        )(hours);
        return [...periods, ...newPeriods];
      }, []),
      _.sortBy((period) => period.open.custom_index),
    )(_.entries(customDays));

    const custom_text = _.flow(
      // _.sortBy(([day]) => _.indexOf(day, DAYS_OF_WEEK)),
      _.map(({ id, from, to, isClosed, hours }: IDay) => {
        const hoursText = isClosed
          ? 'Closed'
          : _.flow(
              _.sortBy((hour: IHourRange) =>
                _.parseInt(10, _.replace(':', '', hour.from)),
              ),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              _.map.convert({ cap: false })(({ from, to }, index, hours) => {
                const convertTime = (time) => {
                  if (time === ':' || time === null) return '';
                  if (time < '12:00') {
                    return `${time} AM`;
                  } else {
                    const [hours, minutes] = _.split(':', time);
                    return `${
                      hours === '12' ? '12' : _.parseInt(10, hours) - 12
                    }:${minutes} PM`;
                  }
                };
                const fromStr = convertTime(from);
                const toStr = convertTime(to);
                const hoursStr = _.isEqual(fromStr, toStr)
                  ? '24 Hours'
                  : !toStr
                  ? `Opens ${fromStr}`
                  : `${fromStr} - ${toStr}`;
                const arrSize = _.size(hours);
                const suffix =
                  arrSize === 1 || index === arrSize - 1
                    ? ''
                    : arrSize === 2
                    ? ' and '
                    : index === arrSize - 2
                    ? ', and '
                    : ', ';
                return `${hoursStr}${suffix}`;
              }),
              _.join(''),
            )(_.filter(({ from, to }) => from, hours));
        return hoursText
          ? [
              changeDateFormatToGoogle(from),
              ' - ',
              changeDateFormatToGoogle(to),
              `: ${hoursText}`,
            ]
          : '';
      }),
      _.filter((text) => !!text),
    )(customDays);

    return {
      periods,
      custom_text,
    };
  }

  setMapHours = (customHours: IDays) => {
    this.customHours = customHours;
  };

  addHours = (day) => {
    this.customHours[day].hours.push({
      id: nanoid(),
      from: null,
      to: null,
    });
  };

  addDay = () => {
    this.customHours.push({
      isClosed: false,
      hours: getDefaultHours(),
      id: nanoid(),
      from: null,
      to: null,
    });
  };

  removeHours = (day: number, id: string) => {
    this.customHours[day].hours = _.filter(
      (hours) => hours.id !== id,
      this.customHours[day].hours,
    );
  };

  removeDay = (day: string) => {
    this.customHours = _.filter((d) => d.id !== day, this.customHours);
  };

  toggleClosed(id: string) {
    _.each((customDay) => {
      if (customDay.id !== id) return;
      customDay.isClosed = !customDay.isClosed;
    }, this.customHours);
  }

  changeHours(
    day: number,
    id: string,
    key: 'from' | 'to',
    value: string | null,
  ) {
    _.each((hours) => {
      if (hours.id !== id) return;
      hours[key] = value;
    }, this.customHours[day].hours);
  }

  changeDay(id: string, key: 'from' | 'to', value: string | null) {
    _.each((customDay) => {
      if (customDay.id !== id) return;
      customDay[key] = value;
    }, this.customHours);
  }

  toJS() {
    return toJS(this.customHours);
  }
}
