import { nextAPIClient } from '../client';
import { extractData } from '../../extract-data';
import { Subject } from 'rxjs';

export const unauthorizedAnalytics$ = new Subject();

export const fetchAnalytics = (options: any = {}) =>
  nextAPIClient
    .get(`/analytics`, {
      params: {
        ...options.params,
      },
    })
    .then(extractData)
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        unauthorizedAnalytics$.next(null);
      }
      return Promise.reject(err);
    });
