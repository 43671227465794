import {
  PatchUserListPOIAction,
  PINNED_PLACES_LIST_NAME,
} from '@wander-app/shared';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  createMapList,
  deleteMapList,
  getMapLists,
  updateMapList,
  updateMapListPois,
} from '../util/api';
import { useMapId } from './map.hooks';
import { useStore } from './store.hooks';
import { useErrorToast } from './toasts';
import { useTracking } from './useTracking';
import * as _ from 'lodash/fp';

enum FeatureTypePinType {
  markers = 'marker',
  paths = 'path',
  polygons = 'polygon',
}

export const useBookmark = () => {
  const store = useStore();
  const errorToast = useErrorToast();
  const mapIdentifier = useMapId();
  const bookMarkLists = store.embed.bookMarkLists;
  const mapBookMarkLists = store.embed.bookMarkLists?.[mapIdentifier];
  const tracker = useTracking();
  const isLoggedIn = store.user.isLoggedIn;

  useQuery(['mapLists', mapIdentifier], () => getMapLists(mapIdentifier), {
    enabled:
      isLoggedIn && store.user.pinListsSynced && !store.user.listsFetched,
    onSuccess: (data) => {
      store.user.setListsFetched(true);
      store.embed.mergeBookMarkLists(data);
    },
  });

  const createList = useMutation(createMapList, {
    onError: () => {
      errorToast('Error creating your new list');
    },
  });
  const updateList = useMutation(updateMapList, {
    onError: () => {
      errorToast('Error updating your list');
    },
  });
  const deleteList = useMutation(deleteMapList, {
    onError: () => {
      errorToast('Error deleting your list');
    },
  });
  const updateListPois = useMutation(updateMapListPois, {
    onError: () => {
      errorToast('Error updating the poi from your list');
    },
  });

  useEffect(() => {
    if (!isLoggedIn) {
      store.embed.resetBookMarksToLocalStorage();
    }
  }, []);

  const createNewBookMarkList = async (
    listName: string,
    featureId?: string,
    featureType?: string,
  ) => {
    const originalBookMarkLists = _.cloneDeep(bookMarkLists);

    const newBookMarkLists = {
      ...bookMarkLists,
      [mapIdentifier]: {
        ...bookMarkLists?.[mapIdentifier],
        [listName]:
          featureId && featureType
            ? {
                [featureType]: bookMarkLists?.[mapIdentifier]?.[listName]?.[
                  featureType
                ]
                  ? [
                      ...bookMarkLists[mapIdentifier][listName][featureType],
                      featureId,
                    ]
                  : [featureId],
              }
            : {},
      },
    };

    if (typeof window !== 'undefined') {
      if (!isLoggedIn) {
        window.localStorage.setItem(
          'BOOKMARK-LISTS',
          JSON.stringify(newBookMarkLists),
        );
      }
      store.embed.setBookMarkLists(newBookMarkLists);
      tracker.track(
        isLoggedIn ? 'list created' : 'list created by non-signed-in user',
        { listName },
      );
    }

    if (isLoggedIn) {
      try {
        const { id: listId } = await createList.mutateAsync({
          mapId: mapIdentifier,
          data: {
            name: listName,
          },
        });

        if (listId && featureId && featureType) {
          await updateListPois.mutateAsync({
            mapId: mapIdentifier,
            listName,
            data: {
              type: FeatureTypePinType[featureType],
              poi_id: featureId,
              operation: PatchUserListPOIAction.Add,
            },
          });
        }
      } catch (error) {
        console.log(error);
        store.embed.setBookMarkLists(originalBookMarkLists);
      }
    }
  };

  const deleteBookMarkList = async (listName: string) => {
    const originalBookMarkLists = JSON.parse(JSON.stringify(bookMarkLists));
    const copiedPins =
      listName === PINNED_PLACES_LIST_NAME &&
      JSON.parse(JSON.stringify(store.embed.pinnedList));

    if (copiedPins) {
      store.embed.clearPinsByMapId(mapIdentifier);
    }

    const bookMarkListsCopy = JSON.parse(JSON.stringify(bookMarkLists));
    delete bookMarkListsCopy[mapIdentifier][listName];

    if (typeof window !== 'undefined') {
      if (!isLoggedIn) {
        window.localStorage.setItem(
          'BOOKMARK-LISTS',
          JSON.stringify(bookMarkListsCopy),
        );
      }
      store.embed.setBookMarkLists(bookMarkListsCopy);

      tracker.track(
        isLoggedIn ? 'list deleted' : 'list deleted by non-signed-in user',
        { listName },
      );
      if (listName === store.tags.selecetedList) {
        store.tags.setSelectedList('');
      }
    }

    if (isLoggedIn) {
      try {
        await deleteList.mutateAsync({
          mapId: mapIdentifier,
          listName,
        });
      } catch (error) {
        console.log(error);
        store.embed.setPinnedList(copiedPins);
        store.embed.setBookMarkLists(originalBookMarkLists);
      }
    }
  };

  const addFeatureToBookMarkList = async (
    listName: string,
    featureId: string,
    featureType: string,
  ) => {
    const originalBookMarkLists = _.cloneDeep(bookMarkLists);

    const newBookMarkLists = {
      ...bookMarkLists,
      [mapIdentifier]: {
        ...bookMarkLists?.[mapIdentifier],
        [listName]: {
          ...bookMarkLists?.[mapIdentifier]?.[listName],
          [featureType]: bookMarkLists?.[mapIdentifier]?.[listName]?.[
            featureType
          ]
            ? [
                ...bookMarkLists[mapIdentifier][listName][featureType],
                featureId,
              ]
            : [featureId],
        },
      },
    };

    if (typeof window !== 'undefined') {
      if (!isLoggedIn) {
        window.localStorage.setItem(
          'BOOKMARK-LISTS',
          JSON.stringify(newBookMarkLists),
        );
      }
      store.embed.setBookMarkLists(newBookMarkLists);
      tracker.track(
        isLoggedIn
          ? 'feature added to list'
          : 'feature added to list by non-signed-in user',
        { featureId, listName },
      );
    }

    if (isLoggedIn) {
      try {
        await updateListPois.mutateAsync({
          mapId: mapIdentifier,
          listName,
          data: {
            type: FeatureTypePinType[featureType],
            poi_id: featureId,
            operation: PatchUserListPOIAction.Add,
          },
        });
      } catch (error) {
        console.log(error);
        store.embed.setBookMarkLists(originalBookMarkLists);
      }
    }
  };

  const removeFeatureFromBookMarkList = async (
    listName: string,
    featureId: string,
    featureType: string,
  ) => {
    const originalBookMarkLists = JSON.parse(JSON.stringify(bookMarkLists));

    const newBookMarkLists = {
      ...bookMarkLists,
      [mapIdentifier]: {
        ...bookMarkLists?.[mapIdentifier],
        [listName]: {
          ...bookMarkLists?.[mapIdentifier]?.[listName],
          [featureType]: bookMarkLists?.[mapIdentifier]?.[listName]?.[
            featureType
          ]
            ? bookMarkLists[mapIdentifier][listName][featureType].filter(
                (feature) => feature !== featureId,
              )
            : [],
        },
      },
    };

    if (typeof window !== 'undefined') {
      if (!isLoggedIn) {
        window.localStorage.setItem(
          'BOOKMARK-LISTS',
          JSON.stringify(newBookMarkLists),
        );
      }
      store.embed.setBookMarkLists(newBookMarkLists);
      tracker.track(
        isLoggedIn
          ? 'feature removed from list'
          : 'feature removed from list by non-signed-in user',
        { featureId, listName },
      );
    }

    if (isLoggedIn) {
      try {
        await updateListPois.mutateAsync({
          mapId: mapIdentifier,
          listName,
          data: {
            type: FeatureTypePinType[featureType],
            poi_id: featureId,
            operation: PatchUserListPOIAction.Remove,
          },
        });
      } catch (error) {
        console.log(error);
        store.embed.setBookMarkLists(originalBookMarkLists);
      }
    }
  };

  const renameBookMarkList = async (listName: string, newListName: string) => {
    if (listName === newListName) {
      return;
    }

    const originalBookMarkLists = JSON.parse(JSON.stringify(bookMarkLists));

    let bookMarkListsCopy = JSON.parse(JSON.stringify(bookMarkLists));
    bookMarkListsCopy = {
      ...bookMarkListsCopy,
      [mapIdentifier]: {
        ...bookMarkListsCopy?.[mapIdentifier],
        [newListName]: bookMarkListsCopy[mapIdentifier][listName],
      },
    };
    delete bookMarkListsCopy[mapIdentifier][listName];

    if (typeof window !== 'undefined') {
      if (!isLoggedIn) {
        window.localStorage.setItem(
          'BOOKMARK-LISTS',
          JSON.stringify(bookMarkListsCopy),
        );
        tracker.track('bookmark list renamed by non-signed-in user');
      }
      store.embed.setBookMarkLists(bookMarkListsCopy);
      store.tags?.setSelectedList(newListName);
    }

    if (isLoggedIn) {
      try {
        await updateList.mutateAsync({
          mapId: mapIdentifier,
          listName,
          data: {
            name: newListName,
          },
        });
      } catch (error) {
        console.log(error);
        store.embed.setBookMarkLists(originalBookMarkLists);
        store.tags?.setSelectedList(listName);
      }
    }
  };

  return {
    bookMarkLists,
    mapBookMarkLists,
    createNewBookMarkList,
    deleteBookMarkList,
    addFeatureToBookMarkList,
    renameBookMarkList,
    removeFeatureFromBookMarkList,
  };
};
