import client from '../client';
import { extractData } from '../../extract-data';
import {
  IPatchMapVersionDto,
  IPatchMapVersionVisibilitiesDto,
  IPostMapVersionDto,
} from '@wander-app/shared';

export const addMapVersion = ({
  mapId,
  data,
}: {
  mapId;
  data: IPostMapVersionDto;
}) => client.post(`/maps/${mapId}/versions`, data).then(extractData);

export const editMapVersion = ({
  mapId,
  versionId,
  data,
}: {
  mapId;
  versionId;
  data: IPatchMapVersionDto;
}) =>
  client.patch(`/maps/${mapId}/versions/${versionId}`, data).then(extractData);

export const deleteMapVersion = ({ mapId, versionId }: { mapId; versionId }) =>
  client.delete(`/maps/${mapId}/versions/${versionId}`).then(extractData);

export const editMapVersionVisibilities = ({
  mapId,
  versionId,
  data,
}: {
  mapId: string;
  versionId: string;
  data: IPatchMapVersionVisibilitiesDto;
}) =>
  client
    .patch(`/maps/${mapId}/versions/${versionId}/visibilities`, data)
    .then(extractData);

export const getMapVersions = (mapId: string) =>
  client.get(`/maps/${mapId}/versions`).then(extractData);

export const getMapVersion = ({
  mapId,
  versionId,
}: {
  mapId: string;
  versionId: string;
}) => client.get(`/maps/${mapId}/versions/${versionId}`).then(extractData);
