const t = 'Wander';
const d = 'Destination Maps';
const url = 'https://wandermaps.com';
const defaultPhoto =
  'https://firebasestorage.googleapis.com/v0/b/wander-production-308019.appspot.com/o/WMSCS21AC-16_2_1_70-resized.jpeg?alt=media&token=d3dac839-bfcf-492b-87c8-593572f93616';

const SEO = (title = t, description = d, photoUrl = defaultPhoto) => ({
  title,
  description,
  canonical: url,
  openGraph: {
    type: 'website',
    url,
    title,
    description,
    images: [
      {
        url: photoUrl,
        alt: title,
        height: 1048,
        width: 1170,
      },
    ],
  },
  // twitter: {
  //   cardType: 'summary_large_image',
  //   handle: '@hendraaagil',
  //   site: '@hendraaagil',
  // },
  additionalLinkTags: [{ rel: 'icon', href: '/favicon.ico' }],
});

export default SEO;
