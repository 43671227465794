import { useStore } from './store.hooks';
import { buffer, polygon, simplify } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { useCallback, useMemo } from 'react';

export const useResetMapBounds = () => {
  const store = useStore();

  const config = useMemo(() => {
    if (!store.embed.map) return;
    const coordinates = store.embed.map.area_coordinates?.coordinates;

    let nextConfig;

    if (coordinates) {
      nextConfig = {
        zoom: undefined,
        bounds: undefined,
        fitBoundsOptions: undefined,
      };
      const biggerBounds = simplify(
        buffer(polygon(coordinates), 0.2, {
          units: 'kilometers',
        }),
        { tolerance: 0.01, highQuality: false },
      ).geometry.coordinates[0].reduce(
        (bounds, coords) =>
          bounds.extend(coords as [number, number, number, number]),
        new mapboxgl.LngLatBounds(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          coordinates[0][0],
          coordinates[0][0],
        ),
      );

      nextConfig.bounds = biggerBounds;
      nextConfig.fitBoundsOptions = {
        pitch: store.embed.map.default_pitch,
        bearing: store.embed.map.default_bearing,
      };
    }

    return nextConfig;
  }, [store.embed.map]);

  return useCallback(async () => {
    if (store.embed.mapInstance && config) {
      store.embed.mapInstance.fitBounds(config.bounds, config.fitBoundsOptions);
      await store.embed.mapInstance.once('idle');
      store.embed.onManualViewportChange();
    }
  }, [config]);
};
