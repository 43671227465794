import _ from 'lodash/fp';
import F from 'futil';

const textTransform = F.when(
  (x: string) => !_.includes(x, ['none', 'uppercase', 'lowercase']),
  () => 'none',
);

const toNumberOr = (x, or = 0) => {
  const num = Number(x);
  return _.isNaN(num) ? or : num;
};

export default {
  textTransform,
  toNumberOr,
};
