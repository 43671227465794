import * as ENV_VARS from './env-vars';

const firebaseAdminInitConfig = {
  credential: {
    projectId: ENV_VARS.NEXT_PUBLIC_FIREBASE_ADMIN_PROJECT_ID,
    clientEmail: ENV_VARS.FIREBASE_ADMIN_CLIENT_EMAIL,
    privateKey: ENV_VARS.FIREBASE_ADMIN_PRIVATE_KEY
      ? ENV_VARS.FIREBASE_ADMIN_PRIVATE_KEY.replace(/\\n/g, '\n')
      : undefined,
  },
  databaseURL: ENV_VARS.NEXT_PUBLIC_FIREBASE_ADMIN_DATABASE_URL,
  storageBucket: ENV_VARS.FIREBASE_ADMIN_STORAGE_BUCKET,
};

const firebaseClientInitConfig = {
  apiKey: ENV_VARS.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: ENV_VARS.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_VARS.NEXT_PUBLIC_FIREBASE_ADMIN_DATABASE_URL,
  projectId: ENV_VARS.NEXT_PUBLIC_FIREBASE_ADMIN_PROJECT_ID,
  storageBucket: ENV_VARS.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_VARS.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV_VARS.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const cookies = {
  authSessionCookieName: 'wander-api',
  currentSecret: ENV_VARS.COOKIE_SECRET_CURRENT,
  previousSecret: ENV_VARS.COOKIE_SECRET_PREVIOUS,
};

const isServer = typeof window === 'undefined';

const runtime = {
  isServer,
  isBrowser: !isServer,
  apiUrl: ENV_VARS.API_URL,
  isProduction: ENV_VARS.NODE_ENV === 'production',
  maxRequestSize: ENV_VARS.MAX_REQ_SIZE_MB,
};

const mapConfig = {
  MAPBOX_ACCESS_TOKEN: ENV_VARS.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
  MAPBOX_USERID: ENV_VARS.NEXT_PUBLIC_MAPBOX_USER_ID,
  MAPBOX_STYLEID: ENV_VARS.NEXT_PUBLIC_MAPBOX_STYLEID,
};

const WANDER_DELIMITER = ':';

const wanderDebugger = {
  name: 'wander-app',
  delimiter: WANDER_DELIMITER,
};

const mixpanel = {
  token: ENV_VARS.MIXPANEL_PROJECT_TOKEN,
  serviceUser: ENV_VARS.MIXPANEL_SERVICE_ACCOUNT_USER,
  servicePass: ENV_VARS.MIXPANEL_SERVICE_ACCOUNT_PASS,
  projectId: ENV_VARS.MIXPANEL_PROJECT_ID,
};

export default {
  cookies,
  runtime,
  mapConfig: {
    ...mapConfig,
    // @vince bug perhaps?
    STYLE_URL: `https://api.mapbox.com/styles/v1/${mapConfig.MAPBOX_USERID}/${mapConfig.MAPBOX_STYLEID}/tiles/256/{z}/{x}/{y}@2x?access_token=${mapConfig.MAPBOX_ACCESS_TOKEN}`,
    // STYLE_URL: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${mapConfig.MAPBOX_ACCESS_TOKEN}`,
    NEXT_PUBLIC_NEW_EMBED_URL: ENV_VARS.NEXT_PUBLIC_NEW_EMBED_URL,
    NEXT_PUBLIC_CLIENT_URL: ENV_VARS.NEXT_PUBLIC_CLIENT_URL,
  },
  firebase: {
    firebaseAdminInitConfig,
    firebaseClientInitConfig,
  },
  sentry: {
    dsn: ENV_VARS.SENTRY_DSN,
    disabled: ENV_VARS.DISABLE_SENTRY === 'true',
  },
  keys: {
    googleMapsAPI: ENV_VARS.GOOGLE_MAPS_API_KEY,
    foursquare: ENV_VARS.NEXT_PUBLIC_FOURSQUARE_API_KEY,
  },
  wanderDebugger,
  mixpanel,
  bigquery: {
    table:
      ENV_VARS.BIG_QUERY_TABLE ||
      'strange-reducer-305822.mixpanel_dev.mp__mp_master_event',
    region: ENV_VARS.BIG_QUERY_REGION || 'US',
    projectId: ENV_VARS.BIG_QUERY_PROJECT_ID || 'strange-reducer-305822',
  },
};
