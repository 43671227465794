import { useQuery } from 'react-query';
import { getMapVersion, getMapVersions } from '../util/api';
import { useStore } from './';

export const useMapVersion = (versionId) => {
  const store = useStore();
  const mapId = store.embed.map?.id;
  const mapVersionKey = ['mapVersion', mapId, versionId];

  return useQuery(mapVersionKey, () => getMapVersion({ mapId, versionId }), {
    enabled: !!versionId,
  });
};

export const useMapVersions = () => {
  const store = useStore();
  const mapId = store.embed.map?.id;
  const mapVersionsKey = ['mapVersions', mapId];

  return useQuery(mapVersionsKey, () => getMapVersions(mapId));
};
