import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import env from '../../env';
import { getAuthToken } from './clients';
import { store } from '../../hooks';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getAuthToken();
  if (!token) return config;
  return {
    ...config,
    headers: {
      ...config.headers,
      // Todo @vince mark has "Bearer ..." in here.. this needs to be removed,
      authorization: `${token}`,
    },
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.data?.message === 'User is not a publisher') {
    if (env.runtime.isBrowser) {
      await store.user.logout();
      await store.user.setLoginError(
        'Your account does not have publisher access.',
      );
    }
  }
  // @vince need to do debug logging here!
  if (env.runtime.isProduction || !env.runtime.isProduction) {
    console.error(error);
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
// TODO fix
export default setupInterceptorsTo(
  axios.create({
    baseURL: `${env.runtime.apiUrl}/api`,
  }),
);

export const nextAPIClient = setupInterceptorsTo(
  axios.create({
    baseURL: `/api`,
  }),
);

export const wrap = (prom: Promise<AxiosResponse>) =>
  prom.then(({ data }) => data);
