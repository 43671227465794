import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import * as _ from 'lodash/fp';

export enum Action {
  Manage = 'manage',
  Get = 'get',
  Patch = 'patch',
  Post = 'post',
  Delete = 'delete',
  Approve = 'approve',
  Reject = 'reject',
  Archive = 'archive',
}

export enum SubjectsEnum {
  'admin',
  'analytics',
  'admin/publisher',
  'maps',
  'maps/comments',
  'maps/changelog',
  'maps/publish',
  'maps/edits',
  'maps/categories',
  'maps/features',
  'maps/buildings',
  'maps/floors',
  'maps/lists',
  'styles/map',
  'user',
  'publisher',
  'publisher/assign_permissions',
  'publisher/user',
  'teams',
  'teams/user',
  'teams/map',
  'tags',
  'all',
}

export type Subjects = keyof typeof SubjectsEnum;

export enum OrganizationRolesEnum {
  'organization_account_admin',
  'organization_account_viewer',
}

export enum AccountRolesEnum {
  'super_admin',
  'wander_account_manager',
  'wander_map_builder',
}

export enum MapRolesEnum {
  'organization_map_admin',
  'organization_editor',
  'organization_reviewer',
  'organization_viewer',
}

export type AppAbility = Ability<[Action, Subjects]>;

export const anyPaths =
  (pathPrefix: string) =>
  (obj: Record<string, unknown>) =>
  (...paths: Array<string>) =>
    _.some((path: string) => !!_.get(path, _.get(`${pathPrefix}`, obj)), paths);

export const defineAbility = (
  user: {
    roles: any;
  },
  data_guard_info: any,
) => {
  const {
    can: caslCan,
    cannot: caslCannot,
    build,
  } = new AbilityBuilder(Ability as AbilityClass<AppAbility>);
  const can = (action: Action, subject: Subjects) => caslCan(action, subject);
  const cannot = (action: Action, subject: Subjects) =>
    caslCannot(action, subject);

  if (user) {
    const userRole = anyPaths('roles')(user);
    const mapRole = anyPaths(
      `roles.map_roles.${_.get('map_id', data_guard_info) || '.'}`,
    )(user);

    // SUPER ADMIN
    if (userRole('super_admin')) {
      can(Action.Manage, 'all');
    }
    // WANDER ROLES
    if (userRole('wander_account_manager', 'wander_map_builder')) {
      can(Action.Get, 'admin/publisher');
      can(Action.Get, 'analytics');
      can(Action.Get, 'maps'); // view specific maps
      can(Action.Get, 'maps/changelog'); // view changelog/audit trail
      can(Action.Get, 'publisher');
      can(Action.Manage, 'maps/buildings');
      can(Action.Manage, 'maps/categories');
      can(Action.Manage, 'maps/edits'); // make, approve, or reject edits for specific maps
      can(Action.Manage, 'maps/features');
      can(Action.Manage, 'maps/floors');
      can(Action.Manage, 'styles/map');
      can(Action.Patch, 'admin/publisher');
      can(Action.Patch, 'maps');
      can(Action.Patch, 'maps/lists');
      can(Action.Delete, 'maps/lists');
      can(Action.Post, 'maps'); // create a map
      can(Action.Post, 'maps/comments'); // comment/suggest edits on specific maps
    }
    if (userRole('wander_account_manager')) {
      can(Action.Delete, 'publisher'); // delete an organization
      can(Action.Manage, 'publisher/assign_permissions');
      can(Action.Manage, 'publisher/user'); //add/remove people from organization
      can(Action.Manage, 'tags');
      can(Action.Patch, 'publisher'); // update an organization
      can(Action.Post, 'publisher'); // create a new organization
      can(Action.Post, 'teams'); // create a team
      can(Action.Post, 'teams/map'); // add a team to a map
    }

    // CUSTOMER ROLES
    if (userRole('organization_account_admin')) {
      can(Action.Manage, 'publisher/user'); //add/remove people from organization
      can(Action.Manage, 'teams/user'); // add/remove someone to/from a team
      can(Action.Post, 'maps/comments'); // comment/suggest edits on specific maps
      can(Action.Post, 'teams'); // create a team
      can(Action.Post, 'teams/map'); // add a team to a map
      can(Action.Delete, 'maps/lists');
      if (_.get('plan.builder', data_guard_info)) {
        can(Action.Delete, 'maps'); // delete a map
        can(Action.Patch, 'maps/publish'); // publish a map for public consumption
      }
    }
    if (
      userRole('organization_account_admin', 'organization_account_viewer') ||
      mapRole(
        'organization_viewer',
        'organization_reviewer',
        'organization_editor',
        'organization_map_admin',
      )
    ) {
      can(Action.Get, 'maps'); // view specific maps
      can(Action.Get, 'publisher');
      if (_.get('plan.analytics', data_guard_info)) {
        can(Action.Get, 'analytics');
      }
    }
    if (
      userRole('organization_account_admin') ||
      mapRole('organization_editor', 'organization_map_admin')
    ) {
      can(Action.Get, 'maps/changelog'); // view changelog/audit trail
      if (_.get('plan.builder', data_guard_info)) {
        can(Action.Manage, 'maps/buildings');
        can(Action.Manage, 'maps/categories');
        can(Action.Manage, 'maps/edits'); // make, approve, or reject edits for specific maps
        can(Action.Manage, 'maps/features');
        can(Action.Manage, 'maps/floors');
        can(Action.Manage, 'styles/map');
        can(Action.Patch, 'maps');
        can(Action.Patch, 'maps/lists');
        can(Action.Delete, 'maps/lists');
      }
    }
    if (
      userRole('organization_account_admin') ||
      mapRole('organization_map_admin')
    ) {
      if (_.get('plan.builder', data_guard_info)) {
        can(Action.Archive, 'maps'); // archive a map
        can(Action.Post, 'maps'); // create a map
      }
    }
    if (
      userRole('organization_account_admin') ||
      mapRole(
        'organization_reviewer',
        'organization_editor',
        'organization_map_admin',
      )
    ) {
      can(Action.Manage, 'publisher/assign_permissions');
    }
  }

  return build();
};
