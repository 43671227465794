export enum IconSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum MarkerIconType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export const pathTypes = ['LineString', 'MultiLineString'];

export const polygonTypes = ['Polygon', 'MultiPolygon'];

export type PathGeoJsonType = (typeof pathTypes)[number];

const phoneRegExpOne =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExpTwo =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
export const phoneRegExp = new RegExp(
  `(${phoneRegExpOne.source})|(${phoneRegExpTwo.source})`,
);

export const websiteRegExp =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const alphaNumericSpaceRegExp = /^[a-zA-Z0-9 ]*$/;

export interface IOpen {
  day: number;
  time: string;
  hours?: number;
  minutes?: number;
  nextDate?: number;
}

export interface IClose {
  day: number;
  time: string;
  hours?: number;
  minutes?: number;
  nextDate?: number;
}

export interface IHoursPeriod {
  open: IOpen;
  close: IClose;
}

export interface IHours {
  open_now: boolean;
  periods: IHoursPeriod[];
  weekday_text: string[];
}

export enum PolygonFillTranslateAnchor {
  MAP = 'map',
  VIEWPORT = 'viewport',
}

export enum TextAnchor {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}
