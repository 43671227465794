import _ from 'lodash/fp';
import client, { wrap } from '../client';

export const createCategory = (data) => {
  const { map_id, types, ...payload } = data;
  return wrap(client.post(`/maps/${map_id}/categories`, { category: payload }));
};

export const updateCategory = (data) => {
  const { map_id, id, ...payload } = data;
  _.each(
    (key) => {
      payload[key] = Number.parseFloat(payload[key]);
    },
    [
      'marker_icon_halo_blur',
      'marker_icon_halo_width',
      'marker_label_radial_offset',
      'marker_label_text_halo_blur',
      'marker_label_text_halo_width',
      'marker_label_text_letter_spacing',
      'marker_label_text_padding',
      'marker_label_text_size',
      'marker_transition_zoom',
      'path_label_symbol_spacing',
      'path_label_text_halo_blur',
      // path_label_text_halo_width is deprected, should use path_label_halo_width instead,
      'path_label_text_halo_width',
      'path_label_halo_width',
      'path_label_text_letter_spacing',
      'path_label_text_padding',
      'path_label_text_size',
      'path_line_blur',
      'path_line_gap_width',
      'path_line_miter_limit',
      'path_line_offset',
      'path_line_opacity',
      'path_line_round_limit',
      'path_line_width',
    ],
  );
  return wrap(
    client.patch(`/maps/${map_id}/categories/${id}`, {
      category: _.omit(
        // 'category.property {BELOW} should not exist' @MARK fix please then remove the below
        [
          'path_center_gap_width',
          'path_segment_dash_width',
          'sort_order',
          'marker_label_halo_width',
        ],
        payload,
      ),
    }),
  );
};

export const deleteCategory = (data) =>
  wrap(client.delete(`/maps/${data.map_id}/categories/${data.id}`));

export const sortCategories = ({ mapId, data }) =>
  wrap(client.put(`/maps/${mapId}/categories/sort`, data));

export const bulkFeatureRelations = ({ mapId, data }) =>
  wrap(client.patch(`/maps/${mapId}/categories/bulk/relations`, data));
