import { useToast, UseToastOptions } from '@chakra-ui/react';
import ms from 'ms';

export const useErrorToast = () => {
  const toast = useToast();
  return (msg, options: UseToastOptions = {}) => {
    if (!options.id || !toast.isActive(options.id)) {
      toast({
        title: msg,
        position: 'top-right',
        status: 'error',
        isClosable: true,
        duration: ms('3.0s'),
        ...options,
      });
    }
  };
};

export const useSuccessToast = () => {
  const toast = useToast();
  return (msg, options: UseToastOptions = {}) => {
    if (!options.id || !toast.isActive(options.id)) {
      toast({
        title: msg,
        position: 'top-right',
        status: 'success',
        isClosable: true,
        duration: ms('3.0s'),
        ...options,
      });
    }
  };
};
