import mapboxgl from 'mapbox-gl';
import { bearing, point, booleanWithin, polygon } from '@turf/turf';

const is3dArr = (arr) => {
  if (typeof arr[0][0] !== 'number') {
    return true;
  } else {
    return false;
  }
};

// Converts from degrees to radians.
const toRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

// Converts from radians to degrees.
const toDegrees = (radians) => {
  return (radians * 180) / Math.PI;
};

const getECEF = (lat, lon, alt) => {
  // debugger
  const radius = 6378137;
  const polarRadius = 6356752.312106893;

  const asqr = radius * radius;
  const bsqr = polarRadius * polarRadius;
  const e = Math.sqrt((asqr - bsqr) / asqr);
  // let eprime = Math.sqrt((asqr-bsqr)/bsqr);

  const N = getN(radius, e, lat);
  const ratio = bsqr / asqr;

  const X = (N + alt) * Math.cos(lat) * Math.cos(lon);
  const Y = (N + alt) * Math.cos(lat) * Math.sin(lon);
  const Z = (ratio * N + alt) * Math.sin(lat);

  return [X, Y, Z];
};

const getDeltaECEF = (from, to) => {
  const X = to[0] - from[0];
  const Y = to[1] - from[1];
  const Z = to[2] - from[2];

  return [X, Y, Z];
};

const getN = (a, e, latitude) => {
  const sinlatitude = Math.sin(latitude);
  const denom = Math.sqrt(1 - e * e * sinlatitude * sinlatitude);
  return a / denom;
};

const getVerticalBearing = (
  fLat,
  fLon,
  fAlt,
  tLat,
  tLon,
  tAlt,
  currentElevation = 0,
) => {
  const fromLat = toRadians(fLat);
  const fromLon = toRadians(fLon);
  const toLat = toRadians(tLat);
  const toLon = toRadians(tLon);

  const fromECEF = getECEF(fromLat, fromLon, fAlt);
  const toECEF = getECEF(toLat, toLon, tAlt);
  const deltaECEF = getDeltaECEF(fromECEF, toECEF);

  const d =
    fromECEF[0] * deltaECEF[0] +
    fromECEF[1] * deltaECEF[1] +
    fromECEF[2] * deltaECEF[2];
  const a =
    fromECEF[0] * fromECEF[0] +
    fromECEF[1] * fromECEF[1] +
    fromECEF[2] * fromECEF[2];
  const b =
    deltaECEF[0] * deltaECEF[0] +
    deltaECEF[2] * deltaECEF[2] +
    deltaECEF[2] * deltaECEF[2];
  let elevation = toDegrees(Math.acos(d / Math.sqrt(a * b)));
  elevation = 180 - elevation;
  return elevation;

  // return elevation - currentElevation;
};

const calculatePathViewport = (coordinates, map) => {
  const result = is3dArr(coordinates)
    ? coordinates.reduce((accc, currr) => [...accc, ...currr], [])
    : coordinates;
  const pathCoorsWithElevation = result.map((coord, i) => {
    const elevation = Math.floor(
      // Do not use terrain exaggeration to get actual meter values
      map.queryTerrainElevation(coord, {
        exaggerated: false,
      }),
    );
    return [...coord, elevation];
  });
  const highEndPoint =
    pathCoorsWithElevation[0][2] >=
    pathCoorsWithElevation[pathCoorsWithElevation.length - 1][2]
      ? pathCoorsWithElevation[0]
      : pathCoorsWithElevation[coordinates.length - 1];
  const lowEndPoint =
    pathCoorsWithElevation[0][2] >=
    pathCoorsWithElevation[pathCoorsWithElevation.length - 1][2]
      ? pathCoorsWithElevation[coordinates.length - 1]
      : pathCoorsWithElevation[0];
  const newBearing = bearing(point(lowEndPoint), point(highEndPoint));
  const verticalBearing = getVerticalBearing(
    highEndPoint[1],
    highEndPoint[0],
    highEndPoint[2],
    lowEndPoint[1],
    lowEndPoint[0],
    lowEndPoint[2],
  );
  const pathCoords = result;
  const bounds = pathCoords.reduce(function (bounds, coord) {
    return bounds.extend([coord[0], coord[1]]);
  }, new mapboxgl.LngLatBounds(pathCoords[0], pathCoords[1]));
  return { bounds, newBearing, verticalBearing };
};

const pointWithinBounds = (coordinates, point) => {
  const polygonn = polygon([coordinates]);
  return booleanWithin(point, polygonn);
};

const calculateNewMapBounds = (
  rect,
  windowWidth,
  windowHeight,
  map,
  isMobile = false,
) => {
  if (rect.top === 0) {
    // edge case where on the first marker click, the rectangle dimensions are not set yet
    return { needsCorrection: false };
  }
  let needsCorrection = false;
  const mapCenter = map.getCenter();
  const { x, y } = map.project(mapCenter);
  let centerXYCoords = [x, y];
  const rightThreshold = windowWidth;
  const leftThreshold = isMobile ? 40 : 450 + 40; // sidebar + marker width
  const topThreshold = isMobile ? 90 : 50;
  const bottomThreshold = isMobile ? windowHeight - 120 : 50;
  let rightAdjustment = 0;
  let leftAdjustment = 0;
  let bottomAdjustment = 0;
  let topAdjustment = 0;
  const overhangsRight = rect.right > rightThreshold;
  const overhangsTop = rect.top < topThreshold;
  const overhangsLeft = rect.left < leftThreshold;
  const overhangsBottom = rect.bottom > bottomThreshold;
  if (overhangsRight) {
    rightAdjustment = rect.right - rightThreshold;
    centerXYCoords = [centerXYCoords[0] + rightAdjustment, centerXYCoords[1]];
    needsCorrection = true;
  } else if (overhangsLeft) {
    leftAdjustment = leftThreshold - rect.left;
    centerXYCoords = [centerXYCoords[0] - leftAdjustment, centerXYCoords[1]];
    needsCorrection = true;
  }
  if (overhangsTop) {
    topAdjustment = topThreshold - rect.top;
    centerXYCoords = [centerXYCoords[0], centerXYCoords[1] - topAdjustment];
    needsCorrection = true;
  } else if (overhangsBottom) {
    bottomAdjustment = rect.bottom - bottomThreshold;
    centerXYCoords = [centerXYCoords[0], centerXYCoords[1] + bottomAdjustment];
    needsCorrection = true;
  }

  const newMapCenter = map.unproject(centerXYCoords);
  return { newMapCenter, needsCorrection };
};

export { calculatePathViewport, pointWithinBounds, calculateNewMapBounds };
