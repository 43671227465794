import { Action, defineAbility, Subjects } from '../shared/ability';
import { ForbiddenError } from '@casl/ability';

export const checkPermission = (
  permissions: Array<{ action: Action; subject: Subjects }>,
  store: any,
) => {
  const ability = defineAbility(store.user, {});
  try {
    permissions.forEach((rule) => {
      ForbiddenError.from(ability).throwUnlessCan(rule.action, rule.subject);
    });
    return true;
  } catch (err) {
    return false;
  }
};
