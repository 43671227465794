import { QueryClient } from 'react-query';

const defaultQueryFn = ({ queryKey }) => queryKey.join('/');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});
