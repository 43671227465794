import { useRouter } from 'next/router';
import { useStore } from './store.hooks';
import { useDebounceEffect } from 'ahooks';
import * as _ from 'lodash/fp';
import { useEffect, useMemo } from 'react';
import { useMapId } from './map.hooks';
import { PINNED_PLACES_LIST_NAME } from '../util/constants';

const truncateDecimal = (num, places = 0) => {
  const factor = Math.pow(10, places);
  const result = Math.trunc(num * factor) / factor;
  return isNaN(result) ? null : result;
};

export const useCaptureEmbedUrlData = () => {
  const store = useStore();
  const router = useRouter();
  const mapId = useMapId();

  const list_id = useMemo(() => {
    if (store.tags.selecetedList === PINNED_PLACES_LIST_NAME) return;
    return _.get(
      `${mapId}.${store.tags.selecetedList}.id`,
      store.embed.bookMarkLists,
    );
  }, [store.tags.selecetedList, store.embed.bookMarkLists, mapId]);

  useDebounceEffect(
    () => {
      if (
        !store.embed.ready ||
        !store.embed.urlDataLoaded ||
        router.pathname !== '/embed'
      )
        return;
      const queryValues = {
        zoom: truncateDecimal(store.embed.viewport.zoom, 2),
        lat: truncateDecimal(store.embed.viewport.latitude, 7),
        lng: truncateDecimal(store.embed.viewport.longitude, 7),
        bearing: truncateDecimal(store.embed.viewport.bearing),
        pitch: truncateDecimal(store.embed.viewport.pitch),
        filterItems: store.tags.filterItemIDsArray.length
          ? store.tags.filterItemIDsArray.join(',')
          : undefined,
        filterItem: store.tags.filterSearchQueryItem
          ? store.tags.filterSearchQueryItem.id
          : undefined,
        category: store.tags.selectedFilterItems.category?.id,
        selectedFeature: store.embed.selectedFeatureID,
        search:
          store.tags.filterSearchQuery === ''
            ? undefined
            : store.tags.filterSearchQuery,
        version_id: router.query.version_id || undefined,
        list_id,
      };
      router.push({
        pathname: router.pathname,
        query: {
          mapId: router.query.mapId,
          ..._.omitBy(_.isNil, queryValues),
        },
      });
    },
    [
      store.embed.ready,
      store.embed.viewport.zoom,
      store.embed.viewport.latitude,
      store.embed.viewport.longitude,
      store.embed.viewport.bearing,
      store.embed.viewport.pitch,
      store.tags.filterSearchQueryItem,
      store.tags.filterItemIDsArray,
      store.embed.selectedFeatureID,
      store.tags.filterSearchQuery,
      store.embed.urlDataLoaded,
      list_id,
    ],
    { wait: 50 },
  );
};

type EmbedURLQueryData = Partial<{
  zoom?: number;
  lat?: number;
  lng?: number;
  bearing?: number;
  pitch?: number;
  filterItem?: string;
  filterItems?: string[];
  selectedFeature?: string;
  search?: string;
  category?: string;
  list_id?: string;
}>;

type UseEmbedUrlKeys = Array<keyof EmbedURLQueryData>;

export const useEmbedUrlData = (keys: UseEmbedUrlKeys): EmbedURLQueryData => {
  const router = useRouter();

  return useMemo(() => {
    if (router.pathname !== '/embed') return {};

    const tidyQueryData = (query, keys) =>
      _.reduce(
        (acc, key: string) => {
          if (key === 'filterItems') {
            acc[key] = query[key]?.split(',').filter((v) => !!v);
          } else if (
            key === 'zoom' ||
            key === 'lat' ||
            key === 'lng' ||
            key === 'bearing' ||
            key === 'pitch'
          ) {
            acc[key] = Number(query[key]);
          } else if (
            key === 'filterItem' ||
            key === 'selectedFeature' ||
            key === 'category' ||
            key === 'search' ||
            key === 'list_id'
          ) {
            acc[key] = query[key];
          }
          return acc;
        },
        {},
        keys,
      );

    const removeInvalidValues = _.omitBy((v, k) => {
      if (v === undefined || v === null || v === '') {
        return true;
      }
      if (k === 'lat') {
        return isNaN(v as number) || v < -90 || v > 90;
      }
      if (k === 'lng') {
        return isNaN(v as number) || v < -180 || v > 180;
      }
      if (k === 'zoom') {
        return isNaN(v as number) || v < 0 || v > 22;
      }
      if (k === 'bearing' || k === 'pitch') {
        return isNaN(v as number);
      }
      if (Array.isArray(v) && v.length === 0) {
        return true;
      }
      return false;
    });

    const convertLatLngKeys = (data: EmbedURLQueryData) => {
      const { lat, lng, ...rest } = data;
      if (lat && lng) {
        return {
          ...rest,
          latitude: lat,
          longitude: lng,
        };
      }
      return rest;
    };

    const query = _.flow(
      tidyQueryData,
      removeInvalidValues,
      convertLatLngKeys,
    )(router.query, keys);

    return query;
  }, [router.query]);
};
