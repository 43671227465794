import { useQuery, useMutation } from 'react-query';
import {
  createCategory,
  updateCategory,
  deleteCategory,
  sortCategories,
  bulkFeatureRelations,
  bulkDeleteRelations,
  updateHours,
  bulkFeaturesGeoJson,
} from '../util/api';

export const useGetCategoriesByMapId = (id: string) =>
  useQuery(`/maps/${id}/categories`);

export const useCreateCategory = () => useMutation(createCategory);

export const useUpdateCategory = () => useMutation(updateCategory);

export const useDeleteCategory = () => useMutation(deleteCategory);

export const useSortCategories = () => useMutation(sortCategories);

export const useBulkFeatureRelations = () => useMutation(bulkFeatureRelations);

export const useUpdateHours = () => useMutation(updateHours);

export const useBulkDeleteRelations = () => useMutation(bulkDeleteRelations);

export const useBulkFeatureGeoJson = () => useMutation(bulkFeaturesGeoJson);
