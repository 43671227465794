import { makeAutoObservable, toJS } from 'mobx';
import { StoreMobx } from './store.mobx';
import { nanoid } from 'nanoid';
import _ from 'lodash/fp';
import { IHours as DBHours } from './ImapData';

const getDefaultHours = () => [
  {
    id: nanoid(),
    from: null,
    to: null,
  },
];
export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

type IHourRange = {
  id: string;
  from: string;
  to: string;
};

type IHour = {
  isClosed: boolean;
  hours: IHourRange[];
};

type IHours = {
  sunday: IHour;
  monday: IHour;
  tuesday: IHour;
  wednesday: IHour;
  thursday: IHour;
  friday: IHour;
  saturday: IHour;
};

type DayKey = keyof IHours;

const check = (day, obj) => _.get([day, 'isClosed'], obj) !== undefined;

export class MapHoursMobx {
  parent: StoreMobx;
  hours = {
    sunday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    monday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    tuesday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    wednesday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    thursday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    friday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
    saturday: {
      isClosed: false,
      hours: getDefaultHours(),
    },
  };

  constructor(parent: StoreMobx) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  convertHoursFromGoogle(hours: DBHours | IHours) {
    //check if hours are using old format
    if (_.size(_.intersection(DAYS_OF_WEEK, _.keys(hours)))) {
      return hours;
    }
    hours = hours as DBHours;
    if (_.isNull(hours) || _.size(hours.periods) === 0) return;

    const mappedDays = _.reduce(
      (days, period) => {
        const hours = {
          id: nanoid(),
          from: period.open.time
            .substring(0, 2)
            .concat(':')
            .concat(period.open.time.substring(2)),
          to: period.close.time
            .substring(0, 2)
            .concat(':')
            .concat(period.close.time.substring(2)),
        };
        if (_.has(DAYS_OF_WEEK[period.close.day], days)) {
          const day = days[DAYS_OF_WEEK[period.close.day]];
          days[DAYS_OF_WEEK[period.close.day]] = {
            ...day,
            hours: [...day.hours, hours],
          };
        } else {
          days[DAYS_OF_WEEK[period.close.day]] = {
            isClosed: false,
            hours: [hours],
          };
        }

        return days;
      },
      {},
      hours.periods,
    );
    const convertedHours = _.reduce(
      (days, day) => {
        if (_.has(day, mappedDays)) {
          days[day] = mappedDays[day];
        } else {
          days[day] = { isClosed: true, hours: getDefaultHours() };
        }
        return days;
      },
      {},
      DAYS_OF_WEEK,
    );
    this.setMapHours(convertedHours as IHours);
  }

  convertHoursToGoogle(hours: IHours) {
    const periods = _.flow(
      _.filter(([, { isClosed, hours }]) => !isClosed),
      _.reduce(function generatePeriods(
        periods,
        [day, { hours }]: [
          string,
          {
            isClosed: boolean;
            hours: IHourRange[];
          },
        ],
      ) {
        const dayNumber = _.indexOf(day, DAYS_OF_WEEK);
        const newPeriods = _.flow(
          _.filter(({ from, to }) => from && to),
          _.map(({ from, to }) => ({
            open: { day: dayNumber, time: _.replace(':', '', from) },
            close: { day: dayNumber, time: _.replace(':', '', to) },
          })),
          _.sortBy((period) => [
            period.open.day,
            _.parseInt(10, period.open.time),
          ]),
        )(hours);
        return [...periods, ...newPeriods];
      }, []),
      _.sortBy((period) => period.open.day),
    )(_.entries(hours));

    const weekday_text = _.flow(
      _.sortBy(([day]) => _.indexOf(day, DAYS_OF_WEEK)),
      _.map(([day, { isClosed, hours }]: [string, IHour]) => {
        const dayStr = _.capitalize(day);
        const hoursText = isClosed
          ? 'Closed'
          : _.flow(
              _.sortBy((hour: IHourRange) =>
                _.parseInt(10, _.replace(':', '', hour.from)),
              ),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              _.map.convert({ cap: false })(({ from, to }, index, hours) => {
                const convertTime = (time) => {
                  if (time < '12:00') {
                    return `${time} AM`;
                  } else {
                    const [hours, minutes] = _.split(':', time);
                    return `${
                      hours === '12' ? '12' : _.parseInt(10, hours) - 12
                    }:${minutes} PM`;
                  }
                };
                const fromStr = convertTime(from);
                const toStr = convertTime(to);
                const hoursStr = _.isEqual(fromStr, toStr)
                  ? '24 Hours'
                  : `${fromStr} - ${toStr}`;
                const arrSize = _.size(hours);
                const suffix =
                  arrSize === 1 || index === arrSize - 1
                    ? ''
                    : arrSize === 2
                    ? ' and '
                    : index === arrSize - 2
                    ? ', and '
                    : ', ';
                return `${hoursStr}${suffix}`;
              }),
              _.join(''),
            )(_.filter(({ from, to }) => from && to, hours));
        return hoursText ? `${dayStr}: ${hoursText}` : '';
      }),
      _.filter((text) => !!text),
    )(_.entries(hours));
    return {
      open_now: false,
      periods,
      weekday_text,
    };
  }

  setMapHours = (hours: IHours) => {
    if (!hours) return;
    const failed = _.flow(
      _.map((x) => check(x, hours)),
      _.any((x) => x !== true),
    )(DAYS_OF_WEEK);
    if (!failed) {
      this.hours = hours;
    }
  };

  addHours = (day: DayKey) => {
    this.hours[day].hours.push({
      id: nanoid(),
      from: null,
      to: null,
    });
  };

  removeHours = (day: DayKey, id: string) => {
    this.hours[day].hours = _.filter(
      (hours) => hours.id !== id,
      this.hours[day].hours,
    );
  };

  toggleClosed(day: DayKey) {
    this.hours[day].isClosed = !this.hours[day].isClosed;
  }

  changeHours(
    day: DayKey,
    id: string,
    key: 'from' | 'to',
    value: string | null,
  ) {
    _.each((hours) => {
      if (hours.id !== id) return;
      hours[key] = value;
    }, this.hours[day].hours);
  }

  setAllToMatch(day: DayKey) {
    const matching = this.hours[day];
    _.each((dayOfWeek) => {
      if (dayOfWeek !== day) {
        this.hours[dayOfWeek].isClosed = matching.isClosed;
        this.hours[dayOfWeek].hours = _.map(
          (hour) =>
            _.cloneDeep({
              ...toJS({ ...hour }),
              id: nanoid(),
            }),
          matching.hours,
        );
      }
    }, DAYS_OF_WEEK);
  }

  get toJSON() {
    return toJS(this.hours);
  }
}
