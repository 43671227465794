export enum PoiTypeEnum {
  'marker',
  'path',
  'polygon',
}

export interface IPutRelateItemsDto {
  poi_id: string;
  type: PoiTypeEnum;
  item_ids: Array<string>;
  random_id: string;
}
