import { useRouter } from 'next/router';
import { useMutation, useQuery } from 'react-query';
import {
  saveMapFilters,
  deleteMapFilters,
  fetchMapById,
  fetchMapFilters,
  updateInfo,
} from '../util/api';

type UUID = string;

export const useGetMapById = (id: UUID) =>
  useQuery(`/maps/${id}`, () => fetchMapById(id));

export const useMapId = () => useRouter()?.query?.mapId as string;

export const useUpdateInfo = () => useMutation(updateInfo);

export const useFetchMapFilters = (mapId: UUID) =>
  useQuery(`/map/${mapId}/map-filters`, () => fetchMapFilters({ mapId }));
export const useSaveMapFilters = () => useMutation(saveMapFilters);
export const useDeleteMapFilters = () => useMutation(deleteMapFilters);
