import { StoreMobx } from './store.mobx';
import { makeAutoObservable, toJS } from 'mobx';
import { object, string, InferType } from 'yup';
import env from '../env';

const queryParamsMobxSchema = object({
  category: string()
    .nullable(true)
    .default(() => null),
});
export type QueryParamsMobxType = InferType<typeof queryParamsMobxSchema>;

const defaultParams = queryParamsMobxSchema.cast({});
export class QueryParamsMobx {
  parent: StoreMobx;
  selected: boolean;
  pathname: string;
  params = { ...defaultParams };

  constructor(parent: StoreMobx) {
    this.parent = parent;
    if (env.runtime.isBrowser) {
      const urlParams = new URLSearchParams(window.location.search);
      const query = Object.fromEntries(urlParams);
      this.replace({
        pathname: window.location.pathname,
        query,
      });
    }
    makeAutoObservable(this);
  }

  get root(): StoreMobx {
    return this.parent;
  }

  replace = (nextParams: {
    pathname: string;
    query: Partial<QueryParamsMobxType>;
  }) => {
    const { pathname, query } = nextParams;
    this.pathname = pathname;
    const params = { ...defaultParams, ...query };
    this.params = queryParamsMobxSchema.cast(params);
  };

  jsonParams = () => toJS(this.params);
}
