import { bboxPolygon, difference, polygon } from '@turf/turf';

const defaultBounds = [
  242.12641976128907, 87.8609516564726, -295.41067193245925, -87.86095165647257,
];
const polymask = (mask, bounds) => difference(bboxPolygon(bounds), mask);

const maskGeoJson = (coordinates, bounds = defaultBounds) =>
  polymask(polygon(coordinates), bounds);

export default maskGeoJson;
