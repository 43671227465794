import { IMapData, IStyles } from '../../../store/ImapData';
import {
  mapGeoJSON,
  mapMarkersGeoJSON,
  mapPathsGeoJSON,
  mapPolygonsGeoJSON,
} from '../../mapGeoJSON';
import client from '../client';
import _ from 'lodash/fp';
import { extractData } from '../../extract-data';
import { IGetMapByIdQueryDto } from '@wander-app/shared';

type BooleanExcept<T> = {
  [K in keyof T]: K extends 'version_id' ? T[K] : boolean;
};

export type GetMapByIdQueryDtoBoolean = Partial<
  BooleanExcept<IGetMapByIdQueryDto>
>;
export const fetchMapById = (
  id: string,
  options: GetMapByIdQueryDtoBoolean = {},
) => {
  const defaultParams = {
    info: true,
    map_data: true,
    publisher: true,
    all_categories: true,
    contact: true,
    contents: true,
  };
  return client
    .get(`/maps/${id}`, {
      params: {
        ...defaultParams,
        ...options,
      },
    })
    .then(extractData)
    .then(mapGeoJSON);
};

export const fetchMapMarkersById = (
  id: string,
  options: { version_id?: string } = {},
) =>
  client
    .get(`/maps/${id}`, {
      params: {
        markers: true,
        ...options,
      },
    })
    .then(extractData)
    .then(mapMarkersGeoJSON);

export const fetchMapPathsById = (
  id: string,
  options: { version_id?: string } = {},
) =>
  client
    .get(`/maps/${id}`, {
      params: {
        paths: true,
        ...options,
      },
    })
    .then(extractData)
    .then(mapPathsGeoJSON);

export const fetchMapPolygonsById = (
  id: string,
  options: { version_id?: string } = {},
) =>
  client
    .get(`/maps/${id}`, {
      params: {
        polygons: true,
        ...options,
      },
    })
    .then(extractData)
    .then(mapPolygonsGeoJSON);

export const fetchMaps = (): Promise<IMapData['map'][]> =>
  client.get(`/publisher/maps`).then(extractData);

export const fetchCategoryIcons = (): Promise<IMapData['map'][]> =>
  client.get(`/places-of-interest/icons`).then(extractData);

export const bulkDeleteRelations = ({
  mapId,
  data,
}): Promise<IMapData['map'][]> =>
  client.delete(`/maps/${mapId}/bulk/features`, { data }).then(extractData);

export const updateHours = ({ mapId, data }): Promise<IMapData['map'][]> =>
  client.patch(`/maps/${mapId}`, data).then(extractData);

export const updateInfo = (data) => {
  const {
    mapId,
    contact,
    updated_at,
    publisher,
    area_coordinatesz,
    styles,
    buildings,
    paths,
    polygons,
    contents,
    ...map
  } = data;

  const mapAreaCoordinates = _.set(
    'area_coordinates',
    map.area_coordinates.coordinates,
    map,
  );
  const mapPoint = _.set('point', map.point.coordinates, mapAreaCoordinates);

  return client
    .patch(`/maps/${mapId}`, {
      map: mapPoint,
      contact: _.omit(['id'], contact),
      contents,
    })
    .then(extractData) as Promise<IMapData['map'][]>;
};

export const fetchDefaultStyles = (): Promise<IStyles[]> =>
  client.get('/styles/map').then(extractData);

export const fetchPubisherStyles = (): Promise<IStyles[]> =>
  client.get('/publisher/styles/map').then(extractData);

export const getMapIdsToNames = (
  mapIds: Array<string>,
): Promise<Record<string, string>> =>
  client
    .get('/maps/names', { params: { ids: _.join(',', mapIds) } })
    .then(extractData);

export const fetchMapList = ({ mapId, listId }) =>
  client.get(`/maps/${mapId}/lists/${listId}`).then(extractData);

export const fetchMapFilters = ({ mapId }) =>
  client.get(`/map/${mapId}/map-filters`).then(extractData);

export const saveMapFilters = async ({ mapId, formData }) => {
  const { id, ...body } = formData;
  if (id) {
    await client.put(`/map/${mapId}/map-filters/${id}`, body).then(extractData);
  } else {
    await client.post(`/map/${mapId}/map-filters`, body).then(extractData);
  }
};

export const deleteMapFilters = async ({ mapId, mapFilterId }) => {
  await client
    .delete(`/map/${mapId}/map-filters/${mapFilterId}`)
    .then(extractData);
};
