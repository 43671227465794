import client, { wrap } from '../client';
import { auth } from '../../firebase';
import axios, { AxiosResponse } from 'axios';
import {
  IGetCustomInviteTokenQueryDto,
  ILoginDto,
  IRegisterDto,
} from '@wander-app/shared-web';
import env from '../../../env';
import { logger } from '../../logger';
import { extractData } from '../../extract-data';
import { Subject } from 'rxjs';

export const login = (data: ILoginDto) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  wrap<{
    token: string;
    forceRefresh: boolean;
    options: Record<string, string>;
  }>(client.post(`/auth/login?permissions=true`, data));

export const logout = () => client.post(`/auth/logout`);

export const sendResetPasswordEmail = (email: string) =>
  client.post(`/auth/send-reset-password-email`, { email });

const loginWithNewIdToken = async (
  count = 0,
): Promise<AxiosResponse> | null => {
  if (count === 5) return null;
  const idToken = await auth.currentUser.getIdToken(true);
  const result = await axios.post('/api/login', { idToken });

  if (!result.data.forceRefresh) {
    return result;
  }
  return await new Promise((res) => {
    setTimeout(() => {
      loginWithNewIdToken(++count).then((result) => res(result));
    }, 100);
  });
};

export const refreshCookieInProgress$ = new Subject();
export const refreshCookieComplete$ = new Subject();

export const refreshCookie = async () => {
  try {
    refreshCookieInProgress$.next('REFRESH COOKIE IN PROGRESS');
    const { data } = await loginWithNewIdToken();
    setAuthToken(data.token);
    setLocalPermissions(JSON.stringify(data.permissions));
    logger('refreshing token done');
  } finally {
    refreshCookieComplete$.next('REFRESH COOKIE COMPLETED');
  }
};

const AUTH_KEY = 'wander-auth';
const PERMISSIONS_KEY = 'wander-permissions';

export const setAuthToken = (value) => {
  logger('setting auth token');
  if (!value) return;
  if (env.runtime.isServer) return;
  logger('auth token set');
  window.localStorage.setItem(AUTH_KEY, value);
};

export const getAuthToken = () => {
  if (env.runtime.isServer) return '';
  try {
    return window.localStorage.getItem(AUTH_KEY);
  } catch (e) {
    return '';
  }
};

export const setLocalPermissions = (permissionsStr: string) => {
  if (!permissionsStr) return;
  if (env.runtime.isServer) return;
  window.localStorage.setItem(PERMISSIONS_KEY, permissionsStr);
};

export const getLocalPermissions = () => {
  if (env.runtime.isServer) {
    return null;
  }
  const permissionsStr = window.localStorage.getItem(PERMISSIONS_KEY);
  return permissionsStr ? JSON.parse(permissionsStr) : { map_roles: {} };
};

export const getCustomInviteToken = (code: string) => {
  const params: IGetCustomInviteTokenQueryDto = { code };
  return client
    .get('/auth/get-custom-invite-token', { params })
    .then(extractData);
};

export const registerEmailPassword = (data: IRegisterDto) =>
  client.post('/auth/register', data);
