import {
  object,
  boolean,
  number,
  mixed,
  array,
  string,
  AnyObjectSchema,
} from 'yup';
import { IconSize, MarkerIconType } from '../../helpers';

export const advancedCategoriesSchema: AnyObjectSchema = object({
  cluster_min_points: number().integer().nullable(false),
  default_selected: boolean().nullable(false),
  cluster_radius: number().integer().nullable(false),
  cluster_max_zoom: number().integer().nullable(false),
  path_markers_visible: boolean().nullable(false),
  marker_icon_halo_width: number().nullable(false),
  marker_transition_zoom: number().integer().nullable(false),
  icon_size: mixed<IconSize>().oneOf(Object.values(IconSize)).nullable(false),
  marker_icon_halo_blur: number().nullable(false),
  // marker_icon_only: boolean().nullable(true),
  // marker_minzoom: number().nullable(true),
  // marker_maxzoom: number().nullable(true),
  marker_label_text_size: number().nullable(false),
  marker_label_text_halo_width: number().nullable(false),
  marker_label_text_letter_spacing: number()
    .typeError('Label letter spacing must be a number.')
    .nullable(false),
  marker_label_text_font: array().nullable(false),
  marker_label_text_halo_blur: number().nullable(false),
  marker_label_text_max_width: number().nullable(false),
  marker_label_text_color: string().nullable(true),
  marker_label_text_halo_color: string().nullable(true),
  marker_label_text_padding: number().nullable(false),
  marker_label_text_offset: array().nullable(false),
  path_line_width: number().nullable(false),
  // path_line_halo_width: number().integer().nullable(true),
  path_line_cap: string().nullable(false),
  path_line_blur: number().nullable(false),
  path_line_color: string().nullable(true),
  // path_line_opacity: number().nullable(true),
  // path_line_halo_color: string().nullable(true),
  // path_line_gap_width: number().nullable(false),
  // path_minzoom: number().integer().nullable(true),
  // path_maxzoom: number().integer().nullable(true),
  path_label_text_size: number().nullable(false),
  path_label_halo_width: number().nullable(false),
  path_label_text_anchor: string().nullable(false),
  path_label_text_font: array().nullable(false),
  path_label_text_halo_blur: number().nullable(true),
  path_label_text_max_width: number().integer().nullable(false),
  path_label_text_padding: number().nullable(false),
  path_label_text_offset: array().nullable(false),
  path_label_text_letter_spacing: number().nullable(true),
  path_label_color: string().nullable(true),
  path_label_halo_color: string().nullable(true),
  path_label_text_symbol_spacing: number().integer().nullable(true),
  // circle_icon_size: number().nullable(true),
  // circle_icon_stroke_width: number().nullable(true),
  marker_icon_color: string().nullable(true),
  marker_icon_halo_color: string().nullable(true),
  // marker_icon_icon_allow_overlap: boolean().nullable(false),
  // marker_icon_text_allow_overlap: boolean().nullable(false),
  // marker_icon_text_ignore_placement: boolean().nullable(false),
  // marker_icon_type: mixed<MarkerIconType>()
  //   .oneOf(Object.values(MarkerIconType))
  //   .nullable(false),
  // marker_label_icon_ignore_placement: boolean().nullable(false),
  // marker_label_radial_offset: number().nullable(false),
  // marker_label_symbol_placement: string().nullable(false),
  // marker_label_symbol_z_order: string().nullable(false),
  // marker_label_text_allow_overlap: boolean().nullable(false),
  marker_label_text_ignore_placement: boolean().nullable(false),
  // marker_label_text_justify: string().nullable(false),
  // path_label_icon_allow_overlap: boolean().nullable(false),
  // path_label_maxzoom: number().integer().nullable(true),
  // path_label_minzoom: number().integer().nullable(true),
  // path_label_text_halo_color: string().nullable(true),
  // <---- path_label_text_halo_width is deprected, should use path_label_halo_width instead ---> //
  // path_label_text_halo_width: number().nullable(false),
  // path_label_text_ignore_placement: boolean().nullable(false),
  // marker_icon_icon_ignore_placement: boolean().nullable(false),
  // marker_label_icon_allow_overlap: boolean().nullable(false),
  name: string().nullable(false).required(),
  icon: string().nullable(false).required(),
  color: string().nullable(false).required(),
  poly_fill_antialias: boolean().nullable(false),
  poly_fill_color: string().nullable(true),
  poly_fill_opacity: number()
    .typeError('Label letter spacing must be a number.')
    .nullable(false),
  poly_fill_outline_color: string().nullable(false),
  poly_fill_translate: array().nullable(false),
  poly_fill_translate_anchor: mixed()
    .oneOf(['map', 'viewport'])
    .nullable(false),
  poly_label_text_anchor: mixed()
    .oneOf([
      'center',
      'left',
      'right',
      'top',
      'bottom',
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right',
    ])
    .nullable(false),
  poly_label_text_size: number().nullable(false),
  poly_label_text_letter_spacing: number().nullable(false),
  poly_label_color: string().nullable(true),
  poly_label_halo_color: string().nullable(true),
  poly_label_text_halo_width: number().nullable(false),
  poly_label_text_halo_blur: number().nullable(false),
  poly_markers_visible: boolean().nullable(false),
  icon_url: string().nullable(false),
});
