import * as _ from 'lodash/fp';
import { IMapData } from '../store/ImapData';

// https://dev.socrata.com/docs/datatypes/multipoint.html#
// .point = central middle location on the map
// .area_coordinates = points on a map that hold the markers
// .markers = each marker inside, should have properties
/*
    GeoJSON supports the following geometry types:
    Point, LineString, Polygon, MultiPoint, MultiLineString, and MultiPolygon.
    Geometric objects with additional properties are Feature objects.
    Sets of features are contained by FeatureCollection objects.

    {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "properties": {
                    "population": 200
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [-112.0372, 46.608058]
                }
            }
        ]
    }
*/

export const mapMarkersGeoJSON = _.flow(
  _.getOr([], 'markers'),
  _.map((marker) => ({
    type: 'Feature',
    geometry: JSON.parse(_.get('point', marker)),
    properties: _.omit(['point', 'pointz'], marker),
  })),
);

export const mapPathsGeoJSON = _.flow(
  _.getOr([], 'paths'),
  _.map((path) => ({
    type: 'Feature',
    geometry: JSON.parse(_.get('coordinates', path)),
    properties: _.omit(
      [
        'coordinates',
        'coordinatesz',
        'multi_line_string',
        'multi_line_stringz',
      ],
      path,
    ),
  })),
);

export const mapPolygonsGeoJSON = _.flow(
  _.getOr([], 'polygons'),
  _.map((polygon) => ({
    type: 'Feature',
    geometry: JSON.parse(_.get('coordinates', polygon)),
    properties: _.omit(['coordinates'], polygon),
  })),
);

export const transformParse = (x) => ({
  ...x,
  point: JSON.parse(x.point),
  area_coordinates: JSON.parse(x.area_coordinates),
  markers: _.map((y) => ({ ...y, point: JSON.parse(y.point) }), x.markers),
  paths: _.map(
    (y) => ({
      ...y,
      coordinates: JSON.parse(y.coordinates),
      multi_line_string: y.multi_line_string
        ? JSON.parse(y.multi_line_string)
        : null,
    }),
    x.paths,
  ),
});

// clean up the data
export const transformMapToJS = (x) => ({
  ...x,
  ...parseMarkersAndFeatures(x),
  point: x.point ? JSON.parse(x.point) : undefined,
  area_coordinates: x.area_coordinates
    ? JSON.parse(x.area_coordinates)
    : undefined,
});

const OMIT_PICK = ['markers', 'categories'];
export const mapGeoJSON = (obj): IMapData => {
  const parsedMap = transformMapToJS(obj);
  return {
    ..._.pick(OMIT_PICK, parsedMap),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    map: _.omit(OMIT_PICK, parsedMap),
    geoJSON: {
      type: 'FeatureCollection',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      features: mergeMarkersAndFeatures(parsedMap),
    },
  };
};

export const parseMarkersAndFeatures = (parsedMap) => ({
  markers: _.map(
    (f) => ({ ...f, point: JSON.parse(f.point) }),
    parsedMap.markers,
  ),
  paths: _.map(
    (f) => ({
      ...f,
      coordinates: JSON.parse(f.coordinates),
      multi_line_string: f.multi_line_string
        ? JSON.parse(f.multi_line_string)
        : null,
      coordinatesz: undefined,
      multi_line_stringz: undefined,
    }),
    parsedMap.paths,
  ),
  polygons: _.map(
    (f) => ({
      ...f,
      coordinates: JSON.parse(f.coordinates),
    }),
    parsedMap.polygons,
  ),
});

export const mergeMarkersAndFeatures = (parsedMap) => [
  //   normalize marker points
  ..._.flow(
    _.getOr([], 'markers'),
    _.map((marker) => ({
      type: 'Feature',
      geometry: _.get('point', marker),
      properties: marker,
    })),
  )(parsedMap),
  //   normalize paths
  ..._.flow(
    _.getOr([], 'paths'),
    _.map((path) => ({
      type: 'Feature',
      geometry: _.get('coordinates', path),
      properties: path,
    })),
  )(parsedMap),
  //   normalize polygons
  ..._.flow(
    _.getOr([], 'polygons'),
    _.map((polygon) => ({
      type: 'Feature',
      geometry: _.get('coordinates', polygon),
      properties: polygon,
    })),
  )(parsedMap),
];

export const parseAndMergeMarkersAndFeatures = _.flow(
  parseMarkersAndFeatures,
  mergeMarkersAndFeatures,
);

export const pathsGeoJSON = _.map((path) => ({
  type: 'Feature',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  geometry: path.coordinates ?? path.multi_line_string,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  properties: _.omit(['coordinates', 'multi_line_string'], path),
}));

export const markerGeoJSON = _.map((marker) => ({
  type: 'Feature',
  geometry: _.get('point', marker),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  properties: _.omit(['point'], marker),
}));

export const transformedToGeoJSON = (obj) => ({
  type: 'FeatureCollection',
  features: [
    //   normalize area coordinates
    {
      type: 'Feature',
      geometry: obj.area_coordinates,
      properties: {},
    },
    //   normalize marker points
    ...markerGeoJSON(_.getOr([], 'markers', obj)),
    //   normalize paths
    ...pathsGeoJSON(_.getOr([], 'paths', obj)),
  ],
});
