import _ from 'lodash/fp';
import { makeAutoObservable, toJS } from 'mobx';
import { nanoid } from 'nanoid';
import client from '../util/api/client';
import { calculatePathViewport } from '../util/gis-utils';
import { EmbedMobx } from './embed.mobx';
import { EmbedCategoryMobx } from './embedCategory.mobx';
import { FeatureHoursMobx } from './featureHours.mobx';
import { IFeature, IGeometry, IProperties, ITagItem } from './ImapData';
import { applyParams, isLocalEntity } from './mobx.utils';
import { StoreMobx } from './store.mobx';
import { featureCustomHoursMobx } from './featureCustomHours.mobx';
import { Subject } from 'rxjs';

export const fly$ = new Subject<'drawer' | 'sidebar' | 'fly'>();

export const flyDebounced$ = new Subject();

export class EmbedFeatureMobx implements IFeature {
  parent: EmbedMobx;
  type: '';
  geometry: IGeometry;
  tempGeometry: IGeometry;
  properties: IProperties;
  selected = false;
  isVisible = true;
  dynamicMarkerKey = '';
  isClustered = false;
  draggable = false;
  featureHours = new FeatureHoursMobx(this);
  featureCustomHours = new featureCustomHoursMobx(this);
  tagItemsSet: Set<string>;

  constructor(parent: EmbedMobx, data: Record<string, unknown> = {}) {
    this.parent = parent;
    this.generateDynamicKey();
    applyParams(this, data);

    this.tagItemsSet = new Set(
      (this.tagItems || []).map((tagItem) => tagItem.id),
    );

    makeAutoObservable(
      this,
      this.root.isBuilderInstance
        ? undefined
        : {
            geometry: false,
            properties: false,
          },
    );
  }

  setGeometry = (geometry: IGeometry) => {
    if (!geometry) return;
    this.geometry = geometry;
    this.generateDynamicKey();
  };

  setTempGeometry = (geometry: IGeometry) => {
    if (!geometry) return;
    this.tempGeometry = geometry;
    this.generateDynamicKey();
  };

  get isDraggable() {
    return this.root.isBuilderInstance && this.draggable;
  }

  get referentialKey() {
    return `feature-${this.id}-${
      this.isDraggable ? 'draggable' : 'not-draggable'
    }-${this.dynamicMarkerKey}`;
  }

  setDraggable(to: boolean) {
    this.draggable = to;
  }

  get root(): StoreMobx {
    return this.parent.parent;
  }

  getAddressForCoords = async () => {
    const url =
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      this.geometry.coordinates[0] +
      ',' +
      this.geometry.coordinates[1] +
      '.json?access_token=' +
      process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN +
      '&types=address';
    const data = await client.get(url);
    return data.data.features && data.data.features[0]?.place_name;
  };

  setAddress = (address) => {
    this.properties.address = address;
  };

  setTagItems = (tag_items: ITagItem[]) => {
    this.properties.tag_items = tag_items;
  };

  get tagItems(): Array<ITagItem> {
    return this.properties.tag_items as Array<ITagItem>;
  }

  generateDynamicKey() {
    this.dynamicMarkerKey = nanoid();
  }

  toggleSelected = () => {
    this.selected = !this.selected;
  };

  toggleVisibility = () => {
    this.isVisible = !this.isVisible;
    // if a category eye icon is set to invisible, that should mean that all the features within a category are invisible, so if a user makes a single feature in a category visible, the category eye icon should toggle back to visible
    if (!this.category.isVisible && this.isVisible) {
      this.category.isVisible = true;
    }
  };
  onMarkerDrag = ({ lng, lat }: { lng: number; lat: number }) => {
    this.geometry.coordinates = [lng, lat];
    this.generateDynamicKey();
  };

  onMarkerClick = () => {
    if (this.root.isBuilderInstance) {
      this.handleViewFeature();
      return;
    }
  };

  handleViewFeature = () => {
    this.root.layout.builder.openModal(
      `feature.${this.entityType}.view`,
      'feature',
      this,
    );
  };

  handleModifyFeature = () => {
    this.root.layout.builder.clearSidebar();
    this.root.layout.builder.openModal(
      `feature.${this.entityType}.edit`,
      'feature',
      this,
    );
  };

  onPathClick = () => {
    if (this.root.isBuilderInstance) {
      this.handleViewFeature();
      return;
    }
  };

  onPolygonClick = () => {
    if (this.root.isBuilderInstance) {
      this.handleViewFeature();
      return;
    }
  };

  get firstPhoto() {
    return _.get('properties.photos[0]', this) ?? '/no-image-placeholder.png';
  }

  async flyTo() {
    switch (this.geometry.type) {
      case 'Point': {
        const coordinates = this.geometry.coordinates;
        this.parent.mapInstance.flyTo({
          center: coordinates,
          zoom: 16,
        });
        break;
      }
      case 'LineString':
      case 'MultiLineString': {
        const { bounds } = calculatePathViewport(
          this.geometry.coordinates,
          this.parent.parent.embed.mapInstance,
        );
        this.parent.mapInstance.fitBounds(bounds, {
          padding: 100,
          speed: 1.8,
          bearing: this.properties?.default_bearing,
          pitch: this.properties?.default_pitch,
        });
        break;
      }
    }
    await this.parent.mapInstance.once('idle');
    fly$.next('fly');
    this.parent.onManualViewportChange();
  }

  toggleAutoCompletePopup() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.properties.showAutoCompletePopup =
      !this.properties.showAutoCompletePopup;
  }
  setClustered = (value) => {
    if (this.isClustered !== value) {
      this.isClustered = value;
    }
  };

  updateFromServer = (data: Record<string, unknown>) => {
    // TODO: create better apply function
    applyParams(this.properties, data);
  };

  get id() {
    return this.properties.id as string;
  }

  get category(): EmbedCategoryMobx | null {
    if (this.parent.categories.has(this.properties.category_id as string)) {
      return this.parent.categories.get(this.properties.category_id as string);
    }
    return null;
  }

  get isPoint(): boolean {
    return this.geometry.type === 'Point';
  }

  get isPath(): boolean {
    return (
      this.geometry.type === 'LineString' ||
      this.geometry.type === 'MultiLineString'
    );
  }

  get isPolygon(): boolean {
    return (
      this.geometry.type === 'Polygon' || this.geometry.type === 'MultiPolygon'
    );
  }

  get isSupportedGeometryType(): boolean {
    return (
      this.geometry.type === 'LineString' ||
      this.geometry.type === 'MultiLineString' ||
      this.geometry.type === 'Point' ||
      this.geometry.type === 'Polygon' ||
      this.geometry.type === 'MultiPolygon'
    );
  }

  get entityType() {
    switch (this.geometry.type) {
      case 'LineString':
      case 'MultiLineString':
        return 'paths';
      case 'Point':
        return 'markers';
      case 'Polygon':
      case 'MultiPolygon':
        return 'polygons';
    }
  }

  get photos() {
    return _.flow(
      _.getOr([], 'photos'),
      _.castArray,
      _.compact,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    )(this.properties);
  }

  get highlight_medias() {
    return _.flow(
      _.getOr([], 'highlight_medias'),
      _.castArray,
      _.compact,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    )(this.properties);
  }

  get isLocal() {
    return isLocalEntity(this.id);
  }

  get hasPhotos() {
    return !!_.size(this.properties.photos);
  }

  get hasMedias() {
    return !!_.size(this.properties.highlight_medias);
  }

  get isOpen() {
    if (!this.parent.parent.embed.localTime) return false;
    // find which day today is
    const day = this.parent.parent.embed.localTime.getDay();
    const hours = this.parent.parent.embed.localTime.getHours();
    const minutes = this.parent.parent.embed.localTime.getMinutes();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const matchingPeriods = this.properties.hours?.periods?.filter((p) => {
      return (p.open && p.open.day) === day || (p.close && p.close.day) === day;
    });
    let isOpenNow = false;
    // @vince clean it up
    _.each((p) => {
      const local24hrtime = ('0000' + (hours * 100 + minutes)).slice(-4);
      if (!p.open || !p.close) {
        return;
      }
      const openTime = Number.parseInt(p.open.time, 10);
      const closeTime = Number.parseInt(p.close.time, 10);
      const localTime = Number.parseInt(local24hrtime, 10);
      if (
        (!openTime && !closeTime) ||
        (openTime < localTime && closeTime > localTime)
      ) {
        isOpenNow = true;
      }
    }, matchingPeriods);
    return isOpenNow;
  }

  get nextOpeningTime() {
    // only should be called if currently closed
    if (
      !this.parent.parent.embed.localTime ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !this.properties.hours?.periods?.length
    )
      return false;
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // find which day today is
    const today = this.parent.parent.embed.localTime.getDay();
    const hours = this.parent.parent.embed.localTime.getHours();
    const minutes = this.parent.parent.embed.localTime.getMinutes();
    const local24hrtime = ('0000' + (hours * 100 + minutes)).slice(-4);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mappedPeriods = this.properties.hours.periods.reduce((acc, curr) => {
      const day = curr.open?.day || curr.close?.day;
      return {
        ...acc,
        [day]: [
          ...(acc[day] || []),
          {
            ...(curr.open?.time ? { open: curr.open.time } : {}),
            ...(curr.close?.time ? { close: curr.close.time } : {}),
          },
        ],
      };
    }, {});
    let nextOpeningTime = '';
    let nextOpeningDay = '';
    const dayArr = [0, 1, 2, 3, 4, 5, 6];

    const sortedDayArr = dayArr.concat(dayArr.splice(0, dayArr.indexOf(today)));
    sortedDayArr.forEach((d) => {
      if (nextOpeningDay || !mappedPeriods[d]) {
        return;
      }
      if (d === today) {
        const openingTimesLaterToday = mappedPeriods[d].filter(
          (p) => p.open && parseInt(p.open) > parseInt(local24hrtime),
        );
        const closest = openingTimesLaterToday.sort(
          (a, b) =>
            Math.abs(parseInt(local24hrtime) - parseInt(a.open)) -
            Math.abs(parseInt(local24hrtime) - parseInt(b.open)),
        )[0];
        if (closest) {
          nextOpeningDay = 'Today';
          nextOpeningTime = closest.open;
        }
        return;
      } else {
        const openingTimes = mappedPeriods[d].filter((p) => p.open);
        const closest = openingTimes.sort(
          (a, b) =>
            Math.abs(0 - parseInt(a.open)) - Math.abs(0 - parseInt(b.open)),
        )[0];
        if (closest) {
          nextOpeningDay = days[d];
          nextOpeningTime = closest.open;
        }
        return;
      }
    });
    if (!nextOpeningTime) {
      return '';
    }
    const parsedHours = parseInt(nextOpeningTime.substring(0, 2));
    const nextOpeningTimePrefix = parsedHours < 12 ? 'AM' : 'PM';
    const nextOpeningTimeHours =
      parsedHours <= 12 ? parsedHours : parsedHours - 12;
    const nextOpeningDayString = `Opens ${nextOpeningDay} ${
      nextOpeningTimeHours || 12
    }:${nextOpeningTime.substring(2)} ${nextOpeningTimePrefix}`;

    return nextOpeningDayString;
  }

  get nextClosingTime() {
    // only should be called if currently closed
    if (
      !this.parent.parent.embed.localTime ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !this.properties.hours?.periods?.length
    )
      return false;

    // find which day today is
    const today = this.parent.parent.embed.localTime.getDay();
    const hours = this.parent.parent.embed.localTime.getHours();
    const minutes = this.parent.parent.embed.localTime.getMinutes();
    const local24hrtime = ('0000' + (hours * 100 + minutes)).slice(-4);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const matchingPeriods = this.properties.hours?.periods.filter((p) => {
      return (
        (p.open && p.open.day) === today || (p.close && p.close.day) === today
      );
    });
    const closest = matchingPeriods.sort(
      (a, b) =>
        Math.abs(parseInt(local24hrtime) - parseInt(a.close.time)) -
        Math.abs(parseInt(local24hrtime) - parseInt(b.close.time)),
    )[0];
    if (!closest) {
      return '';
    }
    if (closest.open.time === '0000' && closest.close.time === '0000') {
      return 'Open 24 hours';
    }
    if (closest.close.time === '0000') {
      return 'Open all day';
    }
    const parsedHours = parseInt(closest.close.time.substring(0, 2));
    const nextOpeningTimePrefix = parsedHours > 12 ? 'PM' : 'AM';
    const nextOpeningTimeHours =
      parsedHours > 12 ? parsedHours - 12 : parsedHours;
    const nextOpeningDayString = `Closes ${nextOpeningTimeHours}:${closest.close.time.substring(
      2,
    )} ${nextOpeningTimePrefix}`;

    return nextOpeningDayString;
  }

  get hasOpenHoursAvailable() {
    return !!_.get('properties.hours.periods', this);
  }

  get toJSON() {
    return {
      id: this.id,
      type: 'Feature',
      geometry: toJS(this.geometry),
      properties: { ...toJS(this.properties), isVisible: this.isVisible },
    };
  }

  get trackingData() {
    return {
      ...this.parent.trackingData,
      ...this.category?.trackingData,
      featureId: this.id,
      featureName: this.properties.name,
      featureType: this.entityType,
    };
  }

  get defaultFormState() {
    switch (this.entityType) {
      case 'markers':
        return {
          values: {
            id: this.properties.id,
            name: this.properties.name,
            photos: this.photos.slice(),
            highlight_medias: this.highlight_medias.slice(),
            category_id: this.properties.category_id,
            description: this.properties.description ?? null,
            address: this.properties.address ?? null,
            phone:
              (this.properties.phone as unknown as string)
                ?.replace(/[()]/g, '')
                .replace(/[ ]/, '-') ?? null,
            website: this.properties.website ?? null,
          },
          inputs: [
            'name',
            'address',
            'categories',
            'phone',
            'website',
            'description',
            'photos',
          ],
        };
      case 'paths':
        return {
          values: {
            id: this.properties.id,
            name: this.properties.name,
            photos: this.photos,
            highlight_medias: this.highlight_medias.slice(),
            category_id: this.properties.category_id,
            description: this.properties.description ?? null,
            default_pitch: this.properties.default_pitch ?? null,
            default_bearing: this.properties.default_bearing ?? null,
            website: this.properties.website ?? null,
          },
          inputs: [
            'name',
            'photos',
            'categories',
            'default_pitch',
            'default_bearing',
            'description',
            'website',
          ],
        };
      case 'polygons':
        return {
          values: {
            id: this.properties.id,
            name: this.properties.name,
            photos: this.photos.slice(),
            highlight_medias: this.highlight_medias.slice(),
            category_id: this.properties.category_id,
            description: this.properties.description ?? null,
            address: this.properties.address ?? null,
            phone:
              (this.properties.phone as unknown as string)
                ?.replace(/[()]/g, '')
                .replace(/[ ]/, '-') ?? null,
            website: this.properties.website ?? null,
          },
          inputs: [
            'name',
            'address',
            'categories',
            'phone',
            'website',
            'description',
            'photos',
          ],
        };
    }
  }
}
