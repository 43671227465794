import * as yup from 'yup';

export const uploadSchema = yup.object().shape({
  file: yup.mixed().nullable(),
  previewUrl: yup.string().nullable(),
  sizeExceeded: yup.boolean(),
});

export const mapInfoSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  tagline: yup.string().nullable(),
  address: yup.string(),
  contact: yup.object(),
  website: yup.string().nullable(),
  // publisher: yup.string(),
  logo_url: yup.string().nullable(),
  logo_url_upload: uploadSchema.optional(),
  cover_photo_url: yup.string().nullable(),
  cover_photo_url_upload: uploadSchema.optional(),
  primary_color: yup.string().nullable(),
  contrast_color: yup.string().nullable(),
  is_custom_tag_only: yup.boolean(),
  poi_suggestions_enabled: yup.boolean(),
  show_hours_filters: yup.boolean(),
  contents: yup
    .array(
      yup.object().shape({
        title: yup.string().nullable(),
        link: yup.string().nullable(),
        photo: yup.string().nullable(),
        upload: uploadSchema.optional(),
      }),
    )
    .nullable(),
});
