enum AgentType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export interface IPostGetAppDto {
  readonly phone: string;
  readonly map_id: string;
  readonly agent?: AgentType;
}
