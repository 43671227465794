import { makeAutoObservable, flow } from 'mobx';
import _ from 'lodash/fp';
import { StoreMobx } from './store.mobx';
import client from '../util/api/client';
import { auth, getProvider } from '../util/firebase';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth';
import debug from 'debug';
import { IPublisher } from './ImapData';
import { getLocalPermissions, refreshCookie } from '../util/api';

type PublisherClaims = {
  created_at: string;
  handle: string;
  id: string;
  name: string;
};

type Claims = {
  GnRqJv7bPUYyNscs17GLj?: number;
  email?: string;
  email_verified?: boolean;
  name?: string;
  picture?: string;
  user_id?: string;
  publisher?: PublisherClaims;
};

type AuthStatus = 'idle' | 'logged-in' | 'logged-out';
export class UserMobx {
  parent: StoreMobx;
  claims: Claims | null;
  authStatus: AuthStatus = 'idle';
  roles = null;
  plan: any = null;
  loginError = null;
  logger: debug.Debugger;
  isRefreshingToken = false;
  pinListsSynced = false;
  pinsFetched = false;
  listsFetched = false;

  constructor(parent: StoreMobx) {
    this.parent = parent;
    this.logger = this.parent.extendLogger(UserMobx.name);
    makeAutoObservable(this);
  }

  setPinListsSynced = (pinListsSynced: boolean) => {
    this.pinListsSynced = pinListsSynced;
  };

  setPinsFetched = (isFetched: boolean) => {
    this.pinsFetched = isFetched;
  };

  setListsFetched = (isFetched: boolean) => {
    this.listsFetched = isFetched;
  };

  setIsRefreshingToken = (isRefreshingToken: boolean) => {
    this.isRefreshingToken = isRefreshingToken;
  };

  get isLoggedIn(): boolean {
    return _.negate(_.isNil)(this.claims);
  }

  get isAdmin(): boolean {
    return !!this.claims?.GnRqJv7bPUYyNscs17GLj;
  }

  setClaims(claims: Claims | null) {
    this.claims = claims;
  }

  setPlan(plan: any) {
    this.plan = plan;
  }

  updateClaims = flow(
    function* setClaims() {
      if (auth.currentUser) {
        const { claims } = yield auth.currentUser?.getIdTokenResult(true);
        this.claims = { ...claims };
      }
    }.bind(this),
  );

  setAuthStatus = (status: AuthStatus) => {
    this.authStatus = status;
  };

  setLoginError = (error: string) => {
    this.loginError = error;
  };

  login = flow(function* login(loginData: {
    provider: string;
    email?: string;
    password?: string;
  }) {
    const self = this as UserMobx;
    self.authStatus = 'idle';
    switch (loginData.provider) {
      case 'email': {
        yield signInWithEmailAndPassword(
          auth,
          loginData.email,
          loginData.password,
        );
        break;
      }
      case 'google': {
        const googleProvider = getProvider('google');
        yield signInWithPopup(auth, googleProvider);
        break;
      }
      case 'facebook': {
        const facebookProvider = getProvider('facebook');
        yield signInWithPopup(auth, facebookProvider);
        break;
      }
      case 'apple': {
        const appleProvider = getProvider('apple');
        yield signInWithPopup(auth, appleProvider);
        break;
      }
    }
  });

  updateRoles = () => (this.roles = getLocalPermissions());

  setRoles = (roles) => (this.roles = roles);

  resetPassword = flow(function* resetPassword(email) {
    yield sendPasswordResetEmail(auth, email);
  });

  changePublisher = flow(function* changePublisher(
    publisher: IPublisher,
    internal = false,
  ) {
    if (!publisher) return;
    const self = this as UserMobx;
    try {
      yield internal
        ? client.patch('/admin/publisher_id', {
            publisher_id: publisher.id,
          })
        : client.patch('/users/switch-organization', {
            new_organization_id: publisher.id,
          });
      self.claims.publisher.id = publisher.id;
      self.claims.publisher.name = publisher.name;
      yield refreshCookie();
    } catch (err) {
      self.logger('unable to change publisher', err);
    }
  });

  changeOrgName = ({ name, handle }) => {
    this.claims.publisher.name = name;
    this.claims.publisher.handle = handle;
  };

  logout = flow(
    function* logout(manualTrigger = false) {
      yield auth.signOut().catch(_.noop);
      this.authStatus = 'logged-out';
      this.loginError = null;
      this.claims = null;
      this.roles = null;
      this.plan = null;
      this.pinListsSynced = false;
      this.pinsFetched = false;
      this.listsFetched = false;
      if (manualTrigger) {
        this.parent.embed.setPinnedList({});
        this.parent.embed.setBookMarkLists({});
      }

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        localStorage.removeItem(key);
      }
    }.bind(this),
  );
}
