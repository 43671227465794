import { useBookmark } from './bookmark.hooks';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getMapPins, pinOrUnnpinPoi } from '../util/api';
import { useMapId } from './map.hooks';
import { useStore } from './store.hooks';
import { useErrorToast } from './toasts';
import { useTracking } from './useTracking';
import * as _ from 'lodash/fp';
import { PINNED_PLACES_LIST_NAME } from '@wander-app/shared';

enum FeatureTypePinType {
  markers = 'marker',
  paths = 'path',
  polygons = 'polygon',
}

export const usePin = () => {
  const store = useStore();
  const errorToast = useErrorToast();
  const mapIdentifier = useMapId();
  const pinnedList = store.embed.pinnedList;
  const mapPinnedList = store.embed.pinnedList?.[mapIdentifier];
  const isFirstPin = store.embed.isFirstPin as boolean;
  const tracker = useTracking();
  const {
    mapBookMarkLists,
    addFeatureToBookMarkList,
    removeFeatureFromBookMarkList,
    createNewBookMarkList,
  } = useBookmark();
  const isLoggedIn = store.user.isLoggedIn;
  const pinOrUnpin = useMutation(pinOrUnnpinPoi, {
    onError: () => {
      errorToast('Error pinning your point of interest');
    },
  });
  useQuery(['mapPins', mapIdentifier], () => getMapPins(mapIdentifier), {
    enabled: isLoggedIn && store.user.pinListsSynced && !store.user.pinsFetched,
    onSuccess: (data) => {
      store.user.setPinsFetched(true);
      store.embed.mergePins({ [mapIdentifier]: data });

      if (Object.values(data).flat().length > 0) {
        store.embed.setIsFirstPin(false);
      }
    },
  });

  useEffect(() => {
    if (!isLoggedIn) {
      store.embed.resetPinsToLocalStorage();
    }
  }, []);

  const clearPins = async () => {
    if (!isLoggedIn) {
      window.localStorage.setItem('PIN-LIST', JSON.stringify([]));
    }
    store.embed.setPinnedList({});
    tracker.track('cleared pin list');
  };

  const pinFeature = async (featureId: string, featureType: string) => {
    const originalPinnedList = _.cloneDeep(pinnedList);

    const newPinnedList = {
      ...pinnedList,
      [mapIdentifier]: {
        ...pinnedList?.[mapIdentifier],
        [featureType]: pinnedList?.[mapIdentifier]?.[featureType]
          ? [...pinnedList[mapIdentifier][featureType], featureId]
          : [featureId],
      },
    };

    if (!isLoggedIn) {
      window.localStorage.setItem('PIN-LIST', JSON.stringify(newPinnedList));
    }

    store.embed.setPinnedList(newPinnedList);
    tracker.track(
      isLoggedIn ? 'feature pinned' : 'feature pinned by non-signed-in user',
      { featureId },
    );

    if (mapBookMarkLists?.[PINNED_PLACES_LIST_NAME]) {
      await addFeatureToBookMarkList(
        PINNED_PLACES_LIST_NAME,
        featureId,
        featureType,
      );
    } else {
      await createNewBookMarkList(
        PINNED_PLACES_LIST_NAME,
        featureId,
        featureType,
      );
    }

    if (isLoggedIn) {
      try {
        await pinOrUnpin.mutateAsync({
          mapId: mapIdentifier,
          data: {
            type: FeatureTypePinType[featureType],
            poi_id: featureId,
            pinned: true,
          },
        });
      } catch (error) {
        console.error(error);
        store.embed.setPinnedList(originalPinnedList);
      }
    }
  };

  const unpinFeature = async (featureId: string, featureType: string) => {
    const originalPinnedList = _.cloneDeep(pinnedList);

    const newPinnedList = {
      ...pinnedList,
      [mapIdentifier]: {
        ...pinnedList?.[mapIdentifier],
        [featureType]: pinnedList?.[mapIdentifier]?.[featureType]
          ? pinnedList[mapIdentifier][featureType].filter(
              (feature) => feature !== featureId,
            )
          : [],
      },
    };

    if (!isLoggedIn) {
      window.localStorage.setItem('PIN-LIST', JSON.stringify(newPinnedList));
    }

    store.embed.setPinnedList(newPinnedList);
    tracker.track(
      isLoggedIn
        ? 'feature unpinned'
        : 'feature unpinned by non-signed-in user',
      { featureId },
    );
    await removeFeatureFromBookMarkList(
      PINNED_PLACES_LIST_NAME,
      featureId,
      featureType,
    );

    if (isLoggedIn) {
      try {
        await pinOrUnpin.mutateAsync({
          mapId: mapIdentifier,
          data: {
            type: FeatureTypePinType[featureType],
            poi_id: featureId,
            pinned: false,
          },
        });
      } catch (error) {
        console.error(error);
        store.embed.setPinnedList(originalPinnedList);
      }
    }
  };

  return {
    mapPinnedList,
    pinFeature,
    unpinFeature,
    clearPins,
    isFirstPin,
  };
};
