import { useCallback } from 'react';
import { Subject } from 'rxjs';
import { useStore } from './store.hooks';

export const pinBookmarkClicked$ = new Subject();

export type PinBookmarkClickedData = {
  type: 'PIN' | 'BOOKMARK';
  isPinned?: boolean;
  isBookMarked?: boolean;
};

export const useHandlePinBookmarkClick = () => {
  const store = useStore();
  const triggerPinBookmarkAnimation = useCallback(
    (event, data: PinBookmarkClickedData) => {
      const rect = event.target.getBoundingClientRect();
      pinBookmarkClicked$.next({ rect, data });
    },
    [store.user.authStatus],
  );

  return { triggerPinBookmarkAnimation };
};
