import client, { wrap } from '../client';
import { IPutSortTagPois } from '@wander-app/shared';

export const updateFeature = (data) => {
  const { mapId, id, types, ...payload } = data;

  switch (types) {
    case 'markers': {
      return wrap(
        client.patch(`/maps/${mapId}/markers/${id}`, {
          marker: payload,
        }),
      );
    }
    case 'paths': {
      return wrap(
        client.patch(`/maps/${mapId}/paths/${id}`, {
          path: payload,
        }),
      );
    }
    case 'polygons': {
      return wrap(
        client.patch(`/maps/${mapId}/polygons/${id}`, {
          polygon: payload,
        }),
      );
    }
  }
};

export const addFeature = (data) => {
  const { mapId, id, types, ...payload } = data;

  switch (types) {
    case 'markers': {
      return wrap(
        client.post(`/maps/${mapId}/markers`, {
          marker: payload,
        }),
      );
    }
    case 'paths': {
      return wrap(
        client.post(`/maps/${mapId}/paths`, {
          path: payload,
        }),
      );
    }
    case 'polygons': {
      return wrap(
        client.post(`/maps/${mapId}/polygons`, {
          polygon: payload,
        }),
      );
    }
  }
};

export const deleteFeature = (data) => {
  switch (data.types) {
    case 'markers': {
      return wrap(client.delete(`/maps/${data.map_id}/markers/${data.id}`));
    }
    case 'paths': {
      return wrap(client.delete(`/maps/${data.map_id}/paths/${data.id}`));
    }
    case 'polygons': {
      return wrap(client.delete(`/maps/${data.map_id}/polygons/${data.id}`));
    }
  }
};

export const bulkFeaturesGeoJson = (data) => {
  const { mapId, ...geojson } = data;
  return wrap(client.patch(`/maps/${mapId}/bulk/features/geojson`, geojson));
};

export const sortFeatures = ({ mapId, data }) =>
  wrap(client.put(`/places-of-interest/sort`, data));

export const sortTagFeatures = ({
  tagId,
  data,
}: {
  tagId: string;
  data: IPutSortTagPois;
}) => wrap(client.put(`/places-of-interest/items/${tagId}/sort-pois`, data));
