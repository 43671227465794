export enum UserPinType {
  Marker = 'marker',
  Path = 'path',
  Polygon = 'polygon',
}

export interface IPatchUserPinsDto {
  type: UserPinType;
  poi_id: string;
  pinned: boolean;
}
