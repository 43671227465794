import client from '../client';
import { extractData } from '../../extract-data';
import {
  IPostCreateSendUserInviteDto,
  ICheckUserInviteExistsQueryDto,
} from '@wander-app/shared';

export const createSendUserInvite = (data: IPostCreateSendUserInviteDto) =>
  client.post('/publisher/create-send-user-invite', data).then(extractData);

export const checkUserInviteExists = (email: string): Promise<boolean> => {
  const params: ICheckUserInviteExistsQueryDto = { email };
  return client
    .get('/publisher/check-user-invite-email-exists', {
      params,
    })
    .then(({ data }) => data.exists);
};

export const getUserInvites = (completed: boolean) =>
  client
    .get('/publisher/user-invites', { params: { completed } })
    .then(extractData);

export const updateOrganizationPlan = ({
  organizationId,
  planId,
}: {
  organizationId: string;
  planId: string;
}) =>
  client
    .patch(`/publisher/${organizationId}/plan`, { plan_id: planId })
    .then(extractData);

export const cancelUserInvite = (email: string) =>
  client.delete(`/publisher/user-invite/${email}`).then(extractData);

export const getPublisherById = (id: string) =>
  client.get(`/publisher/${id}`).then(extractData);

export const getDefaultMapSuggestions = () =>
  client.get('/publisher/maps/default_autosuggest').then(extractData);
