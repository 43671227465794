import _ from 'lodash/fp';
import { makeAutoObservable, observable, runInAction, toJS } from 'mobx';
import { fuzzy } from 'fast-fuzzy';
import { FUZZY_SEARCH_THRESHOLD, LOCAL_CATEGORY_ID } from './mobx.utils';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { EmbedCategoryMobx } from './embedCategory.mobx';
import { IMapData, IProperties, IPropertyMapping } from './ImapData';
import maskGeoJson from '../util/maskGeoJSON';
import { mapToArray } from '../util/store.util';
import { StoreMobx } from './store.mobx';
import { localCategory } from './localCategory';
import { MutableRefObject } from 'react';
import { pointWithinBounds } from '../util/gis-utils';
import {
  distance,
  lineString,
  pointToLineDistance,
  multiLineString,
  pointOnFeature,
  combine,
} from '@turf/turf';
import F from 'futil';
import { SegmentBundleMobx } from './segmentBundle.mobx';
import mixpanel from '../util/mixpanel';
import { useMediaQueries } from '../hooks';
import dayjs from 'dayjs';

type GeolocatorInstanceStatus =
  | 'inactive'
  | 'error'
  | 'active'
  | 'ready'
  | 'pending';

type FeaturesTypesListsT = {
  markers?: string[];
  paths?: string[];
  polygons?: string[];
  id?: string;
  readOnly?: boolean;
};

export class EmbedMobx {
  parent: StoreMobx;
  geolocatorInstance = null;
  geolocatorInstanceStatus: GeolocatorInstanceStatus = 'inactive';
  mapInstance = null;
  isUrlAlternativeId = false;
  mapReady = false;
  ready = false;
  map?: IMapData['map'] = null;
  categoryFilterCollapsed = false;
  allCategoriesFeaturesSelectedCheckbox = false;
  allCategoriesFeaturesVisibleCheckbox = true;
  features = observable.map<string, EmbedFeatureMobx>();
  categories = observable.map<string, EmbedCategoryMobx>();
  downloadModalOpen = false;
  localTime: Date = null;
  searchTerm = '';
  defaultStyles: IMapData['styles'][] = [];
  publisherStyles: IMapData['styles'][] = [];
  viewport = {
    width: '100%',
    height: '100%',
    longitude: -111.6788,
    latitude: 46.6788,
    zoom: 16,
    bearing: 0,
    pitch: 0,
    flatBounds: [0, 0, 0, 0],
    defaultZoom: null,
  };
  foursquareSessionToken = '';
  mapInstanceUpdateCounter = 0;
  // desktop ui state
  selectedMarker: EmbedFeatureMobx | null;
  hoveredMarker: EmbedFeatureMobx | null;
  selectedPath: EmbedFeatureMobx | null;
  selectedPolygon: EmbedFeatureMobx | null;
  hoveredPath: EmbedFeatureMobx | null;
  hoveredPolygon: EmbedFeatureMobx | null;

  // mobile ui state
  activeFeatureMobile: EmbedFeatureMobx | null;
  selectedFeatureMobile: EmbedFeatureMobx | null;
  sliderFeaturesList = [];
  lastSliderListUpdateTimestamp = null;
  mobileSliderOpen: boolean;
  // When swiping to view a path, we adjust the map to show it's label in full,
  // but we don't want that adjustment to re-set the slider feature list (sometimes its a big map move for long paths)
  // then, we want to return back to the previous center coordinates as soon as the user swipes to
  // a marker again (<- not implemented yet as of Dec 2021, that's what prevViewportStateMobile is for),
  disableSliderViewportReactions: boolean;
  // TODO
  // prevViewportStateMobile: null;

  clusterRenderCount: number;
  accordionItemsOpen = [];

  segmentBundles = observable.map<string, SegmentBundleMobx>();
  hoveredSegments = observable.map<string, EmbedFeatureMobx>();

  // TODO: move to builder
  importPropertyMapping: IPropertyMapping | null;
  importLogs: string[];

  layerRef: MutableRefObject<unknown> = {
    current: null,
  };

  temporaryMap?: IMapData['map'] = null;

  urlFeatureLoaded = false;
  _tagCategories: EmbedCategoryMobx[];

  pinnedList: { [key: string]: FeaturesTypesListsT } = {};

  isFirstPin = true;

  isFirstPinModalOpen = false;

  categoryIcons = [];

  setFirstPinModal(val: boolean) {
    this.isFirstPinModalOpen = val;
  }

  setIsFirstPin(val: boolean) {
    this.isFirstPin = val;
  }

  clearPinsByMapId(mapId: string) {
    if (this.pinnedList[mapId]) {
      delete this.pinnedList[mapId];
    }
  }

  setPinnedList(list: { [key: string]: FeaturesTypesListsT }) {
    this.pinnedList = JSON.parse(JSON.stringify(list));
    if (
      list &&
      Object.values(list)
        .map((l) => Object.values(l).flat())
        .flat().length
    )
      this.isFirstPin = false;
  }

  bookMarkLists: {
    [key: string]: {
      [key: string]: FeaturesTypesListsT;
    };
  } = {};

  setBookMarkLists(bookMarkLists: {
    [key: string]: {
      [key: string]: FeaturesTypesListsT;
    };
  }) {
    this.bookMarkLists = _.cloneDeep(bookMarkLists);
  }

  mergePins(data: { [key: string]: FeaturesTypesListsT }) {
    this.pinnedList = _.merge(this.pinnedList, data);
  }

  mergeBookMarkLists(data: {
    [key: string]: {
      [key: string]: FeaturesTypesListsT;
    };
  }) {
    this.bookMarkLists = _.merge(this.bookMarkLists, data);
  }

  resetPinsToLocalStorage() {
    try {
      if (typeof window === 'undefined') return;
      const item = localStorage.getItem('PIN-LIST');
      if (Object.keys(this.pinnedList).length === 0) {
        this.setPinnedList(item ? JSON.parse(item) : {});
      }
    } catch (err) {
      console.error(err);
      this.setPinnedList({});
    }
  }

  resetBookMarksToLocalStorage() {
    try {
      if (typeof window === 'undefined') return;
      const item = localStorage.getItem('BOOKMARK-LISTS');
      if (Object.keys(this.bookMarkLists).length === 0) {
        this.setBookMarkLists(item ? JSON.parse(item) : {});
      }
    } catch (error) {
      console.log(error);
      this.setBookMarkLists({});
    }
  }

  get tagCategories() {
    if (!this._tagCategories) {
      this._tagCategories = _.flow(
        _.filter('custom_tag'),
        _.filter(
          (category: EmbedCategoryMobx) => category.allFeatures.length > 0,
        ),
        _.sortBy((c: EmbedCategoryMobx) => c.sort_order),
      )(mapToArray(this.categories));
    }
    return this._tagCategories;
  }

  get urlDataLoaded() {
    return (
      this.mapReady && this.parent.tags.urlFiltersReady && this.urlFeatureLoaded
    );
  }

  setUrlFeatureLoaded(loaded: boolean) {
    this.urlFeatureLoaded = loaded;
  }

  incrementMapUpdateCounter = () => {
    this.mapInstanceUpdateCounter = this.mapInstanceUpdateCounter + 1;
  };

  setFoursquareSessionToken = (token: string) => {
    this.foursquareSessionToken = token;
  };

  get categoryLayerIds() {
    return _.flow(
      _.map('id'),
      _.filter((id) => id),
      _.map((id) => [
        `line-${id}`,
        `line-padding-${id}`,
        `symbol-${id}`,
        `polygon-${id}`,
        `polygon-border-${id}`,
        `polygon-border-label-${id}`,
      ]),
      _.flatten,
    )(mapToArray(this.categories));
  }

  setImportLogs = _.noop;

  setImportPropertyMapping(mapping) {
    this.importPropertyMapping = mapping;
  }

  setDownloadModalOpen(open) {
    this.downloadModalOpen = open;
  }

  setDisableSliderViewportReactions = (disable) => {
    this.disableSliderViewportReactions = disable;
  };

  setMobileSliderOpen = (open) => {
    this.mobileSliderOpen = open;
  };

  get selectedFeatureID(): string | null {
    return this.parent.layout.public?.selectedFeature?.id || null;
  }

  getFeatureByID(id: string) {
    return this.features.get(id);
  }

  setSelectedFeatureMobile = (feature) => {
    this.selectedFeatureMobile = feature;
  };
  setActiveFeatureMobile = (feature) => {
    this.activeFeatureMobile = feature;
  };
  setSliderFeaturesList = (features) => {
    this.sliderFeaturesList = features;
  };
  setLastSliderListUpdateTimestamp = () => {
    Date.now();
  };

  setSelectedMarker = (marker) => {
    this.selectedMarker = marker;
  };
  setHoveredMarker = (marker) => {
    this.hoveredMarker = marker;
  };

  setSelectedPath = (path) => {
    this.selectedPath = path;
  };
  setHoveredPath = (path) => {
    this.hoveredPath = path;
  };
  setSelectedPolygon = (polygon) => {
    this.selectedPolygon = polygon;
  };
  setHoveredPolygon = (polygon) => {
    this.hoveredPolygon = polygon;
  };

  // SEGMENT BUNDLE START

  calculateSegmentBundles = (
    merge_property_one = '',
    operator_one = '',
    merge_property_two = '',
  ) => {
    _.each((bundle: SegmentBundleMobx) => {
      this.segmentBundles.delete(bundle.dynamicKey);
    }, mapToArray(this.segmentBundles));

    const final = _.reduce(
      (acc, feature: EmbedFeatureMobx) => {
        if (feature.isPoint) {
          return acc;
        }
        const merge_value_one = _.getOr(
          '',
          merge_property_one,
          feature.properties,
        );
        const merge_value_two = _.getOr(
          '',
          merge_property_two,
          feature.properties,
        );
        const matchingBundle: SegmentBundleMobx = _.find(
          (segmentBundle: SegmentBundleMobx) => {
            if (merge_value_two && operator_one === 'AND') {
              return (
                segmentBundle.merge_property_one === merge_value_one &&
                segmentBundle.merge_property_two === merge_value_two
              );
            } else if (merge_property_two && operator_one === 'OR') {
              return (
                segmentBundle.merge_property_one === merge_value_one ||
                segmentBundle.merge_property_two === merge_value_two
              );
            } else {
              return segmentBundle.merge_property_one === merge_value_one;
            }
          },
          acc,
        );
        if (!matchingBundle) {
          const merge_values = {
            merge_property_one: merge_value_one,
            merge_property_two: merge_value_two,
          };
          const bundle = new SegmentBundleMobx(this, {
            ...merge_values,
          });
          bundle.addFeatures([feature]);
          return [...acc, bundle];
        } else {
          matchingBundle.addFeatures([feature]);
          return acc;
        }
      },
      [],
      this.selectedFeatures,
    );

    _.each((bundle: SegmentBundleMobx) => {
      this.segmentBundles.set(bundle.dynamicKey, bundle);
    }, final);
  };

  get hoveredSegmentsCombinedGeometry() {
    const segments: any[] = _.map(
      (feature: EmbedFeatureMobx) => feature.toJSON,
      mapToArray(this.hoveredSegments),
    );

    const final = combine({ type: 'FeatureCollection', features: segments });
    return final;
  }

  get selectedPathSegmentBundles() {
    return _.filter(
      (bundle: SegmentBundleMobx) => bundle.selected,
      mapToArray(this.segmentBundles),
    );
  }

  // @performance
  get selectedPathSegmentBundlesGeoJSON() {
    const featuresArray = _.map((bundle: SegmentBundleMobx) => {
      const features: any = _.map(
        (feature: EmbedFeatureMobx) => feature.toJSON,
        bundle.selectedFeatures,
      );
      const final = combine({ type: 'FeatureCollection', features });
      const emptyNames = ['No Name Path', 'no name path', 'No Name', 'no name'];
      const findName = features.find(
        (x) => !!x.properties.name && !emptyNames.includes(x.properties.name),
      );
      const category_id = features[0].properties.category_id;
      let longestDescription = 'No Description';
      let photos = [];
      features.forEach((segment) => {
        if (
          !!segment.properties.description &&
          segment.properties.description.length > longestDescription.length
        ) {
          longestDescription = segment.properties.description;
        }
        if (segment.properties.photos && segment.properties.photos.length) {
          photos = [...photos, ...segment.properties.photos];
        }
      });
      const finalFeature = {
        ...final.features[0],
        properties: {
          ...final.features[0].properties,
          name: findName
            ? findName.properties.name + ' (Combined)'
            : 'No Name Combined Path',
          description: longestDescription,
          category_id,
          ...(photos.length ? { photos } : {}),
        },
      };
      return finalFeature;
    }, this.selectedPathSegmentBundles);
    return {
      type: 'FeatureCollection',
      features: featuresArray,
    };
  }

  get hoveredSegmentsCombinedFeature() {
    const [feat] = this.hoveredSegments.values();
    const feature = new EmbedFeatureMobx(this, {
      ...feat,
      geometry: this.hoveredSegmentsCombinedGeometry.features[0].geometry,
    });
    return feature;
  }

  setHoveredBundleSegments = (features: EmbedFeatureMobx[]) => {
    _.each((feature: EmbedFeatureMobx) => {
      this.hoveredSegments.set(feature.id, feature);
    }, features);
  };
  removeHoveredBundleSegments = (features: EmbedFeatureMobx[]) => {
    _.each((feature: EmbedFeatureMobx) => {
      this.hoveredSegments.delete(feature.id);
    }, features);
  };
  // SEGMENT BUNDLE END

  setLayerRef = (ref: MutableRefObject<unknown>) => {
    this.layerRef = ref;
  };

  setDefaultStyles = (styles) => {
    this.defaultStyles = styles;
  };

  setPublisherStyles = (styles) => {
    this.publisherStyles = styles;
  };

  setAccordionState = (accordion) => {
    const isOpen = _.includes(accordion, this.accordionItemsOpen);
    if (isOpen)
      this.accordionItemsOpen = _.pull(accordion, this.accordionItemsOpen);
    else this.accordionItemsOpen = [...this.accordionItemsOpen, accordion];
  };

  incrementClusterRenderCount = () => {
    this.clusterRenderCount = this.clusterRenderCount + 1;
  };

  // @performance
  updateRenderedFeatures = () => {
    return new Promise((resolve, reject) => {
      if (!this.mapInstance) return resolve([]);
      const features = this.mapInstance.queryRenderedFeatures();
      const pathsWithinView = _.reduce(
        (acc, f) => {
          if (
            f.source === 'map-data' &&
            f.layer.type === 'line' &&
            !f.layer.id.includes('padding')
          ) {
            // https://github.com/mapbox/mapbox-gl-js/issues/3099
            // in queryRenderedFeatures, paths are counted for each map tile that is queried, so we have to remove duplicates.
            // would it be more performant to check the array of features for the array instead of re-creating the Set for each duplicate?
            return _.uniq([...acc, this.features.get(f.properties.id)]);
          } else {
            return acc;
          }
        },
        [],
        features,
      );
      const canvas = this.mapInstance.getCanvas();
      const w = canvas.width;
      const h = canvas.height;
      const cUL = this.mapInstance.unproject([0, 0]).toArray();
      const cUR = this.mapInstance.unproject([w, 0]).toArray();
      const cLR = this.mapInstance.unproject([w, h]).toArray();
      const cLL = this.mapInstance.unproject([0, h]).toArray();
      const coordinates = [cUL, cUR, cLR, cLL, cUL];
      const markersWithinView = _.filter((f) => {
        if (
          !f.category.isVisible &&
          !this.filteredFeaturesArrayV2Set.has(f.id) &&
          !this.listedByDefaultFeaturesSet.has(f.id)
        ) {
          return false;
        }
        if (f.geometry.type === 'Point') {
          const isWithinView = pointWithinBounds(coordinates, f);
          return isWithinView;
        }
        return false;
      }, this.visibleFeatures);

      // _.sortBy('properties.name')
      const sortedFeaturesWithinView = [
        ...pathsWithinView,
        ...markersWithinView,
      ].sort((a, b) => {
        if (a.properties.name < b.properties.name) {
          return -1;
        }
        if (a.properties.name > b.properties.name) {
          return 1;
        }
        return 0;
      });
      this.setSliderFeaturesList(sortedFeaturesWithinView);
      this.setLastSliderListUpdateTimestamp();
      return resolve(sortedFeaturesWithinView);
    });
  };

  getDistanceBetweenFeatureAndCoord = (referenceCoord, feature) => {
    switch (feature.geometry.type) {
      case 'Point':
        return distance(referenceCoord, feature.geometry.coordinates);
      case 'MultiLineString':
        // eslint-disable-next-line no-case-declarations
        const multiLine = multiLineString(feature.geometry.coordinates);
        // eslint-disable-next-line no-case-declarations
        const pointOnLine = pointOnFeature(multiLine);
        return distance(referenceCoord, pointOnLine);
      case 'LineString':
        // eslint-disable-next-line no-case-declarations
        const line = lineString(feature.geometry.coordinates);
        return pointToLineDistance(referenceCoord, line);
    }
  };

  onLayerClicked = (event) => {
    this.parent.logger('clicking onLayerClick event and it is', event);
    if (
      this.parent.isBuilderInstance &&
      this.parent.layout.builder.editSidebarActive
    ) {
      return;
    }
    if (event.features.length > 0) {
      const nextHoveredStateId = _.get('features[0].properties.id', event);

      if (
        (this.selectedPath?.id !== nextHoveredStateId ||
          this.selectedPolygon?.id !== nextHoveredStateId) &&
        this.features.has(nextHoveredStateId)
      ) {
        const feature = this.features.get(nextHoveredStateId);
        if (
          !feature.category.isVisible &&
          !this.filteredFeaturesArrayV2Set.has(feature.id) &&
          !this.listedByDefaultFeaturesSet.has(feature.id)
        ) {
          return;
        }
        if (this.parent.layout.page.isNotDesktop) {
          // mobile ui
          this.setActiveFeatureMobile(feature);
        } else {
          // desktop ui
          switch (feature.entityType) {
            case 'paths': {
              feature.onPathClick();
              this.setSelectedPath(feature);
              this.setHoveredPath(null);
              this.setHoveredMarker(null);
              this.setSelectedMarker(null);
              this.setSelectedPolygon(null);
              this.setHoveredPolygon(null);
              break;
            }
            case 'polygons': {
              feature.onPolygonClick();
              this.setSelectedPolygon(feature);
              this.setHoveredPath(null);
              this.setHoveredMarker(null);
              this.setSelectedMarker(null);
              this.setSelectedPath(null);
              break;
            }
          }
        }

        return;
      }
    }
  };

  previousCursor = '';

  onCategoryLayerHover = (event) => {
    if (!this.mapInstance) return;
    const oldCursorStyle = this.mapInstance.getCanvas().style.cursor;
    if (this.previousCursor === oldCursorStyle) {
      this.previousCursor = oldCursorStyle;
    }
    this.mapInstance.getCanvas().style.cursor = 'pointer';

    if (event.features.length > 0) {
      const nextHoveredStateId = _.get('features[0].properties.id', event);

      if (
        (this.selectedPath?.id !== nextHoveredStateId ||
          this.selectedPolygon?.id !== nextHoveredStateId) &&
        this.features.has(nextHoveredStateId)
      ) {
        const feature = this.features.get(nextHoveredStateId);
        switch (feature.entityType) {
          case 'paths': {
            if (this.hoveredPath !== feature) {
              this.setHoveredPath(feature);
            }
            break;
          }
          case 'polygons': {
            if (this.hoveredPolygon !== feature) {
              this.setHoveredPolygon(feature);
            }
            break;
          }
        }
      }
    }
  };

  onCategoryLayerLeave = (event) => {
    if (!this.mapInstance) return;
    this.mapInstance.getCanvas().style.cursor = this.previousCursor;
    if (this.hoveredPath && !event.features.length) {
      this.setHoveredPath(null);
    } else if (this.hoveredPolygon && !event.features.length) {
      this.setSelectedPolygon(null);
    }
  };

  googleFeatureInProgress = false;

  setGoogleFeatureInProgress(value: boolean) {
    this.googleFeatureInProgress = value;
  }

  toggleCategoryFilterCollapsed = () => {
    this.categoryFilterCollapsed = !this.categoryFilterCollapsed;
  };

  toggleSelectAllCategories = (checked: boolean) => {
    this.categories.forEach((cat) => {
      cat.isVisible = checked;
    });
  };

  setSearchTerm = (term: string) => {
    this.searchTerm = term ?? '';
  };

  get allPublisherAndDefaultStyles() {
    return [...this.publisherStyles, ...this.defaultStyles];
  }

  get selectedPathsBuilderGeoJSON() {
    return {
      type: 'FeatureCollection',
      features: _.flow(
        _.map('toJSON'),
        _.map(_.omit(['id', 'properties.id'])),
      )(this.selectedFeatures),
    };
  }

  get selectedPathMobileGeoJSON() {
    if (!this.activeFeatureMobile || this.activeFeatureMobile.isPoint) {
      return { type: 'FeatureCollection', features: [] };
    }
    return {
      type: 'Feature',
      properties: this.activeFeatureMobile.properties,
      geometry: this.activeFeatureMobile.geometry,
    };
  }

  get selectedPathGeoJSON() {
    if (!this.selectedPath) {
      return { type: 'FeatureCollection', features: [] };
    }
    return {
      type: 'Feature',
      properties: this.selectedPath.properties,
      geometry: this.selectedPath.geometry,
    };
  }

  get hoveredPathGeoJSON() {
    const hoveredPath = this.hoveredPath ?? this.hoveredPolygon;
    if (!hoveredPath) {
      return { type: 'FeatureCollection', features: [] };
    }
    return {
      type: 'Feature',
      properties: hoveredPath.properties,
      geometry: hoveredPath.geometry,
    };
  }

  get matchingFeatures() {
    if (_.isEmpty(this.searchTerm)) return mapToArray(this.features);
    return (
      _.filter(
        (feature: EmbedFeatureMobx) =>
          fuzzy(this.searchTerm, feature.properties?.name as string) >=
          FUZZY_SEARCH_THRESHOLD,
        mapToArray(this.features),
      ) || []
    );
  }

  get categoriesSortedAndHaveItems() {
    const indexWithoutLocalCat = _.filter(
      (cat) => cat.name !== 'local category',
      this.allCategoriesArray,
    );
    F.mapIndexed(
      (category, index) => (category.index = index),
      indexWithoutLocalCat,
    );
    if (_.size(this.searchTerm) < 2) return this.allCategoriesArray;
    return _.filter(
      (cat: EmbedCategoryMobx) => _.size(cat.filteredFeatures) !== 0,
      this.allCategoriesArray,
    );
  }

  filterOutLocalCategories = (categories) =>
    _.filter((c) => c.id !== LOCAL_CATEGORY_ID, categories);

  get nonLocalCategoriesSortedAndHaveItems() {
    return this.filterOutLocalCategories(this.categoriesSortedAndHaveItems);
  }

  get allCategoriesArray(): EmbedCategoryMobx[] {
    return _.sortBy(
      'sort_order',
      mapToArray(this.categories),
    ) as EmbedCategoryMobx[];
  }

  get allNonLocalCategoriesArray(): EmbedCategoryMobx[] {
    return this.filterOutLocalCategories(this.allCategoriesArray);
  }

  get skyLayerProps() {
    return {
      id: 'sky',
      type: 'sky',
      paint: {
        ...(this.map.styles.sky_type
          ? { 'sky-type': this.map.styles.sky_type }
          : {}),
        ...(this.map.styles.sky_atmosphere_sun
          ? { 'sky-atmosphere-sun': this.map.styles.sky_atmosphere_sun }
          : {}),
        ...(this.map.styles.sky_atmosphere_sun_intensity
          ? {
              'sky-atmosphere-sun-intensity': Number(
                this.map.styles.sky_atmosphere_sun_intensity || 2,
              ),
            }
          : {}),
        ...(this.map.styles.sky_atmosphere_color
          ? { 'sky-atmosphere-color': this.map.styles.sky_atmosphere_color }
          : {}),
        ...(this.map.styles.sky_opacity
          ? { 'sky-opacity': Number(this.map.styles.sky_opacity || 1) }
          : {}),
        ...(this.map.styles.sky_atmosphere_halo_color
          ? {
              'sky-atmosphere-halo-color':
                this.map.styles.sky_atmosphere_halo_color,
            }
          : {}),
        ...(this.map.styles.sky_gradient
          ? {
              'sky-gradient': this.map.styles.sky_gradient.includes('[')
                ? JSON.parse(this.map.styles.sky_gradient)
                : this.map.styles.sky_gradient,
            }
          : {}),
        ...(this.map.styles.sky_gradient_center
          ? { 'sky-gradient-center': this.map.styles.sky_gradient_center }
          : {}),
        ...(this.map.styles.sky_gradient_radius
          ? {
              'sky-gradient-radius': Number(
                this.map.styles.sky_gradient_radius || 4,
              ),
            }
          : {}),
        // TODO: test all sky related map style values
      },
    };
  }

  setAllFeaturesSelectedTo = (to: boolean) => {
    this.allCategoriesFeaturesSelectedCheckbox = to;
    runInAction(() => {
      _.each((ele) => {
        ele.selected = to;
      }, this.filteredFeaturesArray);
    });
  };

  setAllCategoriesAndFeaturesVisibilityTo = (to: boolean) => {
    this.allCategoriesFeaturesVisibleCheckbox = to;
    this.categories.forEach((category) => {
      category.setVisibility(to);
    });
    this.features.forEach((feature) => {
      feature.isVisible = to;
    });
  };

  setFeaturesVisibilityToTrue = (features: string[]) => {
    this.setAllCategoriesAndFeaturesVisibilityTo(true);
    this.features.forEach((feature) => {
      if (features?.find((feat) => feat === feature.id)) {
        feature.isVisible = true;
      } else {
        feature.isVisible = false;
      }
    });
  };

  get allCategoriesSelected() {
    return this.allCategoriesArray.every((f) => f.selected);
  }

  get allSelectedCategoriesCount() {
    return _.size(
      _.filter(
        (f) => f.id !== LOCAL_CATEGORY_ID && f.isVisible,
        this.allCategoriesArray,
      ),
    );
  }
  get allFeaturesSelected() {
    return this.filteredFeaturesArray.every((f) => f.selected);
  }

  get allFeaturesVisible() {
    return this.filteredFeaturesArray.every((f) => f.isVisible);
  }

  get someFeaturesSelected() {
    return (
      this.filteredFeaturesArray.some((f) => f.selected) &&
      !this.allFeaturesSelected
    );
  }

  get someFeaturesVisible() {
    return (
      this.filteredFeaturesArray.some((f) => f.isVisible) &&
      !this.allFeaturesVisible
    );
  }

  get geoJSON() {
    return {
      type: 'FeatureCollection',
      features: _.map((f) => f.toJSON, this.allFeaturesArray),
    };
  }

  get pathsGeoJSON() {
    return {
      type: 'FeatureCollection',
      features: _.flow(
        _.filter((x: EmbedFeatureMobx) => x.entityType === 'paths'),
        _.map((f) => f.toJSON),
      )(this.allFeaturesArray),
    };
  }

  get allFeaturesArray(): EmbedFeatureMobx[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return mapToArray(this.features);
  }

  getFullPinnedArray(mapId: string): {
    markers?: EmbedFeatureMobx[];
    paths?: EmbedFeatureMobx[];
    polygons?: EmbedFeatureMobx[];
  } {
    const pinnedList = this.pinnedList?.[mapId];

    const fullArray = {
      markers: pinnedList?.markers?.length
        ? this.allFeaturesArray.filter((fl) =>
            pinnedList?.markers?.includes(fl.id),
          )
        : [],
      paths: pinnedList?.paths?.length
        ? this.allFeaturesArray.filter((fl) =>
            pinnedList?.paths?.includes(fl.id),
          )
        : [],
      polygons: pinnedList?.polygons?.length
        ? this.allFeaturesArray.filter((fl) =>
            pinnedList?.polygons?.includes(fl.id),
          )
        : [],
    };

    return fullArray;
  }

  getFullBookmarkItems(
    mapId: string,
    listName: string,
  ): {
    markers?: EmbedFeatureMobx[];
    paths?: EmbedFeatureMobx[];
    polygons?: EmbedFeatureMobx[];
  } {
    const bookmarkList = this.bookMarkLists?.[mapId]?.[listName];

    const fullArray = {
      markers: bookmarkList?.markers?.length
        ? this.allFeaturesArray.filter((fl) =>
            bookmarkList?.markers?.includes(fl.id),
          )
        : [],
      paths: bookmarkList?.paths?.length
        ? this.allFeaturesArray.filter((fl) =>
            bookmarkList?.paths?.includes(fl.id),
          )
        : [],
      polygons: bookmarkList?.polygons?.length
        ? this.allFeaturesArray.filter((fl) =>
            bookmarkList?.polygons?.includes(fl.id),
          )
        : [],
    };

    return fullArray;
  }

  onMapLoad =
    (urlViewportData = {}) =>
    (e) => {
      if (!this.mapInstance) return;
      this.viewport = {
        ...this.viewport,
        zoom: this.mapInstance.getZoom(),
        bearing:
          this.map.default_bearing ||
          this.mapInstance?.getBearing() ||
          this.viewport.bearing,
        pitch:
          this.map.default_pitch ||
          this.mapInstance?.getPitch() ||
          this.viewport.pitch,
        ...urlViewportData,
        flatBounds: this.mapInstance?.getBounds().toArray().flat(),
      };

      this.mapInstance?.on('touchstart', () => {
        this.setDisableSliderViewportReactions(false);
      });
      this.setMapReady(true);
    };

  updateMap = (map: IMapData['map']) => {
    this.map = map;
    this.temporaryMap = null;
  };

  updateExternalArea = (data, opacity) => {
    const {
      external_area_color,
      default_bearing,
      default_pitch,
      default_zoom,
    } = data;
    if (!this.temporaryMap) this.temporaryMap = this.map;
    this.map = {
      ...this.map,
      default_bearing,
      default_pitch,
      default_zoom,
      styles: {
        ...this.map.styles,
        external_area_color: external_area_color.substring(0, 4) === 'tran' ? '#00000002'.slice(0, 7) : external_area_color.slice(0, 7),
        external_area_opacity: opacity || this.map.styles.external_area_opacity,
      },
    };
  };

  cancelExternalAreaUpdate = () => {
    if (this.temporaryMap) {
      this.map = {
        ...this.map,
        default_bearing: this.temporaryMap.default_bearing,
        default_pitch: this.temporaryMap.default_pitch,
        default_zoom: this.temporaryMap.default_zoom,
        styles: {
          ...this.map.styles,
          external_area_color: this.temporaryMap.styles.external_area_color,
          external_area_opacity: this.temporaryMap.styles.external_area_opacity,
        },
      };
    }
    this.temporaryMap = null;
  };

  setInitialData = (data, isAlternativeId = false) => {
    this.map = data.map;
    this.isUrlAlternativeId = isAlternativeId;
    this.categories.replace(
      _.map((category) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return [category.id, new EmbedCategoryMobx(this, category)];
      }, _.compact(_.concat(data.categories, localCategory))),
    );

    this.viewport = {
      ...this.viewport,
      ...data.viewport,
    };
    this.ready = true;
  };

  setLocalTime = (date) => {
    this.localTime = date;
  };

  setMapStyle = (mapStyle) => {
    this.map = {
      ...this.map,
      styles: {
        ...this.map.styles,
        base_style_url: mapStyle,
      },
    };
  };

  createCategory = (data: EmbedCategoryMobx) => {
    this.categories.set(data.id, new EmbedCategoryMobx(this, data));
  };

  deleteCategory = (id: string) => {
    if (this.categories.has(id)) this.categories.delete(id);
  };

  setFeatures = (features) => {
    this.features.replace(
      _.map(
        (feature) => [
          feature.properties.id,
          new EmbedFeatureMobx(this, feature),
        ],
        features,
      ),
    );
  };

  mergeFeatures = (features) => {
    this.features.merge(
      _.map(
        (feature) => [
          feature.properties.id,
          new EmbedFeatureMobx(this, feature),
        ],
        features,
      ),
    );
  };

  setSegmentBundles = (segmentBundles) => {
    this.segmentBundles.replace(
      _.map(
        (segmentBundle) => [
          segmentBundle.properties.id,
          new SegmentBundleMobx(this, segmentBundle),
        ],
        segmentBundles,
      ),
    );
  };

  updateFeatureProperties = (values: IProperties) => {
    if (!this.features.has(values.id as string)) return;
    const feature = this.features.get(values.id as string);
    feature.properties = { ...feature.properties, ...values };
  };

  deleteFeature = (id: string) => {
    if (this.features.has(id)) this.features.delete(id);
  };

  setReady = (ready: boolean) => {
    this.ready = ready;
  };

  setMapInstance = (map) => {
    this.mapInstance = map;
  };

  setMapReady = (ready: boolean) => {
    this.mapReady = ready;
  };

  onTouchMove = (e) => {
    if (this.disableSliderViewportReactions) {
      this.setDisableSliderViewportReactions(false);
    }
  };

  setSelectedFeatureById = (featureId, fly = true, openDrawer = false) => {
    if (!this.features.has(featureId)) return;
    const feature = this.features.get(featureId);
    if (this.parent.layout.page.isMobile) {
      this.setSelectedFeatureMobile(feature);
      return;
    } else if (feature.isPoint) {
      this.setSelectedMarker(feature);
    } else {
      this.setSelectedPath(feature);
    }
    this.parent.layout.public.changeFeatureItemAndView(
      'feature.view',
      feature,
      fly,
    );
    if (openDrawer) {
      this.parent.layout.public.updateFeatureDrawerIsOpen(true);
    }
  };

  // there are certain cases where we want to manually trigger the onViewportChangeFunction
  onManualViewportChange = () => {
    if (!this.mapInstance) return;
    const zoom = this.mapInstance.getZoom();
    if (!this.viewport.defaultZoom) {
      this.viewport.defaultZoom = zoom;
    }
    const center = this.mapInstance.getCenter();
    this.onViewportChange({
      zoom: this.mapInstance.getZoom(),
      bearing: this.mapInstance.getBearing(),
      pitch: this.mapInstance.getPitch(),
      longitude: center.lng,
      latitude: center.lat,
    });
  };

  onViewportChange = (data) => {
    if (!this.mapInstance) return;
    let isMobile = true;
    if (typeof window !== 'undefined' && window.innerWidth > 769) {
      isMobile = false;
    }

    if (isMobile) {
      if (
        Date.now() - this.lastSliderListUpdateTimestamp > 400 &&
        !this.disableSliderViewportReactions
      ) {
        this.setActiveFeatureMobile(null);
      } else {
        this.setDisableSliderViewportReactions(false);
      }
    }

    let flatBounds;
    try {
      flatBounds = this.mapInstance.getBounds().toArray().flat();
    } catch {
      // This is some shitty state.. lets just return here
      return;
    }
    this.viewport = toJS({ ...this.viewport, ...data, flatBounds });
  };

  hydrate = (data) => {
    if (!data) return;
  };

  get allTempFeatures() {
    return _.filter(
      (feature) => feature.isLocal && feature.isSupportedGeometryType,
      this.allFeaturesArray,
    );
  }

  get allTempFeaturesSelected() {
    return _.filter((feature) => feature.isVisible, this.allTempFeatures);
  }

  get allTempFeaturesSelectedMappedFeatures() {
    const features = _.filter((feature) => {
      return feature.isVisible;
    }, this.mappedImportFeatures);
    return features;
  }

  get allTempFeaturesSelectedMappedFeaturesGeoJson() {
    const result = {
      type: 'FeatureCollection',
      features: _.flow(
        _.map('toJSON'),
        _.map(_.omit(['id', 'properties.id'])),
      )(this.allTempFeaturesSelectedMappedFeatures),
    };
    return result;
  }

  get allTempFeaturesSelectedGeoJson() {
    return {
      type: 'FeatureCollection',
      features: _.flow(
        _.map('toJSON'),
        _.map(_.omit(['id', 'properties.id'])),
      )(this.allTempFeaturesSelected),
    };
  }

  get allTempFeaturesGeoJson() {
    return {
      type: 'FeatureCollection',
      features: _.flow(_.map('toJSON'))(this.allTempFeatures),
    };
  }

  get allTempFeaturesWithoutIdGeoJson() {
    return {
      type: 'FeatureCollection',
      features: _.flow(
        _.map('toJSON'),
        _.map(_.omit(['id', 'properties.id'])),
      )(this.allTempFeatures),
    };
  }

  get visibleFeatures() {
    return _.filter((feature) => feature.isVisible, this.allFeaturesArray);
  }

  get selectedPaths() {
    return _.filter(
      (feature) => feature.selected && feature.isPath,
      this.allFeaturesArray,
    );
  }

  get selectedPolygons() {
    return _.filter(
      (feature) => feature.selected && feature.isPolygon,
      this.allFeaturesArray,
    );
  }

  get selectedFeatures() {
    return _.filter((feature) => feature.selected, this.allFeaturesArray);
  }

  get selectedCategory() {
    return (
      _.find((category) => category.selected, this.allCategoriesArray) || null
    );
  }

  get isSelectedFeature() {
    return Boolean(this.selectedPath || this.selectedMarker);
  }

  clearSelectedFeature = () => {
    const noKeywordTypeOrCategoryFilterApplied =
      !this.parent.tags.filterSearchQueryItem &&
      !this.parent.tags.selectedFilterItems.category;

    if (noKeywordTypeOrCategoryFilterApplied) {
      this.parent.tags.setFilterSearchQuery('');
    }
    this.setSelectedMarker(null);
    this.setSelectedPath(null);
    this.parent.layout.public.setSelectedFeature(null);
  };

  get numFeaturesSelected() {
    const selected = this.selectedFeatures.length + 1;
    return selected - 1 >= 0 ? selected - 1 : selected;
  }

  get filteredCategories() {
    if (_.size(this.searchTerm) < 2) return this.allCategoriesArray;
    return _.filter(
      (category) =>
        fuzzy(this.searchTerm, category.name) >= FUZZY_SEARCH_THRESHOLD,
      this.allCategoriesArray,
    );
  }

  get filteredFeaturesArray() {
    if (_.size(this.searchTerm) < 2) return this.allFeaturesArray;
    return _.filter(
      (feature) =>
        fuzzy(this.searchTerm, String(feature.properties?.name)) >=
        FUZZY_SEARCH_THRESHOLD,
      this.allFeaturesArray,
    );
  }

  get filteredFeaturesArrayV2Set() {
    return new Set(_.map('id', this.filteredFeaturesArrayV2));
  }

  get filtersApplied() {
    const keywordOrTypeId = this.parent.tags.filterSearchQueryItem?.id;
    const fieldOptionIds = Array.from(this.parent.tags.filterItemIDs);
    const categoryFilter = this.parent.tags.selectedFilterItems.category;
    const shouldFilterHours =
      this.parent.tags.filterHoursOpenFilter !== null ||
      _.size(this.parent.tags.filterHoursSelectedDays) !== 0;
    const shouldFilterByFilters =
      _.size(fieldOptionIds) !== 0 || Boolean(keywordOrTypeId);
    const shouldFilterByCategory = Boolean(categoryFilter);
    return shouldFilterByCategory || shouldFilterByFilters || shouldFilterHours;
  }

  get filteredFeaturesArrayV2() {
    const keywordOrTypeId = this.parent.tags.filterSearchQueryItem?.id;
    const fieldOptionIds = Array.from(this.parent.tags.filterItemIDs);
    const categoryFilter = this.parent.tags.selectedFilterItems.category;
    const selectedList = this.parent.tags.selecetedList;
    const mapId = this.isUrlAlternativeId
      ? this.map?.alternative_id
      : this.map?.id || '';
    const bookmarkLists = this.bookMarkLists?.[mapId];
    const pinnedList = this.getFullPinnedArray(mapId);

    const shouldFilterHours =
      this.parent.tags.filterHoursOpenFilter !== null ||
      _.size(this.parent.tags.filterHoursSelectedDays) !== 0;

    const shouldFilterByFilters =
      _.size(fieldOptionIds) != 0 || Boolean(keywordOrTypeId);

    const filterByHours = (features) => {
      if (!shouldFilterHours) return features;

      const timesOverlap = (range1, range2) => {
        const [min1, max1] = range1.sort();
        const [min2, max2] = range2.sort();
        return min1 < max2 && min2 <= max1;
      };

      if (this.parent.tags.filterHoursOpenFilter === null) {
        const daysDayjs = _.flow(
          _.entries,
          _.filter(([day, selected]) => selected),
          _.map(([day]) => dayjs().day(dayToIndex[day])),
          _.reduce(
            (acc, day: dayjs.Dayjs) => ({ ...acc, [day.day()]: day }),
            {},
          ),
        )(this.parent.tags.filterHoursSelectedDays);

        return _.flow(
          _.filter((feature: any) => {
            const { hours } = feature.properties;
            if (!hours) return true;
            const { periods } = hours;
            if (periods) {
              //check if every day in selectedDays is open from periods
              const [start, end] = this.parent.tags.filterHoursRange;
              const hoursSliderStart = filterHoursToTime[start];
              const hoursSliderEnd = filterHoursToTime[end];
              const daysOpen = _.flow(
                _.filter((period) => {
                  const day = _.get('open.day', period);
                  if (day in daysDayjs) {
                    const openTime = _.get('open.time', period);
                    const closeTime = _.get('close.time', period);

                    return timesOverlap(
                      [hoursSliderStart, hoursSliderEnd],
                      [openTime, closeTime],
                    );
                  }
                  return false;
                }),
              )(periods);

              return _.size(daysOpen) === _.size(daysDayjs);
            }
          }),
        )(features);
      } else {
        const today = dayjs();
        const todaysDay = today.day();
        if (this.parent.tags.filterHoursOpenFilter === '24hours') {
          return _.flow(
            _.filter((feature: any) => {
              const { hours } = feature.properties;
              if (!hours) return true;
              const { periods, custom_hours } = hours;

              if (periods) {
                return _.flow(
                  _.find((period) => {
                    const day = _.get('open.day', period);
                    if (todaysDay === day) {
                      const openTime = _.get('open.time', period);
                      const closeTime = _.get('close.time', period);
                      return openTime === '0000' && closeTime === '0000';
                    }
                    return false;
                  }),
                  Boolean,
                )(periods);
              }
            }),
          )(features);
        }
        if (this.parent.tags.filterHoursOpenFilter === 'now') {
          return _.flow(
            _.filter((feature: any) => {
              const { hours } = feature.properties;
              if (!hours) return true;
              const { periods } = hours;
              if (periods) {
                return _.flow(
                  _.find((period) => {
                    const day = _.get('open.day', period);
                    if (todaysDay === day) {
                      const openTime = _.get('open.time', period);
                      const closeTime = _.get('close.time', period);
                      //check if today is in between open and close time
                      const nowTime = parseInt(today.format('HHmm'), 10);
                      return (
                        nowTime >= parseInt(openTime, 10) &&
                        nowTime <= parseInt(closeTime, 10)
                      );
                    }
                    return false;
                  }),
                  Boolean,
                )(periods);
              }
            }),
          )(features);
        }
      }
    };

    const filterByKeywordOrType = (features) =>
      features && keywordOrTypeId
        ? _.filter(
            (feature: EmbedFeatureMobx) =>
              feature.tagItemsSet.has(keywordOrTypeId),
            features,
          )
        : features;

    const filterByFieldOptions = (features) =>
      features && _.size(fieldOptionIds) === 0
        ? features
        : _.filter(
            (feature: EmbedFeatureMobx) =>
              Boolean(
                fieldOptionIds.length &&
                  _.some((id) => feature.tagItemsSet.has(id), fieldOptionIds),
              ),
            features,
          );
    const filterByFilters = _.flow(filterByKeywordOrType, filterByFieldOptions);
    const filterByCategory = _.filter(
      (feature: EmbedFeatureMobx) => feature.category.id === categoryFilter.id,
    );

    const addPinnedItems = (features) =>
      features && pinnedList && Object.values(pinnedList).flat().length
        ? [...features, ...Object.values(pinnedList).flat()]
        : features;

    const filterBySelectedList = (features) =>
      features && selectedList
        ? _.filter(
            (feature: EmbedFeatureMobx) =>
              _.values(_.get(selectedList, bookmarkLists))
                .flat()
                .includes(feature.id),
            features,
          )
        : features;

    const sortByOrder = _.sortBy('properties.sort_order');
    const sortByName = _.sortBy('properties.name');

    const flowFns = [];

    if (selectedList) {
      flowFns.push(filterBySelectedList);
    }
    if (shouldFilterHours) {
      flowFns.push(filterByHours);
    }
    if (shouldFilterByFilters) {
      flowFns.push(filterByFilters);
    }
    if (categoryFilter) {
      flowFns.push(filterByCategory);
    }
    if (categoryFilter && !shouldFilterByFilters) {
      flowFns.push(sortByOrder);
    } else if (flowFns.length) {
      flowFns.push(sortByName);
    }

    if (!selectedList) {
      return flowFns.length
        ? addPinnedItems(_.flow(...flowFns)(this.allFeaturesArray))
        : [];
    }
    return flowFns.length ? _.flow(...flowFns)(this.allFeaturesArray) : [];
  }

  get listedByDefaultFeatures() {
    if (this.filtersApplied) return [];
    return _.flow(
      _.filter(
        (feature: EmbedFeatureMobx) => feature?.category?.listed_by_default,
      ),
      _.sortBy('name'),
    )(this.allFeaturesArray);
  }

  get listedByDefaultFeaturesSet() {
    return new Set(_.map('id', this.listedByDefaultFeatures));
  }

  updateFeatureVisibilities = (
    visibleFeaturesSet = new Set(_.map(_.get('id'), this.allFeaturesArray)),
  ) => {
    if (this.selectedPath && !visibleFeaturesSet.has(this.selectedPath.id)) {
      this.setSelectedPath(null);
    } else if (
      this.selectedMarker &&
      !visibleFeaturesSet.has(this.selectedMarker.id)
    ) {
      this.setSelectedMarker(null);
    }
    _.each((feature: EmbedFeatureMobx) => {
      feature.isVisible = visibleFeaturesSet.has(feature.id);
    }, this.allFeaturesArray);
  };

  get maskGeoJSON() {
    if (!this.ready || this.map?.area_coordinates === null) return null;
    return maskGeoJson(this.map.area_coordinates.coordinates);
  }

  get zmaskPaint() {
    if (!this.ready) return null;
    return {
      'fill-color': this.map.styles.external_area_color || 'white',
      'fill-opacity': Number(this.map.styles.external_area_opacity) ?? 0.4,
    };
  }

  setAreaCoordinates = (area_coordinates) => {
    this.map = {
      ...this.map,
      area_coordinates: {
        type: 'Polygon',
        coordinates: area_coordinates,
      },
    };
  };

  addFeature = async (data, handleModify = true, flyTo = false) => {
    const feature = new EmbedFeatureMobx(this, data);
    if (feature.isPoint && !feature.properties.address) {
      const address = await feature.getAddressForCoords();
      if (address) {
        feature.setAddress(address);
      }
    }
    this.features.set(data.properties.id, feature);
    if (handleModify) {
      feature.handleModifyFeature();
    }
    if (flyTo) {
      feature.flyTo();
    }
    return feature;
  };

  updateFeature = (id, feature) => {
    this.features.set(id, feature);
  };

  removeFeature = (id) => {
    this.features.delete(id);
  };

  replaceFeature = (localId, feature) => {
    this.features.set(feature.id, feature);
    this.features.delete(localId);
  };

  convertApiResponseToFeature(
    data,
    entityType: 'markers' | 'paths' | 'polygons',
  ) {
    switch (entityType) {
      case 'markers': {
        const { point, ...rest } = data.marker;
        return new EmbedFeatureMobx(this, {
          type: 'Feature',
          geometry: JSON.parse(point),
          properties: { ...rest },
        });
      }
      case 'paths': {
        const { coordinates, ...rest } = data.path;
        return new EmbedFeatureMobx(this, {
          type: 'Feature',
          geometry: JSON.parse(coordinates),
          properties: { ...rest },
        });
      }
      case 'polygons': {
        const { coordinates, ...rest } = data.polygon;
        return new EmbedFeatureMobx(this, {
          type: 'Feature',
          geometry: JSON.parse(coordinates),
          properties: { ...rest },
        });
      }
    }
  }

  // Huge issues here @performance
  get mappedImportFeatures(): EmbedFeatureMobx[] {
    const features: EmbedFeatureMobx[] = _.map((feature: EmbedFeatureMobx) => {
      let description = '';
      if (this.importPropertyMapping.description.combineFields.length) {
        description = _.reduce(
          (acc, curr) => {
            if (feature.properties[curr]) {
              return `${acc}

                ${feature.properties[curr]}`;
            } else {
              return acc;
            }
          },
          '',
          this.importPropertyMapping.description.combineFields,
        );
      } else if (
        feature.properties[this.importPropertyMapping.description.targetField]
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        description =
          feature.properties[
            this.importPropertyMapping.description.targetField
          ];
      }
      // address
      let address = '';
      if (this.importPropertyMapping.address.combineFields.length) {
        address = _.reduce(
          (acc, curr) => {
            if (feature.properties[curr]) {
              return `${acc} ${feature.properties[curr]}`;
            } else {
              return acc;
            }
          },
          '',
          this.importPropertyMapping.address.combineFields,
        );
      } else if (
        feature.properties[this.importPropertyMapping.address.targetField]
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        address =
          feature.properties[this.importPropertyMapping.address.targetField];
      }
      if (!address) {
        address = undefined;
      }

      // photos
      let photos: string[] = [];
      if (this.importPropertyMapping.photos.combineFields.length) {
        photos = _.reduce(
          (acc, curr) => {
            if (feature.properties[curr]) {
              if (Array.isArray(feature.properties[curr])) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return [...acc, ...feature.properties[curr]];
              } else if (
                typeof feature.properties[curr] === 'string' ||
                feature.properties[curr] instanceof String
              ) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (feature.properties[curr].includes('http')) {
                  return [...acc, feature.properties[curr]];
                } else {
                  this.setImportLogs(
                    `${
                      feature.properties[
                        this.importPropertyMapping.name.targetField
                      ]
                    } photo property '${curr}' with value: ${
                      feature.properties[curr]
                    } is not a valid string.`,
                  );
                  return acc;
                }
              } else {
                this.setImportLogs(
                  `${
                    feature.properties[
                      this.importPropertyMapping.name.targetField
                    ]
                  } photo property '${curr}' with value: ${
                    feature.properties[curr]
                  } is not of type string or array.`,
                );
                return acc;
              }
            } else {
              return acc;
            }
          },
          [],
          this.importPropertyMapping.photos.combineFields,
        );
      } else if (
        feature.properties[this.importPropertyMapping.photos.targetField]
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        photos =
          feature.properties[this.importPropertyMapping.photos.targetField];
      }

      // metadata
      let metadata = {};
      if (this.importPropertyMapping.metadata.combineFields.length) {
        metadata = _.reduce(
          (acc, curr) => {
            if (feature.properties[curr]) {
              return { ...acc, [curr]: feature.properties[curr] };
            } else {
              return acc;
            }
          },
          {},
          this.importPropertyMapping.metadata.combineFields,
        );
      } else if (
        feature.properties[this.importPropertyMapping.metadata.targetField]
      ) {
        metadata = {
          [this.importPropertyMapping.metadata.targetField]:
            feature.properties[this.importPropertyMapping.metadata.targetField],
        };
      }
      const final = {
        ...feature,
        properties: {
          ...feature.properties,
          name: feature.properties[this.importPropertyMapping.name.targetField],
          website:
            feature.properties[this.importPropertyMapping.website.targetField],
          phone:
            feature.properties[this.importPropertyMapping.phone.targetField],
          hours:
            feature.properties[this.importPropertyMapping.hours.targetField],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          address,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          photos,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          description,
          google_place_id:
            feature.properties[
              this.importPropertyMapping.google_place_id.targetField
            ],
          longitude:
            feature.properties[
              this.importPropertyMapping.longitude.targetField
            ],
          latitude:
            feature.properties[this.importPropertyMapping.latitude.targetField],
          coordinates:
            feature.properties[
              this.importPropertyMapping.coordinates.targetField
            ],
          metadata,
        },
      };
      return new EmbedFeatureMobx(this, final);
    }, this.allTempFeatures);
    return features;
  }

  setCategoryIcons = (icons) => {
    this.categoryIcons = icons;
  };

  setGeolocatorInstance = (geolocator) => {
    this.geolocatorInstance = geolocator;
  };

  setGeolocatorInstanceStatus = (status: GeolocatorInstanceStatus) => {
    if (status === 'ready') {
      const device = useMediaQueries();
      mixpanel.track(
        `${device.isDesktop ? 'Desktop' : 'Mobile'}: gps button click`,
        {
          mapId: this.mapInstance?.id,
        },
      );
    }
    this.geolocatorInstanceStatus = status;
  };

  get trackingData() {
    return {
      ...(this.map?.id ? { mapId: this.map.id } : {}),
      mapName: this.map?.name,
      organizationId: this.parent.user?.claims?.publisher?.id,
      userId: this.parent.user?.claims?.user_id,
    };
  }

  constructor(parent: StoreMobx) {
    this.parent = parent;
    makeAutoObservable(this, {
      layerRef: false,
      mapInstance: false,
      geolocatorInstance: false,
    });
    // need to get working with leading, and trailing
    // this.onViewportChange = _.throttle(500, this.onViewportChange);
  }
}

const dayToIndex = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

const filterHoursToTime = {
  0: '0000',
  1: '0100',
  2: '0200',
  3: '0300',
  4: '0400',
  5: '0500',
  6: '0600',
  7: '0700',
  8: '0800',
  9: '0900',
  10: '1000',
  11: '1100',
  12: '1200',
  13: '1300',
  14: '1400',
  15: '1500',
  16: '1600',
  17: '1700',
  18: '1800',
  19: '1900',
  20: '2000',
  21: '2100',
  22: '2200',
  23: '2300',
  24: '2400',
};
