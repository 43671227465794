export enum PatchUserListPOIType {
  Marker = 'marker',
  Polygon = 'polygon',
  Path = 'path',
}

export enum PatchUserListPOIAction {
  Add = 'add',
  Remove = 'remove',
}

export interface IPatchUserListPOIs {
  type: PatchUserListPOIType;
  poi_id: string;
  operation: PatchUserListPOIAction;
}
