import {
  isTablet,
  isMobile,
  isBrowser,
  isMobileOnly,
} from 'react-device-detect';

export interface IUseMediaQueries {
  isMobile: boolean;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  [Symbol.iterator]: () => Generator<unknown, void, unknown>;
}

export const useMediaQueries = () => {
  return {
    isMobile,
    isPhone: isMobileOnly,
    isTablet,
    isDesktop: isBrowser,
    isBrowser,
    [Symbol.iterator]: function* () {
      const properties = Object.keys(this);
      for (const i of properties) {
        yield this[i];
      }
    },
  };
};
