import * as Sentry from '@sentry/react';
import _ from 'lodash/fp';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import env from '../../env';
import { useStore } from '../../hooks';
import useLogger from '../../hooks/useLogger';

export const ErrorBoundary = observer(
  ({
    source,
    showDialog = env.runtime.isProduction,
    children,
    beforeCapture = _.noop,
  }) => {
    const logger = useLogger(`ErrorBoundary:${source}`);
    const store = useStore();
    const router = useRouter();
    const hasClaims = store.user && store.user.claims;
    const logFatal = () => {
      if (!env.runtime.isProduction && hasClaims) {
        logger('router.pathname', router.pathname);
        if (store.user.claims) {
          logger('user.user_id', store.user.claims.user_id);
          logger('user.name', store.user.claims.name);
        }
      }
    };

    return (
      <Sentry.ErrorBoundary
        showDialog={showDialog}
        beforeCapture={(scope) => {
          logFatal();
          beforeCapture(scope);
          try {
            scope.setTag('router.pathname', router.pathname);
            if (hasClaims) {
              scope.setTag('user.user_id', store.user.claims.user_id);
              scope.setTag('user.name', store.user.claims.name);
            }
          } catch {
            // ..
          }
        }}>
        {children}
      </Sentry.ErrorBoundary>
    );
  },
);
