import _ from 'lodash/fp';
import { makeAutoObservable } from 'mobx';
import { bbox } from '@turf/turf';
import { EmbedCategoryMobx } from './embedCategory.mobx';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { StoreMobx } from './store.mobx';
import { getLocalID } from './mobx.utils';
import { parseAndMergeMarkersAndFeatures } from '../util/mapGeoJSON';
import mixpanel from '../util/mixpanel';

type DrawMode =
  | 'simple_select'
  | 'draw_line_string'
  | 'draw_polygon'
  | 'draw_point'
  | 'direct_select'
  | 'static';

type MapboxControlPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

const stubEvent = (name: string) => null;

type GeoJSON = {
  type: string;
  features: any[];
};

/**
 * DrawMobx - will control the drawing state of the builder page
 */
export class DrawMobx {
  data: GeoJSON = { type: 'FeatureCollection', features: [] };
  currentCategory?: EmbedCategoryMobx;
  editFeatureId: string;
  drawInstance = {};
  editPathStartEndMarkerMode: null | 'start' | 'end' = null;

  setEditPathStartEndMarkerMode = (value: null | 'start' | 'end') => {
    this.editPathStartEndMarkerMode = value;
  };

  /**
   * The mode that will used.
   */
  mode: DrawMode = 'simple_select';

  /**
   * The position of the control
   */
  position: MapboxControlPosition = 'top-left';

  /**
   * The mode options.
   */
  modeOptions = {};

  clearData = () => {
    this.data = {
      type: 'FeatureCollection',
      features: [],
    };
  };

  /**
   * Fired when features are changed.
   * (draw.create, draw.delete, draw.combine, draw.uncombine, draw.update).
   */
  // onChange = stubEvent('onChange');

  /**
   * Fired when a feature is created. The following interactions will trigger this event:
   * • Finish drawing a feature. Simply clicking will create a Point.
   *   A LineString or Polygon is only created when the user has finished drawing it
   *   — i.e. double-clicked the last vertex or hit Enter — and the drawn feature is valid.
   */
  onDrawCreate = (data) => {
    if (
      this.parent.layout.builder.sidebar.includes('new.map') ||
      this.parent.layout.builder.sidebar.includes('edit.map')
    ) {
      this.parent.layout.builder.setLocalMapCoordinates(
        data.features[0]?.geometry.coordinates,
      );
      this.parent.layout.builder.setIsDrawFinal(true);
      this.data.features = data.features;
      return;
    }
    const categoryId = this.currentCategory?.id;
    if (!categoryId) {
      console.warn('Unable to create feature. categoryId is bad...');
      return;
    }
    if (data.features[0]?.type === 'Feature') {
      const message =
        data.features[0]?.geometry.type === 'LineString'
          ? 'Manually Add Path - finish drawing (draw.onCreate is triggered)'
          : data.features[0]?.geometry.type === 'Point'
          ? 'Manually Add Marker Map Click (placing marker)'
          : data.features[0]?.geometry.type === 'Point'
          ? 'Manually Add Polygon Map Click (placing polygon)'
          : null;
      if (message) {
        mixpanel.track(message, {
          ...this.currentCategory.trackingData,
        });
      }
    }

    const id = getLocalID();
    const feature = _.get('features[0]', data);
    this.parent.embed.addFeature({
      ..._.omit(['id'], feature),
      properties: {
        id,
        ...feature.properties,
        name: `Unnamed ${feature.geometry.type} ${this.localId}`,
        category_id: categoryId,
        hours: null,
      },
    });
    this.parent.embed.onManualViewportChange(); // to trigger categories re-render
    this.mode = 'simple_select';
    this.modeOptions = {};
  };

  /**
   * Fired when one or more features are deleted. The following interactions will trigger this event:
   * • Click the trash button when one or more features are selected in simple_select mode.
   * • Hit the Backspace or Delete keys when one or features are selected in simple_select mode.
   * • Invoke draw.trash() when you have a feature selected in simple_select mode.
   */
  onDrawDelete = stubEvent('onDrawDelete');

  /**
   * Fired when the mode is changed. The following interactions will trigger this event:
   * • Click the point, line, or polygon buttons to begin drawing (enter a draw_* mode).
   * • Finish drawing a feature (enter simple_select mode).
   * • While in simple_select mode, click on an already selected feature (enter direct_select mode).
   * • While in direct_select mode, click outside all features (enter simple_select mode).
   * This event is fired just after the current mode stops and just before the next mode starts.
   * A render will not happen until after all event handlers have been triggered,
   * so you can force a mode redirect by calling draw.changeMode() inside a draw.modechange handler.
   */
  onDrawModeChange = (e) => {
    if (e.mode !== this.mode) {
      this.toggleModeTo(e.mode);
    }
  };

  onPasteCoordinates = (coordinates = []) => {
    this.data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          id: 'map_boundaries',
          geometry: {
            type: 'Polygon',
            coordinates,
          },
        },
      ],
    };
  };

  onPathEdit = (feature: EmbedFeatureMobx) => {
    this.modeOptions = { featureId: feature.id };
    this.mode = 'direct_select';
    this.data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          id: feature.id,
          geometry: {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
          },
        },
      ],
    };
  };

  /**
   * Fired just after Draw calls setData() on the Mapbox GL JS map.
   * This does not imply that the set data call has finished updating the map, just that the map is being updated.
   */
  onDrawRender = stubEvent('onDrawRender');

  /**
   * Fired when the selection is changed (i.e. one or more features are selected or deselected).
   * The following interactions will trigger this event:
   * • Click on a feature to select it.
   * • When a feature is already selected, shift-click on another feature to add it to the selection.
   * • Click on a vertex to select it. • When a vertex is already selected,shift-click on another vertex to add it to the selection.
   * • Create a box-selection that includes at least one feature.
   * • Click outside the selected feature(s) to deselect.
   * • Click away from the selected vertex(s) to deselect.
   * • Finish drawing a feature (features are selected just after they are created).
   * • When a feature is already selected, invoke draw.changeMode() such that the feature becomes deselected.
   * • Use draw.changeMode('simple_select', { featureIds: [..] }) to switch to
   *   simple_select mode and immediately select the specified features.
   * • Use draw.delete, draw.deleteAll or draw.trash to delete feature(s).
   */

  // onDrawSelectionChange = stubEvent('onDrawSelectionChange');

  /**
   * Fired when one or more features are updated. The following interactions will trigger this event,
   * which can be subcategorized by action:
   * • action: 'move'
   * • Finish moving one or more selected features in simple_select mode. The event will
   *   only fire when the movement is finished (i.e. when the user releases the mouse button or hits Enter).
   * • action: 'change_coordinates'
   * • Finish moving one or more vertices of a selected feature in direct_select mode.
   *   The event will only fire when the movement is finished (i.e. when the user
   *   releases the mouse button or hits Enter, or her mouse leaves the map container).
   * • Delete one or more vertices of a selected feature in direct_select mode,
   *   which can be done by hitting the Backspace or Delete keys, clicking the Trash button,
   *   or invoking draw.trash().
   * • Add a vertex to the selected feature by clicking a midpoint on that feature in direct_select mode.
   *   This event will not fire when a feature is created or deleted. To track those interactions,
   *   listen for draw.create and draw.delete events.
   */
  onDrawUpdate = ({
    action,
    features,
  }: {
    action: 'move' | 'change_coordinates';
    features: EmbedFeatureMobx[];
  }) => {
    if (
      this.parent.layout.builder.sidebar.includes('new.map') ||
      this.parent.layout.builder.sidebar.includes('edit.map.settings')
    ) {
      this.parent.layout.builder.setLocalMapCoordinates(
        features[0]?.geometry.coordinates,
      );
      this.data.features = features;
      return;
    }
    _.each((feature) => {
      // if (!this.parent.embed.features.has(feature.id)) return;
      this.parent.embed.features.get(feature.id).geometry.coordinates =
        feature.geometry.coordinates;
    }, features);
  };

  /**
   * GeoJson data
   */
  get props() {
    return {
      mode: this.mode,
      position: this.position,
      modeOptions: this.modeOptions,
      onDrawCreate: this.onDrawCreate,
      onDrawDelete: this.onDrawDelete,
      onDrawUpdate: this.onDrawUpdate,
      onDrawModeChange: this.onDrawModeChange,
      data: this.data,
    };
  }

  toggleModeTo = (mode: DrawMode, category: EmbedCategoryMobx = null) => {
    this.modeOptions = {};
    this.currentCategory = category;
    if (mode === 'direct_select') {
      this.modeOptions = {
        ...this.modeOptions,
        featureId: this.data.features[0]?.id || 'map_boundaries',
      };
    }
    this.mode = mode;
    if (
      mode === 'draw_point' ||
      mode === 'draw_line_string' ||
      mode === 'draw_polygon'
    ) {
      this.parent.embed.mapInstance.getCanvas().cursor = 'crosshair';
    } else {
      this.parent.embed.mapInstance.getCanvas().cursor = 'grab';
    }
    console.info(`changed mode to ${mode}`);
  };

  setAllTempFeaturesVisible = (to: boolean) => {
    _.each((feature) => {
      this.parent.embed.features.get(feature.id).isVisible = to;
    }, this.parent.embed.allTempFeatures);
  };

  addTempFeatures = (features) => {
    const counterMap = new Map();
    const getFeatureNameValue = (feature) => {
      const type = feature.geometry.type;
      if (counterMap.has(type)) {
        counterMap.set(type, counterMap.get(type) + 1);
      } else {
        counterMap.set(type, 1);
      }
      return counterMap.get(type);
    };
    const nextFeatures = _.map((feature) => {
      const id = getLocalID();
      const nextObj = {
        geometry: { ...feature.geometry },
        properties: {
          ...feature.properties,
          category_id: this.parent.layout.builder.selectedCategory.id,
          id,
        },
        isVisible: true,
      };
      if (!nextObj.properties.name) {
        nextObj.properties.name = `${
          nextObj.geometry.type
        } ${getFeatureNameValue(feature)}`;
      }
      return [id, new EmbedFeatureMobx(this.parent.embed, nextObj)];
    }, features);
    this.clearTempFeatures();
    this.parent.embed.features.merge(nextFeatures);
    this.parent.embed.mapInstance.fitBounds(
      bbox(this.parent.embed.allTempFeaturesGeoJson),
      { padding: 20 },
    );
  };

  updateTempFeatures = () => {
    const result = {
      type: 'FeatureCollection',
      features: _.flow(
        _.map('toJSON'),
        _.map(_.omit(['id'])),
      )(this.parent.embed.mappedImportFeatures),
    };
    const nextFeatures = _.map((feature) => {
      const nextObj = {
        ...feature,
        properties: {
          ...feature.properties,
          category_id: this.parent.layout.builder.selectedCategory.id,
        },
        isVisible: this.parent.embed.features.get(feature.properties.id)
          .isVisible,
      };
      return [
        nextObj.properties.id,
        new EmbedFeatureMobx(this.parent.embed, nextObj),
      ];
    }, result.features);
    this.clearTempFeatures();
    this.parent.embed.features.merge(nextFeatures);
  };

  saveTempFeatures = (features) => {
    const newFeatures = parseAndMergeMarkersAndFeatures(features);
    const nextFeatures = _.map(
      (feature) => [
        feature.properties.id,
        new EmbedFeatureMobx(this.parent.embed, feature),
      ],
      newFeatures,
    );
    this.clearTempFeatures();
    this.parent.embed.features.merge(nextFeatures);
  };

  clearTempFeatures = () => {
    _.each((feature) => {
      this.parent.embed.features.delete(feature.id);
    }, this.parent.embed.allTempFeatures);
  };

  _id = 0;
  get localId() {
    return ++this._id;
  }

  parent: StoreMobx;
  logger: debug.Debugger;
  constructor(parent: StoreMobx) {
    this.parent = parent;
    this.logger = parent.extendLogger(DrawMobx.name);
    makeAutoObservable(this, { _id: false, logger: false });
  }
}
