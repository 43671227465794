import { useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';

export type IPublisherUser = {
  firebase_id: string;
  name: string;
  email: string;
};

export type IInviteUser = {
  anonymous_firebase_id: string;
  completed: boolean;
  created_at: string;
  email: string;
  id: string;
  name: string;
  organization_id: string;
  updated_at: string;
};

export const useDialogValues = <T extends IPublisherUser | IInviteUser>() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const currentSelectedRef = useRef<T | null>(null);

  const customOnOpen = (value: T) => {
    currentSelectedRef.current = value;
    onOpen();
  };

  return {
    isOpen,
    onOpen: customOnOpen,
    onClose,
    cancelRef,
    currentSelectedRef,
  };
};
