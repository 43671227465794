import _ from 'lodash/fp';
import { computed, makeAutoObservable, observable } from 'mobx';
import { fuzzy } from 'fast-fuzzy';
import { bbox } from '@turf/turf';
import { FUZZY_SEARCH_THRESHOLD } from './mobx.utils';
import { ICategory } from './ImapData';
import { EmbedMobx } from './embed.mobx';
import { applyParams } from './mobx.utils';
import validate from '../util/validators';
import { EmbedFeatureMobx } from './embedFeature.mobx';
import { TextAnchor } from '@wander-app/shared';

export class EmbedCategoryMobx implements ICategory {
  parent: EmbedMobx;
  selected = false;
  collapsed = false;
  isVisible = true;
  clusteredPoints = observable.map<string, boolean>();
  created_at;
  
  constructor(parent: EmbedMobx, data: ICategory) {
    this.parent = parent;
    applyParams(this, _.pick(Object.keys(this), data));
    makeAutoObservable(this);
    if (!_.isNil(this.categoryQueryParam)) {
      this.handleQueryParamChange();
      return;
    }
    this.isVisible = data.default_selected ?? false;
  }

  get categoryQueryParam() {
    return this.parent.parent.queryParams.params.category;
  }

  get trackingData() {
    return {
      ...this.parent.trackingData,
      categoryId: this.id,
      categoryName: this.name,
    };
  }

  handleQueryParamChange = () => {
    this.isVisible = this.categoryQueryParam === this.id;
    if (!this.isVisible) return;
    this.parent.parent.layout.public.changeCategoryItemAndView(
      'category.full.view',
      this,
    );
  };

  fitBounds = () => {
    this.parent.mapInstance.fitBounds(bbox(this.allFeaturesGeoJSON), {
      padding: 20,
      speed: 4,
    });
  };

  setFeaturesSelectedTo = (to: boolean) => {
    this.selected = to;
    _.each((ele) => {
      ele.selected = to;
    }, this.filteredFeatures);
  };

  toFormState() {
    const vals = _.omit(
      [
        'parent',
        'selected',
        'collapsed',
        'isVisible',
        'sort_order',
        'marker_label_halo_width',
        'setItemsChecked',
        'setVisibility',
        'toggleCollapsed',
        'toggleSelected',
        'toggleVisibility',
        'types',
        'updateFromServer',
        'allFeaturesSelected',
        'allFeatures',
        'allVisible',
        'filteredFeatures',
        'markers',
        'paths',
        'modifyMode',
        'clusteredPoints',
      ],
      this,
    );
    return { ...vals };
  }

  updateFromServer = (data: Record<string, unknown>) => {
    // TODO: create better apply function
    applyParams(this, data);
  };

  toggleSelected = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selected = !this.selected;
  };

  toggleCollapsed = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selected = !this.selected;
  };

  setVisibility = (visible: boolean) => {
    this.isVisible = visible;
    _.each((feature) => {
      feature.isVisible = visible;
    }, this.allFeatures);
  };

  toggleVisibility = () => {
    const newVisibility = !this.isVisible;
    this.isVisible = newVisibility;
    _.each((feature) => {
      feature.isVisible = newVisibility;
    }, this.allFeatures);
  };

  @computed get allVisiblePathIds() {
    return this.allFeatures
      .filter((f) => f.isVisible && f.isPath)
      .map((f) => f.id);
  }

  @computed get allVisiblePolygonIds() {
    return this.allFeatures
      .filter((f) => f.isVisible && f.isPolygon)
      .map((f) => f.id);
  }

  get isZoomed() {
    return this.parent.viewport.zoom >= this.marker_transition_zoom;
  }

  get someFeaturesSelected() {
    return (
      this.filteredFeaturesArray.some((f) => f.selected) &&
      !this.allFeaturesSelected
    );
  }

  get allFeaturesSelected() {
    return this.filteredFeaturesArray.every((f) => f.selected);
  }

  get filteredFeaturesArray() {
    if (_.size(this.parent.searchTerm) < 2) return this.allFeatures;
    return _.filter(
      (feature) =>
        fuzzy(this.parent.searchTerm, String(feature.properties?.name)) >=
        FUZZY_SEARCH_THRESHOLD,
      this.allFeatures,
    );
  }

  get allVisible() {
    return this.allFeatures.every((f) => f.isVisible);
  }

  get visibleMarkers() {
    return this.allFeatures.filter(
      (feature) => feature.geometry.type === 'Point' && feature.isVisible,
    );
  }

  get allClusteredMarkerIds() {
    return _.reduce(
      (acc, feature) => {
        if (feature.geometry.type !== 'Point') {
          return acc;
        }
        if (feature.isClustered) {
          return [...acc, feature.id];
        } else {
          return acc;
        }
      },
      [],
      this.allFeatures,
    );
  }

  get paths() {
    return _.filter(
      // TODO make this more explicit
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (feature) => feature.geometry.type !== 'Point',
      this.allFeatures,
    );
  }

  get allFeatures() {
    const filteredFeatures = _.filter(
      (feature) => String(feature.properties.category_id) === this.id,
      this.parent.allFeaturesArray,
    );
    return _.sortBy(
      'properties.sort_order',
      filteredFeatures,
    ) as EmbedFeatureMobx[];
  }

  get allFeaturesGeoJSON() {
    return {
      type: 'FeatureCollection',
      features: _.flow(_.map('toJSON'))(this.allFeatures),
    };
  }

  get markers() {
    return _.filter(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (feature) => feature.geometry.type === 'Point',
      this.allFeatures,
    );
  }

  get filteredFeatures() {
    if (_.isEmpty(this.parent.searchTerm)) return this.allFeatures;
    return (
      _.filter(
        (feature) =>
          fuzzy(this.parent.searchTerm, String(feature.properties?.name)) >=
          FUZZY_SEARCH_THRESHOLD,
        this.allFeatures,
      ) || []
    );
  }

  get lineLabelLayoutStyle() {
    return {
      'text-field': ['get', 'name'],
      'symbol-placement': this.path_label_symbol_placement || 'line',
      'text-keep-upright': this.path_label_text_keep_upright || true,
      'text-letter-spacing': validate.toNumberOr(
        this.path_label_text_letter_spacing,
        0.05,
      ),
      'text-padding': validate.toNumberOr(this.path_label_text_padding, 0),
      'text-size': validate.toNumberOr(this.path_label_text_size, 14),
      'symbol-spacing': validate.toNumberOr(this.path_label_symbol_spacing, 80),
      'text-max-width': this.path_label_text_max_width || 50,
      'text-font': this.path_label_text_font || ['Arial Unicode MS Regular'],
      'text-offset': this.path_label_text_offset || [0, 0],
      'text-justify': this.path_label_text_justify || 'center',
      'text-anchor': this.path_label_text_anchor || 'center',
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-transform': this.path_label_text_transform || 'none',
      'symbol-z-order': this.path_label_symbol_z_order || 'auto',
      'text-pitch-alignment': this.path_label_text_pitch_alignment || 'auto',
      visibility: this.isVisible ? 'visible' : 'none',
    };
  }

  get lineLabelPaintStyle() {
    const highlightedPath =
      this.parent.selectedPath ||
      this.parent.hoveredPath ||
      this.parent.activeFeatureMobile;
    return {
      'text-halo-blur': validate.toNumberOr(
        this.path_label_text_halo_blur,
        0.5,
      ),
      'text-halo-width': validate.toNumberOr(this.path_label_halo_width, 2),
      'text-color': this.path_label_color || this.color,
      'text-halo-color': this.path_label_halo_color || this.color,
      'text-translate': this.path_label_text_translate || [0, 0],
      'text-opacity': [
        'case',
        [
          'all',
          ['==', ['get', 'id'], highlightedPath ? highlightedPath.id : 'none'],
          ['literal', this.isVisible],
        ],
        0.01,
        validate.toNumberOr(this.path_line_opacity, 1),
      ],
    };
  }

  get lineLayoutStyle() {
    return {
      'line-join': this.path_line_join || 'round',
      'line-cap': this.path_line_cap || 'round',
      'line-round-limit': validate.toNumberOr(this.path_line_round_limit, 1.05),
      'line-miter-limit': validate.toNumberOr(this.path_line_miter_limit, 0),
      visibility: this.isVisible ? 'visible' : 'none',
      ...(this.path_line_sort_key
        ? { 'line-sort-key': validate.toNumberOr(this.path_line_sort_key, 0) }
        : {}),
    };
  }

  get linePaintStyle() {
    return {
      'line-color': this.path_line_color ? this.path_line_color : this.color, // catches empty string path_line_color as falsey
      'line-width': validate.toNumberOr(this.path_line_width, 2),
      'line-opacity': validate.toNumberOr(this.path_line_opacity, 1),
      'line-blur': validate.toNumberOr(this.path_line_blur),
      'line-translate': this.path_line_translate ?? [0, 0],
      'line-offset': validate.toNumberOr(this.path_line_offset),
      'line-gap-width': validate.toNumberOr(this.path_line_gap_width),
      // // default in the db for line-translate-anchor should be "map" not "auto", this was a mistake by me (AJ)
      'line-translate-anchor':
        this.path_line_translate_anchor === 'auto' ||
        !this.path_line_translate_anchor
          ? 'map'
          : this.path_line_translate_anchor,
      ...(this.path_line_gradient
        ? { 'line-gradient': this.path_line_gradient }
        : {}),
      ...(this.path_line_pattern
        ? { 'line-pattern': this.path_line_pattern }
        : {}),
    };
  }

  get linePaddingLayoutStyle() {
    const style = {
      'line-join': this.path_line_join || 'round',
      'line-cap': this.path_line_cap || 'round',
      'line-round-limit': Number(this.path_line_round_limit || 1.05),
      'line-miter-limit': Number(this.path_line_miter_limit || 0),

      ...(this.path_line_sort_key
        ? { 'line-sort-key': Number(this.path_line_sort_key) }
        : {}),
    };
    return style;
  }

  get linePaddingPaintStyle() {
    const style = {
      'line-color': this.path_line_color || this.color,
      'line-width': Number(this.path_line_width || 2) + 14,
      'line-opacity': 0.01,
      'line-blur': Number(this.path_line_blur || 0),
      'line-translate': this.path_line_translate ?? [0, 0],
      'line-offset': Number(this.path_line_offset) ?? 0,
      'line-gap-width': Number(this.path_line_gap_width) ?? 0,
      'line-translate-anchor':
        this.path_line_translate_anchor === 'auto' ||
        !this.path_line_translate_anchor
          ? 'map'
          : this.path_line_translate_anchor,
    };
    return style;
  }

  get polygonStyle() {
    const opacity = parseFloat(this.poly_fill_opacity as unknown as string);

    const style = {
      'fill-translate-anchor': this.poly_fill_translate_anchor || 'map',
      'fill-translate': this.poly_fill_translate || [0, 0],
      'fill-outline-color': this.poly_fill_outline_color || '#3a3a3a', // default to dark gray
      'fill-opacity': opacity || 0.4, // slightly transparent
      'fill-color': this.poly_fill_color || this.color,
      'fill-antialias': this.poly_fill_antialias || true,
    };

    return _.omitBy(_.isNil, style);
  }

  get polygonBorderStyle() {
    const result = {
      'line-color': this.poly_fill_outline_color || this.color,
      'line-width': 1,
    };
    return _.omitBy(_.isNil, result);
  }

  get polygonBorderLabelLayout() {
    const result = {
      'symbol-placement': 'line',
      'text-field': ['get', 'name'],
      'text-anchor': this.path_label_text_anchor,
      'text-transform': this.path_label_text_transform || 'none',
      'symbol-z-order': 'auto',
      'text-pitch-alignment': 'viewport',
      'text-size': this.path_label_text_size || 14,
      'symbol-spacing': 350,
      'text-font': ['Arial Unicode MS Regular'],
      'text-justify': 'center',
      'text-keep-upright': true,
      'text-letter-spacing': this.path_label_text_letter_spacing || 0.05,
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'text-max-width': this.path_label_text_max_width || 20,
      'text-padding': this.path_label_text_padding || 1,
      visibility: this.isVisible ? 'visible' : 'none',
    };
    return _.omitBy(_.isNil, result);
  }

  get polygonBorderLabelPaint() {
    const result = {
      'text-color': this.poly_fill_color || this.color,
      'text-halo-color': this.path_label_halo_color || this.color,
      'text-halo-width': this.path_label_text_halo_width || 2,
      'text-halo-blur': this.path_label_text_halo_blur || 0.5,
    };
    return _.omitBy(_.isNil, result);
  }

  get lineSelectedLayoutStyle() {
    const style = {
      'line-join': this.path_line_join || 'round',
      'line-cap': this.path_line_cap || 'round',
      'line-round-limit': Number(this.path_line_round_limit || 1.05),
      'line-miter-limit': Number(this.path_line_miter_limit || 0),
      ...(this.path_line_sort_key
        ? { 'line-sort-key': Number(this.path_line_sort_key) }
        : {}),
    };
    return style;
  }

  get lineSelectedPaintStyle() {
    const style = {
      'line-color': this.path_line_color || this.color,
      'line-width': Number(this.path_line_width || 2) + 2,
      'line-opacity': 1,
      'line-blur': Number(this.path_line_blur || 0),
      'line-translate': this.path_line_translate ?? [0, 0],
      'line-offset': Number(this.path_line_offset) ?? 0,
      'line-gap-width': Number(this.path_line_gap_width) ?? 0,
      'line-translate-anchor':
        this.path_line_translate_anchor === 'auto' ||
        !this.path_line_translate_anchor
          ? 'map'
          : this.path_line_translate_anchor,
      ...(this.path_line_gradient
        ? { 'line-gradient': this.path_line_gradient }
        : {}),
      ...(this.path_line_pattern
        ? { 'line-pattern': this.path_line_pattern }
        : {}),
    };
    return style;
  }

  get lineSelectedHaloLayoutStyle() {
    const style = {
      'line-join': this.path_line_join || 'round',
      'line-cap': this.path_line_cap || 'round',
      'line-round-limit': Number(this.path_line_round_limit || 1.05),
      'line-miter-limit': Number(this.path_line_miter_limit || 0),
    };
    return style;
  }

  get lineSelectedHaloPaintStyle() {
    const style = {
      'line-color': this.path_line_color || this.color,
      'line-width': Number(this.path_line_width || 2) + 12,
      'line-opacity': 0.3,
      'line-blur': Number(this.path_line_blur || 0),
      'line-translate': this.path_line_translate ?? [0, 0],
      'line-offset': Number(this.path_line_offset) ?? 0,
      'line-gap-width': Number(this.path_line_gap_width) ?? 0,
      'line-translate-anchor':
        this.path_line_translate_anchor === 'auto' ||
        !this.path_line_translate_anchor
          ? 'map'
          : this.path_line_translate_anchor,
      ...(this.path_line_gradient
        ? { 'line-gradient': this.path_line_gradient }
        : {}),
      ...(this.path_line_pattern
        ? { 'line-pattern': this.path_line_pattern }
        : {}),
    };
    return style;
  }

  get lineSelectedLabelLayoutStyle() {
    return {
      'text-field': ['get', 'name'],
      'text-rotation-alignment': 'map',
      'symbol-placement': this.path_label_symbol_placement || 'line',
      'text-keep-upright': this.path_label_text_keep_upright || true,
      'text-letter-spacing': validate.toNumberOr(
        this.path_label_text_letter_spacing,
        0.05,
      ),
      'text-padding': validate.toNumberOr(this.path_label_text_padding, 0),
      'text-size': validate.toNumberOr(this.path_label_text_size, 14),
      'symbol-spacing': validate.toNumberOr(this.path_label_symbol_spacing, 80),
      'text-max-width': this.path_label_text_max_width || 50,
      'text-font': this.path_label_text_font || ['Arial Unicode MS Regular'],
      'text-offset': this.path_label_text_offset || [0, 0],
      'text-justify': this.path_label_text_justify || 'center',
      'text-anchor': this.path_label_text_anchor || 'center',
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-transform': this.path_label_text_transform || 'none',
      'symbol-z-order': this.path_label_symbol_z_order || 'auto',
      'text-pitch-alignment': this.path_label_text_pitch_alignment || 'auto',
      visibility: this.isVisible ? 'visible' : 'none',
    };
  }

  get lineSelectedLabelPaintStyle() {
    return {
      'text-halo-blur': validate.toNumberOr(
        this.path_label_text_halo_blur,
        0.5,
      ),
      'text-halo-width': validate.toNumberOr(this.path_label_halo_width, 2),
      'text-color': this.path_label_color || this.color,
      'text-halo-color': this.path_label_halo_color || this.color,
      'text-translate': this.path_label_text_translate || [0, 0],
    };
  }

  get markerNamePaintStyle() {
    const highlightedMarker =
      this.parent.selectedMarker ||
      this.parent.hoveredMarker ||
      this.parent.activeFeatureMobile;
    return {
      'text-halo-blur': validate.toNumberOr(
        this.marker_label_text_halo_blur,
        0.5,
      ),
      'text-halo-width': validate.toNumberOr(
        this.marker_label_text_halo_width,
        0,
      ),
      'text-color': this.marker_label_text_color
        ? this.marker_label_text_color
        : this.color,
      'text-halo-color': this.marker_label_text_halo_color
        ? this.marker_label_text_halo_color
        : this.color,
      'text-translate': this.marker_label_text_translate ?? [0, -48],
      'text-opacity': [
        'case',
        [
          'all',
          [
            '==',
            ['get', 'id'],
            highlightedMarker ? highlightedMarker.id : 'none',
          ],
          ['literal', this.isVisible],
        ],
        0.01,
        1,
      ],
    };
  }

  get markerNameLayoutStyle() {
    return {
      'text-field': ['get', 'name'],
      'symbol-placement': 'point',
      'text-letter-spacing': validate.toNumberOr(
        this.marker_label_text_letter_spacing,
        0,
      ),
      'text-padding': validate.toNumberOr(this.marker_label_text_padding, 2),
      'text-size': validate.toNumberOr(this.marker_label_text_size, 12),
      'text-max-width': validate.toNumberOr(
        this.marker_label_text_max_width,
        12,
      ),
      'text-font': this.marker_label_text_font || ['Arial Unicode MS Regular'],
      'symbol-z-order': this.marker_label_symbol_z_order || 'auto',
      'text-justify': this.marker_label_text_justify || 'center',
      'text-pitch-alignment': this.marker_label_text_pitch_alignment ?? 'auto',
      'text-transform': validate.textTransform(
        this.marker_label_text_transform,
      ),
      visibility: this.marker_icon_only
        ? 'none'
        : this.parent.viewport.zoom > this.marker_transition_zoom
        ? 'visible'
        : 'none',
      'text-anchor': this.marker_label_text_anchor || 'bottom',
      'text-offset': this.marker_label_text_offset || [0, -2.6],
    };
  }

  default_selected: boolean;
  path_label_text_keep_upright: boolean;
  circle_icon_size: null;
  circle_icon_stroke_width: null;
  path_markers_visible: boolean;
  marker_label_text_halo_blur: number;
  marker_label_text_halo_width: number;
  marker_label_text_letter_spacing: number;
  marker_label_text_padding: number;
  marker_label_text_size: number;
  path_label_symbol_spacing: number;
  path_label_text_halo_blur: number;
  path_label_text_halo_width: number;
  path_label_text_letter_spacing: number;
  path_label_text_padding: number;
  path_label_text_size: number;
  path_line_blur: number;
  path_line_gap_width: number;
  path_line_miter_limit: number;
  path_line_offset: number;
  path_line_opacity: number;
  path_line_round_limit: number;
  path_line_width: number;
  cluster_radius: number;
  marker_label_text_max_width: number;
  path_label_halo_width: number;
  path_label_maxzoom: null;
  path_label_minzoom: null;
  path_label_text_max_width: number;
  path_line_halo_width: number;
  path_line_sort_key: null;
  marker_label_text_font: string[];
  marker_label_text_offset: number[];
  marker_label_text_translate: number[];
  path_label_text_font: string[];
  path_label_text_offset: number[];
  path_label_text_translate: number[];
  path_line_dasharray: null;
  path_line_translate: number[];
  color: string;
  icon: string;
  marker_maxzoom: number;
  marker_minzoom: number;
  path_maxzoom: number;
  path_minzoom: number;
  marker_label_symbol_placement: string;
  marker_label_symbol_z_order: string;
  marker_label_text_anchor: string;
  marker_label_text_color: string;
  marker_label_text_halo_color: null;
  marker_label_text_justify: string;
  marker_label_text_pitch_alignment: string;
  marker_label_text_transform: string;
  name: string;
  path_label_color: string;
  path_label_halo_color: null;
  path_label_symbol_placement: string;
  path_label_symbol_z_order: string;
  path_label_text_anchor: string;
  path_label_text_halo_color: null;
  path_label_text_justify: string;
  path_label_text_pitch_alignment: string;
  path_label_text_transform: string;
  path_line_cap: string;
  path_line_color: null;
  path_line_gradient: null;
  path_line_halo_color: null;
  path_line_join: string;
  path_line_pattern: null;
  path_line_translate_anchor: string;
  id: string;
  map_id: string;
  sort_order: number;
  marker_label_icon_allow_overlap: boolean;
  marker_label_icon_ignore_placement: boolean;
  marker_label_text_allow_overlap: boolean;
  marker_label_text_ignore_placement: boolean;
  marker_icon_icon_allow_overlap: boolean;
  marker_icon_icon_ignore_placement: boolean;
  marker_icon_text_allow_overlap: boolean;
  marker_icon_text_ignore_placement: boolean;
  path_label_icon_allow_overlap: boolean;
  path_label_icon_ignore_placement: boolean;
  path_label_text_allow_overlap: boolean;
  path_label_text_ignore_placement: boolean;
  marker_icon_halo_blur: number;
  marker_icon_halo_width: number;
  marker_label_radial_offset: number;
  marker_transition_zoom: number;
  icon_size: string;
  cluster_max_zoom: number;
  cluster_min_points: number;
  marker_text_variable_anchor: string[];
  marker_icon_color: null;
  marker_icon_halo_color: null;
  static: boolean;
  marker_icon_type: 'PRIMARY' | 'SECONDARY';
  marker_icon_only: boolean;
  marker_label_halo_width: string;
  path_label_text_symbol_spacing: number;
  path_center_gap_width: number;
  path_segment_dash_width: number;
  custom_tag: boolean;
  listed_by_default: boolean;
  poly_fill_translate_anchor: 'map' | 'viewport';
  poly_fill_translate: [number, number];
  poly_fill_pattern: string;
  poly_fill_outline_color: string;
  poly_fill_opacity: number;
  poly_fill_color?: string;
  poly_fill_antialias: boolean;
  poly_label_text_anchor: TextAnchor;
  poly_label_text_size: number;
  poly_label_text_letter_spacing: number;
  poly_label_color?: string;
  poly_label_halo_color?: string;
  poly_label_text_halo_width: number;
  poly_label_text_halo_blur: number;
  poly_markers_visible: boolean;
  icon_url: string;
  cluster_icon_url: string;
  poi_icon_url: string;
}
