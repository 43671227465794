import client from '../client';
import {
  IPatchField,
  IPatchFieldOption,
  IPatchKeyword,
  IPatchType,
  IPostField,
  IPostFieldOption,
  IPostKeyword,
  IPostType,
  IPutSortTags,
} from '@wander-app/shared';
import { extractData } from '../../extract-data';

export const getAllTagItems = () =>
  client.get(`/places-of-interest/items/all`).then(extractData);

export const getSortedTagItems = (params) =>
  client
    .get(`/places-of-interest/tags-pois-orders`, { params })
    .then(extractData);

export const relatePoiToItems = ({
  map_id,
  poi_id,
  type,
  item_ids,
  random_id,
}: {
  map_id: string;
  poi_id: string;
  type: 'marker' | 'path' | 'polygon';
  item_ids: Array<string>;
  random_id: string;
}) =>
  client
    .put(`/places-of-interest/${map_id}/items/relate`, {
      poi_id,
      type,
      item_ids,
      random_id,
    })
    .then(extractData);

export const addKeyword = (data: IPostKeyword) =>
  client.post('/places-of-interest/items/keywords', data).then(extractData);

export const addType = ({
  data,
  keyword_id,
}: {
  data: IPostType;
  keyword_id: string;
}) =>
  client
    .post(`/places-of-interest/items/keywords/${keyword_id}/types`, data)
    .then(extractData);

export const addField = ({
  type_id,
  data,
}: {
  type_id: string;
  data: IPostField;
}) => client.post(`/places-of-interest/items/types/${type_id}/fields`, data);

export const addFieldOption = ({
  field_id,
  data,
}: {
  field_id: string;
  data: IPostFieldOption;
}) =>
  client.post(
    `/places-of-interest/items/fields/${field_id}/field_options`,
    data,
  );

export const updateKeyword = ({
  data,
  keyword_id,
}: {
  data: IPatchKeyword;
  keyword_id: string;
}) =>
  client
    .patch(`/places-of-interest/items/keywords/${keyword_id}`, data)
    .then(extractData);

export const updateType = ({
  data,
  keyword_id,
  type_id,
}: {
  data: IPatchType;
  keyword_id: string;
  type_id: string;
}) =>
  client
    .patch(
      `/places-of-interest/items/keywords/${keyword_id}/types/${type_id}`,
      data,
    )
    .then(extractData);

export const updateField = ({
  field_id,
  data,
}: {
  field_id: string;
  data: IPatchField;
}) => client.patch(`/places-of-interest/items/fields/${field_id}`, data);

export const updateFieldOption = ({
  field_option_id,
  data,
}: {
  field_option_id: string;
  data: IPatchFieldOption;
}) =>
  client.patch(
    `/places-of-interest/items/field_options/${field_option_id}`,
    data,
  );

export const deleteKeyword = (keyword_id: string) =>
  client
    .delete(`/places-of-interest/items/keywords/${keyword_id}`)
    .then(extractData);

export const deleteType = ({
  keyword_id,
  type_id,
}: {
  keyword_id: string;
  type_id: string;
}) =>
  client
    .delete(`/places-of-interest/items/keywords/${keyword_id}/types/${type_id}`)
    .then(extractData);

export const deleteField = (field_id: string) =>
  client.delete(`/places-of-interest/items/fields/${field_id}`);

export const deleteFieldOption = (field_option_id: string) =>
  client.delete(`/places-of-interest/items/field_options/${field_option_id}`);

export const bulkPoiTagsUpdate = ({
  pois,
  ids_to_add,
  ids_to_remove,
  mapId,
}: {
  pois: Array<object>;
  ids_to_add: Array<string>;
  ids_to_remove: Array<string>;
  mapId: string;
}) =>
  client
    .post(`maps/${mapId}/bulk/features/tags`, {
      pois,
      ids_to_add,
      ids_to_remove,
    })
    .then(extractData);

export const sortMapTags = ({
  map_id,
  data,
}: {
  map_id: string;
  data: IPutSortTags;
}) =>
  client
    .put(`/places-of-interest/items/sort?map_id=${map_id}`, data)
    .then(extractData);
