import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';
import { BRAND_COLOR } from '@wander-app/shared';

const breakpoints = createBreakpoints({
  sm: '425px',
  md: '768px',
  lg: '960px',
  xl: '1280px',
  '2xl': '1440px',
});

const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  styles: {
    global: (props) => ({
      body: {
        color: mode('wander.cardColor.dark', 'whiteAlpha.900')(props),
        bg: mode('gray.50', '#2B2B2B')(props),
      },
    }),
  },
  fonts: {
    heading: `'Crimson Pro', sans-serif`,
    body: `'Gotham Light', sans-serif`,
    notoSans: `'Noto Sans', sans-serif`,
  },
  colors: {
    brand: {
      50: '#F3F5F0',
      100: '#DCE2D5',
      200: '#C6CFBA',
      300: '#AFBC9E',
      400: '#99AA83',
      500: '#829768',
      600: '#687953',
      700: '#4E5A3F',
      800: '#343C2A',
      900: '#1A1E15',
    },
    brand2: {
      400: '#0C91BB',
      hover: 'rgba(12, 145, 187, 0.25)',
    },
    wanderGray: {
      300: '#5C5E5E',
      400: '#3F4040',
      500: '#373737',
      600: '#282828',
      700: '#181818',
    },
    wander: {
      brown: '#3A2D27',
      green: BRAND_COLOR,
      green80: '#64744E',
      greenLight: '#C3DDC2',
      beige: '#EBDCCF',
      black: '#2B2B2B',
      grayLight: '#B0AFAC',
      gray: '#434343',
      grayishDark: '#53535A',
      white: '#FFFFFF',
      white80: 'rgba(255, 255, 255, 0.8)',
      white70: 'rgba(255, 255, 255, 0.7)',
      orange: '#CD4929',
      orange50: '#F4BE86',
      orangeLight: '#D77B1C',
      lightContrast: '#EDF2F7',
      cardColor: {
        dark: '#434343',
        light: 'white',
      },
    },
  },
  components: {
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
    Checkbox: {},
    Table: {
      variants: {
        brand: {
          th: {
            fontWeight: '700',
            fontFamily: 'unset',
          },
          td: {
            fontWeight: '400',
          },
        },
      },
    },

    Button: {
      defaultProps: {
        variant: 'brand',
      },
      variants: {
        brand: {
          color: 'white',
          bg: 'wander.green',
          _hover: {
            bg: 'wander.green80',
          },
        },
        secondary: () => ({
          color: 'brand.600',
          bg: 'white',
          _hover: {
            bg: 'white',
          },
        }),
      },
    },
    Input: {
      variants: {
        brand: {
          field: {
            bg: 'gray.50',
            errorBorderColor: 'red.300',
            boxShadow: '1px 3px 2px rgba(0, 0, 0, 0.15)',
            _placeholder: {
              _dark: {
                color: 'wander.gray',
              },
            },
            _dark: {
              bg: 'wander.white80',
            },
            _hover: {
              bg: 'gray.100',
              _dark: {
                bg: 'wander.white70',
              },
            },
            _invalid: {
              border: '2px solid',
              borderColor: 'red.300',
            },
            _readOnly: {
              boxShadow: 'none !important',
              userSelect: 'all',
            },
            _focusVisible: {
              bg: 'gray.100',
              _dark: {
                bg: 'wander.white70',
              },
            },
          },
        },
      },
    },
    Link: (props) => ({
      color: props.colorMode === 'dark' ? 'brand.300' : 'brand.500',
    }),
    Menu: {
      baseStyle: ({ colorMode }) => ({
        list: {
          bg:
            colorMode === 'dark'
              ? 'wander.cardColor.dark'
              : 'wander.cardColor.light',
        },
      }),
    },
    Modal: {
      baseStyle: ({ colorMode }) => ({
        dialog: {
          bg:
            colorMode === 'dark'
              ? 'wander.cardColor.dark'
              : 'wander.cardColor.light',
        },
      }),
    },
  },
  breakpoints,
});

export default theme;
