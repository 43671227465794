export enum TagItemsSortEnum {
  Alphabetical = 'alphabetical',
  Popularity = 'popularity',
  Custom = 'custom',
}

export type TagItemsSortType = 'alphabetical' | 'popularity' | 'custom';

export interface IAddMap {
  gps_enabled?: boolean;
  is_featured: boolean;
  default_bearing?: number;
  default_pitch?: number;
  default_zoom?: number;
  area_coordinates: number[][];
  point: [number, number];
  alternative_id?: string;
  city: string;
  cover_photo_url?: string;
  logo_url?: string;
  name: string;
  primary_color: string;
  state: string;
  tagline?: string;
  description: string;
  style_id: string;
  contrast_color?: string;
  timezone?: string;
  is_custom_tag_only?: boolean;
  poi_suggestions_enabled?: boolean;
  show_hours_filters?: boolean;
  tags_order_type?: TagItemsSortType;
  updated_by?: string;
}
