// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash/fp';
import { IUserOrganizationMapsPrivileges } from '../dto-interfaces';

export const permissionsDiffReducer = (
  data: Array<IUserOrganizationMapsPrivileges>,
) => {
  const getDiffShape = ({
    organization_roles,
    map_roles,
    account_roles,
  }: IUserOrganizationMapsPrivileges) => ({
    ORGANIZATION_ROLES: _.sortBy(_.identity, organization_roles),
    ACCOUNT_ROLES: _.sortBy(_.identity, account_roles),
    ..._.reduce(
      (acc, { map_id, roles }) => {
        acc[map_id] = _.sortBy(_.identity, roles);
        return acc;
      },
      {} as Record<string, unknown>,
      map_roles,
    ),
  });

  return _.reduce(
    (acc, { user_id, ...rest }) => {
      acc[user_id] = getDiffShape(rest as IUserOrganizationMapsPrivileges);
      return acc;
    },
    {} as Record<string, unknown>,
    data,
  );
};

export const dbPermissionsToDto = _.flow(
  _.sortBy(
    (u: {
      firebase_id: string;
      name: string;
      roles: Array<string>;
      organization_roles: Array<{ roles: Array<string> }>;
      map_roles: Array<{
        roles: Array<string>;
        map: { id: string; alternative_id?: string | null; name: string };
      }>;
    }) => u.firebase_id,
  ),
  _.map(({ firebase_id, roles, organization_roles, map_roles }) => ({
    user_id: firebase_id,
    organization_roles: [
      ...(_.get('roles', _.first(organization_roles)) || []),
    ],
    map_roles: [
      ..._.map(
        ({ roles, map: { id } }) => ({
          roles,
          map_id: id,
        }),
        map_roles,
      ),
    ],
    roles: _.map(_.identity, roles),
  })),
  permissionsDiffReducer,
);

export const getNewPermissions = ({ db, dto }) =>
  _.flow(
    ({ db, dto }) =>
      _.reduce(
        (acc1, [key1, value1]) => {
          if (_.has(key1, db)) {
            acc1[key1] = _.reduce(
              (acc2, [key2, value2]) => {
                acc2[key2] = _.union(_.get(`${key1}.${key2}`, db), value2);
                return acc2;
              },
              {} as Record<string, unknown>,
              _.entries(db[key1]),
            );
          } else {
            acc1[key1] = value1;
          }
          return acc1;
        },
        {} as Record<string, unknown>,
        _.entries(dto),
      ),
    (diff) =>
      _.reduce(
        (acc, { ORGANIZATION_ROLES, ACCOUNT_ROLES, ...rest }) => {
          acc.ORGANIZATION_ROLES = _.uniq(
            _.concat(acc.ORGANIZATION_ROLES || [], ORGANIZATION_ROLES || []),
          );
          acc.ACCOUNT_ROLES = _.uniq(
            _.concat(acc.ACCOUNT_ROLES || [], ACCOUNT_ROLES || []),
          );
          _.forEach(([key, value]) => {
            acc[key] = _.uniq(_.concat(acc[key] || [], value));
          }, _.entries(rest));
          return acc;
        },
        {} as Record<string, unknown>,
        _.values(diff),
      ),
  )({ db, dto });

export const canAssign = (role, map_id, userRoles) => {
  if (userRoles['super_admin']) {
    return true;
  }
  switch (role) {
    case 'wander_account_manager':
    case 'wander_map_builder':
      return userRoles['wander_account_manager'];
    case 'organization_account_admin':
      return (
        userRoles['organization_account_admin'] ||
        userRoles['wander_account_manager']
      );
    case 'organization_account_viewer':
      return (
        userRoles['organization_account_admin'] ||
        userRoles['wander_account_manager']
      );
    case 'organization_map_admin':
    case 'organization_editor':
      return (
        userRoles['organization_account_admin'] ||
        userRoles['wander_account_manager'] ||
        (userRoles['map_roles'][map_id] &&
          (userRoles['map_roles'][map_id]['organization_map_admin'] ||
            userRoles['map_roles'][map_id]['organization_account_admin'] ||
            userRoles['map_roles'][map_id]['wander_account_manager']))
      );
    case 'organization_reviewer':
    case 'organization_viewer':
      return (
        userRoles['organization_account_admin'] ||
        userRoles['wander_map_builder'] ||
        userRoles['wander_account_manager'] ||
        (userRoles['map_roles'][map_id] &&
          (userRoles['map_roles'][map_id]['organization_reviewer'] ||
            userRoles['map_roles'][map_id]['organization_editor'] ||
            userRoles['map_roles'][map_id]['organization_map_admin'] ||
            userRoles['map_roles'][map_id]['organization_account_admin'] ||
            userRoles['map_roles'][map_id]['wander_map_builder'] ||
            userRoles['map_roles'][map_id]['wander_account_manager']))
      );
  }
};

export const validateDifference = (userRoles = {}, newPermissions) => {
  try {
    _.forEach(([key, roles]) => {
      _.forEach((role) => {
        if (
          !canAssign(
            role,
            key !== 'ORGANIZATION_ROLES' || key !== 'ACCOUNT_ROLES',
            userRoles,
          )
            ? key
            : undefined
        ) {
          throw Error('Cannot assign permission');
        }
      }, roles);
    }, _.entries(newPermissions));
  } catch (err) {
    return false;
  }
  return true;
};
