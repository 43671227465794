import { makeAutoObservable } from 'mobx';
import { StoreMobx } from '../store.mobx';
import { EmbedCategoryMobx } from '../embedCategory.mobx';
import { EmbedFeatureMobx } from '../embedFeature.mobx';

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

const DEFAULT_MODAL_SIZE = 'xl';

type BuilderModalID =
  | 'none'
  | 'category.create'
  | 'category.edit'
  | 'category.advanced'
  | 'bulk.edit'
  | 'bulk.edit.hours-of-operation'
  | 'category.settings'
  | 'feature.markers.edit'
  | 'feature.paths.edit'
  | 'feature.polygons.edit'
  | 'tags.edit'
  | 'photos.edit'
  | 'highlights.edit'
  | 'share'
  | 'properties.edit'
  | 'feature.markers.view'
  | 'feature.paths.view'
  | 'feature.polygons.view';

type SidebarID =
  | 'none'
  | 'feature.markers.create'
  | 'feature.paths.create'
  | 'feature.paths.edit'
  | 'feature.markers.edit'
  | 'feature.paths.view'
  | 'feature.markers.view'
  | 'new.map.first'
  | 'new.map.next'
  | 'edit.map.settings'
  | 'merge.paths.bulk'
  | 'import'
  | 'map-versions-visibilities'
  | 'edit-path-start-end-markers'
  | 'tags-pois-sorting';

type EntityNames = 'none' | 'category' | 'feature' | 'map';
type EntityTypes = EmbedCategoryMobx | EmbedFeatureMobx;

export class BuilderLayoutMobx {
  modal: {
    id: BuilderModalID;
    size: ModalSize;
  } = {
    id: 'none',
    size: DEFAULT_MODAL_SIZE,
  };
  sidebar: SidebarID = 'none';
  selectedFeature?: EmbedFeatureMobx = null;
  selectedCategory?: EmbedCategoryMobx = null;
  importLoading = false;
  uploadProgress = 0;
  localMapCoordinates = [];
  editHoursModalOpen = false;
  fromAddCategoryButton = true;
  isDrawFinal = false;

  setEditHourModalsOpen(value: boolean) {
    this.editHoursModalOpen = value;
  }

  setFromAddCategoryButton(value: boolean) {
    this.fromAddCategoryButton = value;
  }

  setLocalMapCoordinates = (coordinates) => {
    this.localMapCoordinates = coordinates;
  };

  setIsDrawFinal = (value) => {
    this.isDrawFinal = value;
  };

  setUploadProgress = (progress: number) => {
    this.uploadProgress = progress;
  };

  get shouldDisableBuilderActions() {
    return (
      this.sidebar === 'map-versions-visibilities' ||
      this.sidebar === 'edit-path-start-end-markers'
    );
  }

  changeFeatureItemAndSidebar = (id: SidebarID, feature: EmbedFeatureMobx) => {
    this.sidebar = id;
    this.selectedFeature = feature;
  };

  changeCategoryItemAndSidebar = (
    id: SidebarID,
    category: EmbedCategoryMobx,
  ) => {
    this.sidebar = id;
    this.selectedCategory = category;
  };

  setImportLoading = (loading: boolean) => {
    this.importLoading = loading;
  };

  openModal = (
    id: BuilderModalID,
    entityName?: EntityNames,
    entity?: EntityTypes,
    size: ModalSize = DEFAULT_MODAL_SIZE,
  ) => {
    this.modal = {
      id,
      size,
    };
    this.setSelected(entityName, entity);
  };

  closeModal = () => {
    this.modal = {
      id: 'none',
      size: DEFAULT_MODAL_SIZE,
    };
  };

  setSidebar = (
    id: SidebarID,
    entityName: EntityNames,
    entity?: EntityTypes,
  ) => {
    this.sidebar = id;
    this.setSelected(entityName, entity);
  };

  clearSidebar = () => {
    this.sidebar = 'none';
  };

  setSelected = (entityName: EntityNames, entity: EntityTypes) => {
    if (entityName === 'category') {
      this.selectedCategory = entity as EmbedCategoryMobx;
    } else if (entityName === 'feature') {
      this.selectedFeature = entity as EmbedFeatureMobx;
    }
  };

  updateFeatureProperties = (values: Record<string, unknown>) => {
    const feature = this.selectedFeature;
    feature.properties = { ...feature.properties, ...values };
  };

  get editSidebarActive() {
    return this.sidebar.includes('edit');
  }

  get defaultMapSettingsFormState() {
    return {
      values: {
        id: this.parent.embed.map?.id,
        name: this.parent.embed.map?.name,
        map_location: '',
        base_style_url: this.parent.embed.map?.styles.base_style_url,
        default_pitch: this.parent.embed.map?.default_pitch ?? 0,
        default_bearing: this.parent.embed.map?.default_bearing ?? 0,
        area_coordinates: this.parent.embed.map?.area_coordinates ?? '',
        external_area_color:
          this.parent.embed.map?.styles.external_area_color ?? '#000000',
        external_area_opacity:
          this.parent.embed.map?.styles.external_area_opacity ?? '.2',
      },
      inputs: [
        'name',
        'map_location',
        'area_coordinates',
        'default_pitch',
        'default_bearing',
        'base_style_url',
        'external_area_color',
      ],
      requiredFields: ['name', 'area_coordinates', 'base_style_url'],
    };
  }

  parent: StoreMobx;
  constructor(parent: StoreMobx) {
    this.parent = parent;
    makeAutoObservable(this);
  }
}
