import { initializeApp, deleteApp } from 'firebase/app';
import {
  getAuth,
  signInAnonymously,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
  linkWithCredential,
  EmailAuthProvider,
  getRedirectResult,
  linkWithRedirect,
  signOut,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import env from '../env';

const app = initializeApp(env.firebase.firebaseClientInitConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

const getProvider = (type: 'google' | 'facebook' | 'apple') => {
  switch (type) {
    case 'google': {
      const googleAuthProvider = new GoogleAuthProvider();
      googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
      return googleAuthProvider;
    }
    case 'facebook':
      return new FacebookAuthProvider();
    case 'apple':
      return new OAuthProvider('apple.com');
    default:
      throw Error('Invalid provider type');
  }
};

const createAndGetAnonymousUserID = async () => {
  const tempApp = initializeApp(
    env.firebase.firebaseClientInitConfig,
    'tempApp',
  );
  try {
    const tempAuth = getAuth(tempApp);

    await signOut(tempAuth);

    const userCredential = await signInAnonymously(tempAuth);
    return userCredential.user.uid;
  } finally {
    await deleteApp(tempApp);
  }
};

const linkWithProvider = async (
  customToken: string,
  type: 'google' | 'facebook' | 'apple',
) => {
  await signInWithCustomToken(auth, customToken);
  const currentUser = auth.currentUser;
  const provider = getProvider(type);
  await linkWithRedirect(currentUser, provider);
  return currentUser;
};

const linkWWithEmailPassword = async ({
  customToken,
  email,
  password,
}: {
  customToken: string;
  email: string;
  password: string;
}) => {
  await signInWithCustomToken(auth, customToken);
  const currentUser = auth.currentUser;
  const credential = EmailAuthProvider.credential(email, password);
  await linkWithCredential(currentUser, credential);
  return currentUser;
};

const handleRedirectResult = async () => {
  const result = await getRedirectResult(auth);
  return result.user;
};

export {
  auth,
  firestore,
  storage,
  getProvider,
  createAndGetAnonymousUserID,
  linkWithProvider,
  linkWWithEmailPassword,
  handleRedirectResult,
};
